import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Rental } from '../../../common/types/rental';
import { colors } from '../../../common/utils/colors';
import { formatDate, generateDateArray } from '../../../common/utils/dateUtils';
import {
  generateRentalsPerBoxPerDayData,
  getUniqueBoxNames,
} from './utils/generateRentalsPerBoxPerDayData';

interface Props {
  endDate?: string;
  fromDate?: string;
  rentals: Rental[];
}

export const RentalsPerBoxPerDay: React.FC<Props> = ({
  fromDate,
  endDate,
  rentals,
}) => {
  const uniqueBoxes = getUniqueBoxNames(rentals);

  const data =
    rentals.length > 0
      ? generateRentalsPerBoxPerDayData(
          rentals,
          generateDateArray(
            fromDate || rentals[0].rentStart,
            endDate ||
              rentals[rentals.length - 1].rentEnd ||
              formatDate(new Date())
          ),
          uniqueBoxes
        )
      : [];

  return (
    <LineChart
      width={1000}
      height={400}
      data={data}
      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
    >
      <XAxis dataKey={(key) => key.date} />
      <YAxis />
      <Tooltip />
      <Legend
        layout={'vertical'}
        align={'right'}
        verticalAlign={'top'}
        wrapperStyle={{
          overflow: 'scroll',
          height: 300,
        }}
      />
      <CartesianGrid stroke="#f5f5f5" />
      {uniqueBoxes.map((boxName, index) => {
        return (
          <Line
            key={boxName}
            connectNulls={true}
            type="monotone"
            dataKey={boxName}
            stroke={colors[index % colors.length]}
          />
        );
      })}
    </LineChart>
  );
};
