import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Product as ProductType } from '../../common/types/product';
import { resolveRentalTypeDisplayName } from '../../common/utils/ResolveRentalTypeDisplayName';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { Typography } from '../../webui/Typography';
import { ProductTranslation } from './ProductTranslation';
import { useTranslatedProduct } from './utils/useTranslatedProduct';

interface Props {
  product: ProductType;
}

export const Product: React.FC<Props> = ({ product }) => {
  const {
    description,
    id,
    imageName,
    name,
    rentalType,
    translationKey,
  } = product;
  const { tDescription, tName } = useTranslatedProduct();
  return (
    <Box textAlign={'start'}>
      <Link component={RouterLink} to={generatePath(Routes.PRODUCT, { id })}>
        {name}
      </Link>

      <Box mt={2}>
        <img
          src={`/products/${imageName}.svg`}
          alt={imageName}
          width={56}
          height={56}
        />
      </Box>
      <Box mt={2}>
        <Typography>
          Rental type: {resolveRentalTypeDisplayName(rentalType)}
        </Typography>
      </Box>
      {translationKey === undefined ? (
        <Box mt={2}>
          <Typography>{description}</Typography>
        </Box>
      ) : (
        <>
          <ProductTranslation
            lng={'en'}
            name={tName(product, 'en')}
            description={tDescription(product, 'en')}
          />
          <ProductTranslation
            lng={'sv'}
            name={tName(product, 'sv')}
            description={tDescription(product, 'sv')}
          />
        </>
      )}
    </Box>
  );
};
