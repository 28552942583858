import * as React from 'react';
import { useState } from 'react';
import { BatteryReading } from '../../common/types/batteryReading';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { Box } from '../../webui/Box';
import { MenuItem } from '../../webui/inputs/MenuItem';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { Select, SelectChangeEvent } from '../../webui/inputs/Select';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllBoxes } from '../box/gql/useAllBoxes';
import { sortBoxes } from '../box/utils/sortBoxes';
import { BoxBatteryReadings } from './BoxBatteryReadings';
import { getBatteryReadingsPerBox } from './utils/getBatteryReadingsPerBox';
import { getBatteryComparator, SortBy } from './utils/sortBatteryReadings';

interface Props {
  batteryReadings: BatteryReading[];
}

export const DetailedBatteryReadingsTables: React.FC<Props> = ({
  batteryReadings,
}) => {
  const { data: allBoxes, loading: loadingBoxes } = useAllBoxes();
  const boxesWithReadings = getBatteryReadingsPerBox(batteryReadings);

  const [sortBy, setSortBy] = useState<SortBy>('usage');

  const onChangeSortBy = (event: SelectChangeEvent<SortBy | undefined>) => {
    setSortBy(event.target.value as SortBy);
  };

  const [selectedBoxes, setSelectedBoxes] = useState<Option[]>([]);

  const onBoxesChange: MultiSearchSelectProps['onChange'] = (value) => {
    setSelectedBoxes(value.slice());
  };

  const sortedFilteredBoxReadings = Object.values(boxesWithReadings)
    .filter((box) => box.length > 0)
    .sort(getBatteryComparator(sortBy));

  if (loadingBoxes) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <>
      <Box p={2} display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <MultiSearchSelect
          label={'Boxes'}
          onChange={onBoxesChange}
          options={sortBoxes(allBoxes).map((box) => ({
            label: resolveBoxDisplayName(box),
            value: box.id,
          }))}
          value={selectedBoxes}
        />
        <Box ml={2} />
        <Select
          multiple={false}
          label={'Sort by'}
          value={sortBy}
          onChange={onChangeSortBy}
        >
          <MenuItem id={'usage'} value={'usage'}>
            {'Last reading'}
          </MenuItem>
          <MenuItem id={'voltageHighToLow'} value={'voltageHighToLow'}>
            {'Voltage, High - Low'}
          </MenuItem>
          <MenuItem id={'voltageLowToHigh'} value={'voltageLowToHigh'}>
            {'Voltage, Low - High'}
          </MenuItem>
          <MenuItem id={'boxName'} value={'boxName'}>
            {'Box name'}
          </MenuItem>
        </Select>
      </Box>

      {sortedFilteredBoxReadings.map((readings) => {
        if (
          selectedBoxes.length > 0 &&
          !selectedBoxes.some((box) => box.value === readings[0].box.id)
        ) {
          return null;
        }

        return <BoxBatteryReadings readings={readings} />;
      })}
    </>
  );
};
