import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { BoxLite } from '../../common/types/box';
import { filterUndefinedCoordinates } from '../../common/utils/filterUndefinedCoordinates';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { BoxesTable } from './BoxesTable';
import { useAllBoxesLite } from './gql/useAllBoxesLite';
import { BoxMap } from './map/BoxMap';

interface AllBoxesPageProps {}

const getActiveBoxes = (boxes: BoxLite[]): BoxLite[] =>
  boxes.filter((box) => box.active);
const getInactiveBoxes = (boxes: BoxLite[]): BoxLite[] =>
  boxes.filter((box) => !box.active);

export const AllBoxes: React.FC<AllBoxesPageProps> = () => {
  const {
    data: boxes,
    loading: boxesLoading,
    error: boxesError,
  } = useAllBoxesLite();

  if (boxesLoading) {
    return <LoadingSpinnerContainer />;
  }
  if (boxesError) {
    return <div>{boxesError?.message}</div>;
  }

  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are now currently {getActiveBoxes(boxes).length} active and{' '}
            {getInactiveBoxes(boxes).length} inactive boxes.
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_BOX}>
            Add new box
          </Link>
        </Box>
      </Box>
      <Paper>
        <BoxMap
          boxMarkers={filterUndefinedCoordinates(
            boxes.map((box) => ({
              active: box.active,
              color: box.color,
              coordinates: box.location.coordinates,
              id: box.id,
              title: box.name,
            }))
          )}
        />
      </Paper>
      <BoxesTable boxes={boxes} />
    </Container>
  );
};
