import {
  GQL_AllProductsQuery,
  GQL_AllProductsQueryVariables,
  useAllProductsQuery,
} from '../../../generated/graphql';
import * as Apollo from '@apollo/client';
import { transformProduct } from '../../../gql/transformProduct';

export const useAllProducts = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >
) => {
  const { data, ...rest } = useAllProductsQuery(baseOptions);
  return {
    ...rest,
    data: data?.product?.allProducts.map(transformProduct) ?? [],
  };
};
