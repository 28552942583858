import * as Apollo from '@apollo/client';
import {
  GQL_ReviewByIdQuery,
  GQL_ReviewByIdQueryVariables,
  useReviewByIdQuery,
} from '../../../generated/graphql';
import { transformReview } from '../../../gql/transformReview';

export const useReviewById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_ReviewByIdQuery,
    GQL_ReviewByIdQueryVariables
  > &
    (
      | { variables: GQL_ReviewByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useReviewByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.review?.reviewById
      ? transformReview(data?.review?.reviewById)
      : undefined,
  };
};
