import * as React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { BoxWithoutRentals } from '../../common/types/box';
import { createProductsInBoxes } from '../../common/utils/createProductsInBoxes';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  boxes: BoxWithoutRentals[];
}

export interface ProductsInBoxesData {
  id: string;
  name: string;
  count: number;
  boxes: BoxWithoutRentals[];
}

const productsInBoxesHeadCells: TableHeadCell<ProductsInBoxesData>[] = [
  {
    id: 'name',
    label: 'Name',
    render: (value) => value.name,
  },
  {
    id: 'count',
    label: 'Count',
    render: (value) => value.count,
  },
  {
    id: 'boxes',
    label: 'Boxes',
    render: (value) => (
      <Box display={'flex'} flexDirection={'row'}>
        {value['boxes'].map((box) => (
          <Box mr={1} key={box.id}>
            <Link
              component={RouterLink}
              to={generatePath(Routes.BOX, {
                id: box.id,
              })}
            >
              {box.name}
            </Link>
          </Box>
        ))}
      </Box>
    ),
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (
  data: Record<string, BoxWithoutRentals[]>
): ProductsInBoxesData[] => {
  return Object.entries(data).map(([productName, boxes]) => {
    return {
      id: productName,
      name: productName,
      boxes,
      count: boxes.length,
    };
  });
};

const productsInBoxesComparator: Comparator<ProductsInBoxesData> = (
  order,
  orderBy
) => {
  return getComparator(order, orderBy);
};

export const ProductsInBoxes: React.FC<Props> = ({ boxes }) => {
  const productsInBoxes = createProductsInBoxes(boxes);
  return (
    <SortablePaginatedTable
      getComparator={productsInBoxesComparator}
      title={'Products in boxes'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'name'}
      rows={createRows(productsInBoxes)}
      tableHeadCells={productsInBoxesHeadCells}
    />
  );
};
