import React from 'react';
import { RentalsTable } from '../../rentals/RentalsTable';
import { useBoxById } from '../gql/useBoxById';

interface RentalHistoryTableProps {
  boxId: string;
}

export const RentalHistoryTable: React.FC<RentalHistoryTableProps> = ({
  boxId,
}) => {
  const { data: box, loading, error } = useBoxById({
    variables: { id: boxId },
  });

  return (
    <RentalsTable
      error={error}
      loading={loading}
      rentals={
        box?.compartments.flatMap((compartment) => compartment.rentals) ?? []
      }
    />
  );
};
