import React, { useState } from 'react';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../webui/inputs/CheckboxWithLabel';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
  SearchSelect,
  SearchSelectProps,
} from '../../webui/inputs/SearchSelect';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllBoxesWithoutRentals } from '../box/gql/useAllBoxesWithoutRentals';
import { sortBoxes } from '../box/utils/sortBoxes';
import { useAllOrganisations } from '../organisation/gql/useAllOrganisations';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { getFilteredBoxesWithOrganisationBoxes } from './filter/utils/getFilteredBoxesWithOrganisationBoxes';
import { transformFilterToAllRentalsGQLFilter } from './filter/utils/transformFilterToAllRentalsGQLFilter';
import { ProductsPie } from './products/ProductsPie';
import { ProductsInBoxes } from './ProductsInBoxes';

interface Props {}

const selectedBoxesFilter =
  (selectedBoxIds: string[]) =>
  (box: { id: string }): boolean =>
    selectedBoxIds.length === 0 ||
    selectedBoxIds.some((boxId) => box.id === boxId);

const numberOfRentals = 1000;

export const StatisticsProductsPage: React.FC<Props> = () => {
  const { data: allBoxes, loading: allBoxesLoading } =
    useAllBoxesWithoutRentals();
  const { data: allOrganisations, loading: allOrganisationsLoading } =
    useAllOrganisations();

  const [selectedBoxes, setSelectedBoxes] = useState<Option[]>([]);
  const [onlyActiveBoxes, setOnlyActiveBoxes] = useState<boolean>(true);

  const onBoxesChange: MultiSearchSelectProps['onChange'] = (value) => {
    setSelectedBoxes(value.slice());
  };

  const onOrganisationChange: SearchSelectProps['onChange'] = (value) => {
    if (value) {
      const organisation = allOrganisations.find(
        (org) => org.id === value.value
      );
      if (organisation) {
        setSelectedBoxes(
          getFilteredBoxesWithOrganisationBoxes(selectedBoxes, organisation)
        );
      }
    }
  };

  const onOnlyActiveBoxesChange: CheckboxWithLabelProps['onChange'] = (
    value
  ) => {
    setOnlyActiveBoxes(value);
  };

  const {
    data: { rentals },
    loading: allRentalsLoading,
    error,
  } = useAllRentals({
    variables: {
      pagination: {
        limit: numberOfRentals,
        offset: 0,
      },
      filter: transformFilterToAllRentalsGQLFilter({
        selectedBoxes,
        fromDate: undefined,
        selectedProducts: [],
        selectedGenders: [],
        onlyActiveBoxes: true,
        endDate: undefined,
      }),
    },
  });

  if (allRentalsLoading || allBoxesLoading || allOrganisationsLoading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'flex-end'}
        >
          <MultiSearchSelect
            label={'Boxes'}
            onChange={onBoxesChange}
            options={sortBoxes(allBoxes).map((box) => ({
              label: resolveBoxDisplayName(box),
              value: box.id,
            }))}
            value={selectedBoxes}
          />
          <Box ml={2}>
            <CheckboxWithLabel
              label={'Only active boxes'}
              labelPlacement={'start'}
              onChange={onOnlyActiveBoxesChange}
              value={onlyActiveBoxes}
            />
          </Box>
          <Box ml={2} />
          <SearchSelect
            label={'Organisations'}
            onChange={onOrganisationChange}
            options={allOrganisations.map((organisation) => ({
              label: organisation.name,
              value: organisation.id,
            }))}
            value={null}
          />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>Products in boxes</Typography>
          </Box>
          <ProductsInBoxes
            boxes={allBoxes
              .filter(
                selectedBoxesFilter(selectedBoxes.map(({ value }) => value))
              )
              .filter((box) => (onlyActiveBoxes ? box.active : true))}
          />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>
              {`Product share in latest ${numberOfRentals} rentals`}
            </Typography>
          </Box>
          <ProductsPie rentals={rentals} />
        </Box>
      </Paper>
    </Container>
  );
};
