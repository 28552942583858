import { Rental } from '../../../../common/types/rental';
import { toDate } from '../../../../common/utils/dateUtils';
import { sortWith } from '../../../../common/utils/sortWith';

const dateComparator = (a: RentalsPerDay, b: RentalsPerDay): number => {
  if (a.date === b.date) {
    return 0;
  }
  return a.date > b.date ? 1 : -1;
};

export const sortRentals = (rentals: RentalsPerDay[]): RentalsPerDay[] => {
  return sortWith(dateComparator)(rentals);
};

export interface RentalsPerDayData {
  date: string;
  numberOfRentals: number;
  numberOfRentalsSevenDaysAverage: number;
}

export interface RentalsPerDay {
  date: string;
  numberOfRentals: number;
}

export const getRentalsPerDay = (rentals: Rental[]): RentalsPerDay[] => {
  return Object.entries<number>(
    rentals.reduce<Record<string, number>>((acc, rental) => {
      const date = toDate(rental.rentStart);
      if (date) {
        if (acc[date]) {
          acc[date] = acc[date] + 1;
        } else {
          acc[date] = 1;
        }
      }
      return acc;
    }, {})
  ).map(([key, value]) => {
    return {
      date: key,
      numberOfRentals: value,
    };
  });
};

export const calculateMeanAverage = (
  array: RentalsPerDay[],
  currentPos: number,
  averageTime: number
): number => {
  let number = 0;
  for (let i = 0; i < averageTime; i++) {
    const rentalsPerDay = array[currentPos - i];
    if (rentalsPerDay) {
      number = number + rentalsPerDay.numberOfRentals;
    }
  }
  return number / Math.min(currentPos + 1, averageTime);
};

export const generateRentalsPerDayData = (
  rentals: Rental[],
  meanAverageTime: number
): RentalsPerDayData[] => {
  const sortedRentals = sortRentals(getRentalsPerDay(rentals));
  return sortedRentals.map((rental, index) => ({
    numberOfRentals: rental.numberOfRentals,
    date: rental.date,
    numberOfRentalsSevenDaysAverage: calculateMeanAverage(
      sortedRentals,
      index,
      meanAverageTime
    ),
  }));
};
