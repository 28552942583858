import {
  PaymentChargeFailStatus,
  PaymentChargeInformation,
  PaymentChargeSuccessStatus,
  Rental,
} from '../common/types/rental';
import { getTimeDifferenceInMinutes } from '../common/utils/dateUtils';
import {
  GQL_PaymentChargeFailStatus,
  GQL_PaymentChargeInformation,
  GQL_PaymentChargeSuccessStatus,
  GQL_RentalBox,
  GQL_RentalCompartment,
  GQL_RentalFragment,
} from '../generated/graphql';
import { transformLocation } from './transformLocation';
import { transformProduct } from './transformProduct';
import { transformProductStatusFromGQL } from './transformProductStatus';
import { transformReview } from './transformReview';

const transformRentalBox = (box: GQL_RentalBox): Rental['box'] => {
  return {
    id: box.id,
    name: box.name,
    location: transformLocation(box.location),
  };
};

const transformRentalCompartment = (
  gqlCompartment: GQL_RentalCompartment
): Rental['compartment'] => {
  return {
    id: gqlCompartment.id,
    name: gqlCompartment.name ?? undefined,
  };
};

export const transformRentalBy = (
  user: GQL_RentalFragment['rentBy']
): Rental['rentBy'] => ({
  id: user.id,
  email: user.email ?? undefined,
  gender: user.gender ?? undefined,
  firstName: user.firstName ?? undefined,
  lastName: user.lastName ?? undefined,
  phoneNumber: user.phoneNumber ?? undefined,
  birthYear: user.birthYear ?? undefined,
});

export const getRentTime = (
  rentStart: string | undefined | null,
  rentEnd: string | undefined | null
): number | undefined => {
  const timeDifferenceInMinutes = getTimeDifferenceInMinutes(
    rentStart,
    rentEnd
  );
  return timeDifferenceInMinutes !== undefined
    ? Math.max(1, timeDifferenceInMinutes)
    : undefined;
};

const transformFailStatus = (
  failStatus: GQL_PaymentChargeFailStatus
): PaymentChargeFailStatus => {
  switch (failStatus) {
    case GQL_PaymentChargeFailStatus.StolenCard:
      return 'stolen_card';
    case GQL_PaymentChargeFailStatus.LostCard:
      return 'lost_card';
    case GQL_PaymentChargeFailStatus.InsufficientFunds:
      return 'insufficient_funds';
    case GQL_PaymentChargeFailStatus.AuthenticationRequired:
      return 'authentication_required';
    case GQL_PaymentChargeFailStatus.Generic:
      return 'generic';
  }
};

const transformSuccessStatus = (
  success_status: GQL_PaymentChargeSuccessStatus
): PaymentChargeSuccessStatus => {
  switch (success_status) {
    case GQL_PaymentChargeSuccessStatus.Canceled:
      return 'canceled';
    case GQL_PaymentChargeSuccessStatus.Processing:
      return 'processing';
    case GQL_PaymentChargeSuccessStatus.RequiresAction:
      return 'requires_action';
    case GQL_PaymentChargeSuccessStatus.RequiresCapture:
      return 'requires_capture';
    case GQL_PaymentChargeSuccessStatus.RequiresConfirmation:
      return 'requires_confirmation';
    case GQL_PaymentChargeSuccessStatus.RequiresPaymentMethod:
      return 'requires_payment_method';
    case GQL_PaymentChargeSuccessStatus.Succeeded:
      return 'succeeded';
  }
};

const transformPaymentChargeInformation = (
  paymentChargeInformation: GQL_PaymentChargeInformation
): PaymentChargeInformation | undefined => {
  switch (paymentChargeInformation.__typename) {
    case 'PaymentChargeInformationFreeRent':
      return {
        type: 'free_rent',
        amount: paymentChargeInformation.amount,
        currency: paymentChargeInformation.currency,
      };
    case 'PaymentChargeInformationSuccess':
      return {
        type: 'success',
        amount: paymentChargeInformation.amount,
        clientSecret: paymentChargeInformation.clientSecret ?? undefined,
        currency: paymentChargeInformation.currency,
        status: transformSuccessStatus(paymentChargeInformation.success_status),
      };
    case 'PaymentChargeInformationFail':
      return {
        type: 'fail',
        amount: paymentChargeInformation.amount,
        clientSecret: paymentChargeInformation.clientSecret ?? undefined,
        currency: paymentChargeInformation.currency,
        status: transformFailStatus(paymentChargeInformation.fail_status),
      };
    default:
      // TODO: This can never happen. How to avoid?
      return undefined;
  }
};

export const transformRental = (rental: GQL_RentalFragment): Rental => ({
  id: rental.id,
  aborted: rental.aborted ?? undefined,
  box: transformRentalBox(rental.box),
  compartment: transformRentalCompartment(rental.compartment),
  paymentChargeInformation: rental.paymentChargeInformation
    ? transformPaymentChargeInformation(rental.paymentChargeInformation)
    : undefined,
  product: transformProduct(rental.product),
  productStatus: rental.productStatus
    ? transformProductStatusFromGQL(rental.productStatus)
    : undefined,
  productStatusText: rental.productStatusText ?? undefined,
  maxRentTimeInMinutes: rental.maxRentTimeInMinutes,
  numberOfFailedOpenings: rental.numberOfFailedOpenings,
  numberOfSuccessfulOpenings: rental.numberOfSuccessfulOpenings,
  rentBy: transformRentalBy(rental.rentBy),
  rentStartBy: transformRentalBy(rental.rentStartBy),
  rentEndBy: rental.rentEndBy ? transformRentalBy(rental.rentEndBy) : undefined,
  rentStart: rental.rentStart ?? undefined,
  rentEnd: rental.rentEnd ?? undefined,
  rentTime: getRentTime(rental.rentStart, rental.rentEnd),
  review: rental.review ? transformReview(rental.review) : undefined,
});
