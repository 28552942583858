import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export type GQL_AbortRentalInput = {
  productStatus: GQL_ProductStatus;
  productStatusText?: InputMaybe<Scalars['String']['input']>;
  rentalId: Scalars['ID']['input'];
};

export type GQL_AbortRentalResponse = {
  __typename?: 'AbortRentalResponse';
  compartment?: Maybe<GQL_Compartment>;
  rental?: Maybe<GQL_Rental>;
  type?: Maybe<GQL_AbortRentalResponseType>;
};

export enum GQL_AbortRentalResponseType {
  CompartmentIsNotRented = 'CompartmentIsNotRented',
  NoPermissionToStopRental = 'NoPermissionToStopRental',
  Success = 'Success',
}

export type GQL_Activity = {
  __typename?: 'Activity';
  code: Scalars['String']['output'];
};

export type GQL_ActivityArea = {
  __typename?: 'ActivityArea';
  active: Scalars['Boolean']['output'];
  activities: Array<Scalars['String']['output']>;
  areaInfos: Array<GQL_AreaInfo>;
  id: Scalars['ID']['output'];
  location: GQL_Location;
  type: Scalars['String']['output'];
  verified: Scalars['Boolean']['output'];
};

export type GQL_ActivityAreaSearchRoot = {
  __typename?: 'ActivityAreaSearchRoot';
  activityAreaById?: Maybe<GQL_ActivityArea>;
  allActivities: Array<GQL_Activity>;
  allActivityAreas: Array<GQL_ActivityArea>;
};

export type GQL_ActivityAreaSearchRootActivityAreaByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_AddActivityAreaInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activities: Array<Scalars['String']['input']>;
  areaInfos: Array<GQL_AreaInfoInput>;
  location: GQL_AddLocationInput;
  type: Scalars['String']['input'];
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQL_AddActivityAreaResponse = {
  __typename?: 'AddActivityAreaResponse';
  activityArea?: Maybe<GQL_ActivityArea>;
  type?: Maybe<GQL_AddActivityAreaResponseType>;
};

export enum GQL_AddActivityAreaResponseType {
  Generic = 'Generic',
  NoPermissionToAddActivityArea = 'NoPermissionToAddActivityArea',
  Success = 'Success',
}

export type GQL_AddBatteryReadingResponse = {
  __typename?: 'AddBatteryReadingResponse';
  type: GQL_AddBatteryReadingResponseType;
};

export enum GQL_AddBatteryReadingResponseType {
  Generic = 'Generic',
  Success = 'Success',
}

export type GQL_AddBoxCompartmentInput = {
  circuitBoardPort: Scalars['Int']['input'];
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQL_AddBoxInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  circuitBoardId: Scalars['String']['input'];
  color?: InputMaybe<GQL_BoxColor>;
  compartments: Array<GQL_AddBoxCompartmentInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<GQL_AddLocationInput>;
  name: Scalars['String']['input'];
  openEndTime?: InputMaybe<Scalars['String']['input']>;
  openStartTime?: InputMaybe<Scalars['String']['input']>;
  priceWeight?: InputMaybe<Scalars['ID']['input']>;
  sponsorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQL_AddBoxResponse = {
  __typename?: 'AddBoxResponse';
  box?: Maybe<GQL_Box>;
  type?: Maybe<GQL_AddBoxResponseType>;
};

export enum GQL_AddBoxResponseType {
  Generic = 'Generic',
  NoPermissionToAddBox = 'NoPermissionToAddBox',
  Success = 'Success',
}

export type GQL_AddCreditCardInput = {
  token: Scalars['String']['input'];
};

export type GQL_AddCreditCardResponse = {
  __typename?: 'AddCreditCardResponse';
  createCreditCardResponse?: Maybe<GQL_CreateCreditCardResponse>;
  type?: Maybe<GQL_AddCreditCardResponseType>;
};

export enum GQL_AddCreditCardResponseType {
  CouldNotCreatePaymentCustomer = 'CouldNotCreatePaymentCustomer',
  NoPermissionToAdd = 'NoPermissionToAdd',
  Success = 'Success',
}

export type GQL_AddFeedbackInput = {
  freeText: Scalars['String']['input'];
};

export type GQL_AddFeedbackResponse = {
  __typename?: 'AddFeedbackResponse';
  feedback?: Maybe<GQL_Feedback>;
  type?: Maybe<GQL_AddFeedbackResponseType>;
};

export enum GQL_AddFeedbackResponseType {
  Generic = 'Generic',
  NoPermissionToAddFeedback = 'NoPermissionToAddFeedback',
  Success = 'Success',
}

export type GQL_AddLocationInput = {
  coordinates?: InputMaybe<GQL_CoordinatesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_AddOrganisationInput = {
  boxIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactEmail: Scalars['String']['input'];
  contactName: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_AddOrganisationResponse = {
  __typename?: 'AddOrganisationResponse';
  organisation?: Maybe<GQL_Organisation>;
  type: GQL_AddOrganisationResponseType;
};

export enum GQL_AddOrganisationResponseType {
  Generic = 'Generic',
  NoPermissionToAddOrganisation = 'NoPermissionToAddOrganisation',
  Success = 'Success',
}

export type GQL_AddPendingUserResponse = {
  __typename?: 'AddPendingUserResponse';
  pendingUser?: Maybe<GQL_PendingUser>;
  status: GQL_AddPendingUserResponseType;
};

export enum GQL_AddPendingUserResponseType {
  Generic = 'Generic',
  NoPermissionToAddPendingUser = 'NoPermissionToAddPendingUser',
  Success = 'Success',
  UserAlreadyExists = 'UserAlreadyExists',
}

export type GQL_AddPendingUsersInput = {
  emails: Array<Scalars['String']['input']>;
  organisations: Array<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
};

export type GQL_AddPriceWeightInput = {
  fifthHour: Scalars['Int']['input'];
  firstHour: Scalars['Int']['input'];
  fourthHour: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  penalty: Scalars['Int']['input'];
  secondHour: Scalars['Int']['input'];
  sixthHour: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
  thirdHour: Scalars['Int']['input'];
};

export type GQL_AddPriceWeightResponse = {
  __typename?: 'AddPriceWeightResponse';
  priceWeight?: Maybe<GQL_PriceWeight>;
  status: GQL_AddPriceWeightResponseType;
};

export enum GQL_AddPriceWeightResponseType {
  Generic = 'Generic',
  NoPermissionToAddPriceWeight = 'NoPermissionToAddPriceWeight',
  Success = 'Success',
}

export type GQL_AddProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  priceWeight?: InputMaybe<Scalars['ID']['input']>;
  rentalType?: InputMaybe<GQL_ProductRentalType>;
  translationKey?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_AddProductResponse = {
  __typename?: 'AddProductResponse';
  product?: Maybe<GQL_Product>;
};

export type GQL_AddReviewResponse = {
  __typename?: 'AddReviewResponse';
  review?: Maybe<GQL_Review>;
  type?: Maybe<GQL_AddReviewResponseType>;
};

export enum GQL_AddReviewResponseType {
  Generic = 'Generic',
  Success = 'Success',
}

export type GQL_AddRoleInput = {
  name: Scalars['String']['input'];
  permissionIds: Array<Scalars['ID']['input']>;
};

export type GQL_AddRoleResponse = {
  __typename?: 'AddRoleResponse';
  role?: Maybe<GQL_Role>;
  type: GQL_AddRoleResponseType;
};

export enum GQL_AddRoleResponseType {
  Generic = 'Generic',
  NoPermissionToAddRole = 'NoPermissionToAddRole',
  Success = 'Success',
}

export type GQL_AddSponsorInput = {
  imageName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_AddSponsorResponse = {
  __typename?: 'AddSponsorResponse';
  sponsor?: Maybe<GQL_Sponsor>;
};

export type GQL_AddUserInput = {
  birthYear?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GQL_Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  priceWeight?: InputMaybe<Scalars['ID']['input']>;
  type: GQL_User_Type;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_AddUserResponse = {
  __typename?: 'AddUserResponse';
  user?: Maybe<GQL_User>;
};

export type GQL_AllRentalsResponse = {
  __typename?: 'AllRentalsResponse';
  nodes: Array<GQL_Rental>;
  totalCount: Scalars['Int']['output'];
};

export type GQL_AllUsersResponse = {
  __typename?: 'AllUsersResponse';
  nodes: Array<GQL_User>;
  totalCount: Scalars['Int']['output'];
};

export type GQL_AppPermission = {
  __typename?: 'AppPermission';
  type: GQL_AppPermissionType;
};

export enum GQL_AppPermissionType {
  AdminBox = 'ADMIN_BOX',
  AdminCompartment = 'ADMIN_COMPARTMENT',
}

export type GQL_AreaInfo = {
  __typename?: 'AreaInfo';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type GQL_AreaInfoInput = {
  count: Scalars['Int']['input'];
  id: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type GQL_BatteryReading = {
  __typename?: 'BatteryReading';
  box: GQL_Box;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
  voltage: Scalars['Int']['output'];
};

export type GQL_BatteryReadingInput = {
  boxId: Scalars['ID']['input'];
  voltage: Scalars['Int']['input'];
};

export type GQL_BatteryReadingRoot = {
  __typename?: 'BatteryReadingRoot';
  allBatteryReadings: Array<GQL_BatteryReading>;
  batteryReadingsByBoxId: Array<GQL_BatteryReading>;
};

export type GQL_BatteryReadingRootBatteryReadingsByBoxIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_Box = {
  __typename?: 'Box';
  active: Scalars['Boolean']['output'];
  circuitBoardId: Scalars['String']['output'];
  color: GQL_BoxColor;
  compartments: Array<GQL_Compartment>;
  deprecated: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  initialActivationTime?: Maybe<Scalars['String']['output']>;
  location: GQL_Location;
  name: Scalars['String']['output'];
  openEndTime: Scalars['String']['output'];
  openStartTime: Scalars['String']['output'];
  /** @deprecated No longer supported */
  priceWeight: Scalars['Int']['output'];
  priceWeights: GQL_PriceWeight;
  sponsor?: Maybe<GQL_Sponsor>;
};

export enum GQL_BoxColor {
  Hex2f3234 = 'HEX2f3234',
  Hex903373 = 'HEX903373',
}

export type GQL_BoxSearchRoot = {
  __typename?: 'BoxSearchRoot';
  allBoxes: Array<GQL_Box>;
  boxById?: Maybe<GQL_Box>;
};

export type GQL_BoxSearchRootBoxByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_ChargeRentalInput = {
  chargeAmount?: InputMaybe<GQL_InputPrice>;
  rentalId: Scalars['ID']['input'];
};

export type GQL_ChargeRentalResponse = {
  __typename?: 'ChargeRentalResponse';
  paymentChargeInformation?: Maybe<GQL_PaymentChargeInformation>;
  rental?: Maybe<GQL_Rental>;
  type?: Maybe<GQL_ChargeRentalResponseType>;
};

export enum GQL_ChargeRentalResponseType {
  CanNotChargeOngoingRentals = 'CanNotChargeOngoingRentals',
  Generic = 'Generic',
  NoPermissionToChargeRental = 'NoPermissionToChargeRental',
  RentalAlreadyCharged = 'RentalAlreadyCharged',
  RentalDoesNotExist = 'RentalDoesNotExist',
  Success = 'Success',
}

export type GQL_Compartment = {
  __typename?: 'Compartment';
  circuitBoardPort: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  ongoingRental?: Maybe<GQL_CompartmentOngoingRental>;
  priceInfo: GQL_PriceInfoTranslations;
  priceStructure: GQL_PriceStructure;
  private?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<GQL_CompartmentProduct>;
  rentals: Array<GQL_Rental>;
};

export type GQL_CompartmentOngoingRental = {
  __typename?: 'CompartmentOngoingRental';
  id: Scalars['ID']['output'];
  maxRentTimeInMinutes: Scalars['Int']['output'];
  rentBy: GQL_User;
  rentStart: Scalars['String']['output'];
};

export type GQL_CompartmentProduct = {
  __typename?: 'CompartmentProduct';
  product: GQL_Product;
  productStatus?: Maybe<GQL_ProductStatus>;
};

export type GQL_CompartmentSearchRoot = {
  __typename?: 'CompartmentSearchRoot';
  compartmentById?: Maybe<GQL_Compartment>;
};

export type GQL_CompartmentSearchRootCompartmentByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float']['output'];
  long: Scalars['Float']['output'];
};

export type GQL_CoordinatesInput = {
  lat: Scalars['Float']['input'];
  long: Scalars['Float']['input'];
};

export type GQL_CreateBoxBatteryPayloadResponse = {
  __typename?: 'CreateBoxBatteryPayloadResponse';
  payload?: Maybe<Scalars['String']['output']>;
};

export type GQL_CreateCompartmentOpenPayloadResponse = {
  __typename?: 'CreateCompartmentOpenPayloadResponse';
  payload?: Maybe<Scalars['String']['output']>;
};

export type GQL_CreateCompartmentSensePayloadResponse = {
  __typename?: 'CreateCompartmentSensePayloadResponse';
  payload?: Maybe<Scalars['String']['output']>;
};

export type GQL_CreateCreditCardFailResponse =
  GQL_CreateCreditCardResponseInterface & {
    __typename?: 'CreateCreditCardFailResponse';
    fail_status: GQL_CreateCreditCardFailStatus;
    id: Scalars['ID']['output'];
    type: GQL_CreateCreditCardResponseType;
  };

export enum GQL_CreateCreditCardFailStatus {
  Generic = 'generic',
}

export type GQL_CreateCreditCardResponse =
  | GQL_CreateCreditCardFailResponse
  | GQL_CreateCreditCardSuccessResponse;

export type GQL_CreateCreditCardResponseInterface = {
  id: Scalars['ID']['output'];
  type: GQL_CreateCreditCardResponseType;
};

export enum GQL_CreateCreditCardResponseType {
  Fail = 'fail',
  Success = 'success',
}

export type GQL_CreateCreditCardSuccessResponse =
  GQL_CreateCreditCardResponseInterface & {
    __typename?: 'CreateCreditCardSuccessResponse';
    id: Scalars['ID']['output'];
    paymentInformation: GQL_PaymentInformation;
    type: GQL_CreateCreditCardResponseType;
  };

export type GQL_CreatePaymentSetupIntentResponse = {
  __typename?: 'CreatePaymentSetupIntentResponse';
  clientSecret?: Maybe<Scalars['String']['output']>;
  status: GQL_PaymentCardStatus;
};

export type GQL_CreateRentalImageGetUrlResponse = {
  __typename?: 'CreateRentalImageGetUrlResponse';
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type GQL_CreateRentalImagePutUrlResponse = {
  __typename?: 'CreateRentalImagePutUrlResponse';
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type GQL_DateFilterInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_DeleteBoxResponse = {
  __typename?: 'DeleteBoxResponse';
  type: GQL_DeleteBoxResponseType;
};

export enum GQL_DeleteBoxResponseType {
  BoxDoesNotExist = 'BoxDoesNotExist',
  Generic = 'Generic',
  NoPermissionToDeleteBox = 'NoPermissionToDeleteBox',
  Success = 'Success',
}

export type GQL_DeleteCurrentUserResponse = {
  __typename?: 'DeleteCurrentUserResponse';
  type?: Maybe<GQL_DeleteCurrentUserResponseType>;
  user?: Maybe<GQL_User>;
};

export enum GQL_DeleteCurrentUserResponseType {
  Generic = 'Generic',
  NoPermissionToDeleteCurrentUser = 'NoPermissionToDeleteCurrentUser',
  Success = 'Success',
  UserHasOngoingRentals = 'UserHasOngoingRentals',
}

export type GQL_EditActivityAreaInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activities?: InputMaybe<Array<Scalars['String']['input']>>;
  areaInfos?: InputMaybe<Array<GQL_AreaInfoInput>>;
  location?: InputMaybe<GQL_EditLocationInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GQL_EditActivityAreaResponse = {
  __typename?: 'EditActivityAreaResponse';
  activityArea?: Maybe<GQL_ActivityArea>;
  type?: Maybe<GQL_EditActivityAreaResponseType>;
};

export enum GQL_EditActivityAreaResponseType {
  ActivityAreaDoesNotExist = 'ActivityAreaDoesNotExist',
  Generic = 'Generic',
  NoPermissionToEditActivityArea = 'NoPermissionToEditActivityArea',
  Success = 'Success',
}

export type GQL_EditBoxInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  circuitBoardId?: InputMaybe<Scalars['String']['input']>;
  color?: InputMaybe<GQL_BoxColor>;
  deprecated?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<GQL_EditLocationInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  openEndTime?: InputMaybe<Scalars['String']['input']>;
  openStartTime?: InputMaybe<Scalars['String']['input']>;
  priceWeight?: InputMaybe<Scalars['ID']['input']>;
  sponsorId?: InputMaybe<Scalars['ID']['input']>;
};

export type GQL_EditBoxResponse = {
  __typename?: 'EditBoxResponse';
  box?: Maybe<GQL_Box>;
  type?: Maybe<GQL_EditBoxResponseType>;
};

export enum GQL_EditBoxResponseType {
  BoxDoesNotExist = 'BoxDoesNotExist',
  Generic = 'Generic',
  NoPermissionToEditBox = 'NoPermissionToEditBox',
  Success = 'Success',
}

export type GQL_EditCompartmentInput = {
  circuitBoardPort?: InputMaybe<Scalars['Int']['input']>;
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<GQL_EditCompartmentProductInput>;
};

export type GQL_EditCompartmentProductInput = {
  productId: Scalars['ID']['input'];
  productStatus?: InputMaybe<GQL_ProductStatus>;
};

export type GQL_EditCompartmentResponse = {
  __typename?: 'EditCompartmentResponse';
  compartment?: Maybe<GQL_Compartment>;
};

export type GQL_EditCompartmentsInput = {
  circuitBoardPort?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['ID']['input'];
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  private?: InputMaybe<Scalars['Boolean']['input']>;
  product?: InputMaybe<GQL_EditCompartmentProductInput>;
};

export type GQL_EditCompartmentsResponse = {
  __typename?: 'EditCompartmentsResponse';
  compartments: Array<GQL_Compartment>;
};

export type GQL_EditLocationInput = {
  coordinates?: InputMaybe<GQL_CoordinatesInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_EditOrganisationInput = {
  boxIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  city?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_EditOrganisationResponse = {
  __typename?: 'EditOrganisationResponse';
  organisation?: Maybe<GQL_Organisation>;
  type: GQL_EditOrganisationResponseType;
};

export enum GQL_EditOrganisationResponseType {
  Generic = 'Generic',
  NoPermissionToEditOrganisation = 'NoPermissionToEditOrganisation',
  Success = 'Success',
}

export type GQL_EditPendingUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  organisations?: InputMaybe<Array<Scalars['String']['input']>>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type GQL_EditPendingUserResponse = {
  __typename?: 'EditPendingUserResponse';
  pendingUser?: Maybe<GQL_PendingUser>;
  status: GQL_EditPendingUserResponseType;
};

export enum GQL_EditPendingUserResponseType {
  Generic = 'Generic',
  NoPermissionToEditPendingUser = 'NoPermissionToEditPendingUser',
  Success = 'Success',
}

export type GQL_EditPriceStructureInput = {
  fifthHour: Scalars['Int']['input'];
  firstHour: Scalars['Int']['input'];
  fourthHour: Scalars['Int']['input'];
  penalty: Scalars['Int']['input'];
  secondHour: Scalars['Int']['input'];
  sixthHour: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
  thirdHour: Scalars['Int']['input'];
};

export type GQL_EditPriceStructureResponse = {
  __typename?: 'EditPriceStructureResponse';
  priceStructure: GQL_PriceStructure;
};

export type GQL_EditPriceWeightInput = {
  fifthHour: Scalars['Int']['input'];
  firstHour: Scalars['Int']['input'];
  fourthHour: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  penalty: Scalars['Int']['input'];
  secondHour: Scalars['Int']['input'];
  sixthHour: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
  thirdHour: Scalars['Int']['input'];
};

export type GQL_EditPriceWeightResponse = {
  __typename?: 'EditPriceWeightResponse';
  priceWeight?: Maybe<GQL_PriceWeight>;
  status: GQL_EditPriceWeightResponseType;
};

export enum GQL_EditPriceWeightResponseType {
  Generic = 'Generic',
  NoPermissionToEditPriceWeight = 'NoPermissionToEditPriceWeight',
  Success = 'Success',
}

export type GQL_EditProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  imageName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priceWeight?: InputMaybe<Scalars['ID']['input']>;
  rentalType?: InputMaybe<GQL_ProductRentalType>;
  translationKey?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_EditProductResponse = {
  __typename?: 'EditProductResponse';
  product?: Maybe<GQL_Product>;
};

export type GQL_EditRoleInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  permissionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQL_EditRoleResponse = {
  __typename?: 'EditRoleResponse';
  role?: Maybe<GQL_Role>;
  type: GQL_EditRoleResponseType;
};

export enum GQL_EditRoleResponseType {
  Generic = 'Generic',
  NoPermissionToEditRole = 'NoPermissionToEditRole',
  Success = 'Success',
}

export type GQL_EditSponsorInput = {
  imageName: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_EditSponsorResponse = {
  __typename?: 'EditSponsorResponse';
  sponsor?: Maybe<GQL_Sponsor>;
};

export type GQL_EditUserInput = {
  birthYear?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<GQL_Gender>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisations?: InputMaybe<Array<Scalars['ID']['input']>>;
  permissions?: InputMaybe<Array<Scalars['ID']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  priceWeight?: InputMaybe<Scalars['ID']['input']>;
  roles?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<GQL_User_Type>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type GQL_EditUserResponse = {
  __typename?: 'EditUserResponse';
  user?: Maybe<GQL_User>;
};

export type GQL_Feedback = {
  __typename?: 'Feedback';
  date: Scalars['String']['output'];
  freeText: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  user?: Maybe<GQL_User>;
};

export type GQL_FeedbackSearchRoot = {
  __typename?: 'FeedbackSearchRoot';
  allFeedbacks: Array<GQL_Feedback>;
  feedbackById?: Maybe<GQL_Feedback>;
};

export type GQL_FeedbackSearchRootFeedbackByIdArgs = {
  id: Scalars['ID']['input'];
};

export enum GQL_Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
}

export type GQL_InputPrice = {
  amount: Scalars['Int']['input'];
  currency: Scalars['String']['input'];
};

export type GQL_Location = {
  __typename?: 'Location';
  coordinates?: Maybe<GQL_Coordinates>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type GQL_LockCompartmentResponse = {
  __typename?: 'LockCompartmentResponse';
  compartment?: Maybe<GQL_Compartment>;
};

export type GQL_LockerPayload = {
  __typename?: 'LockerPayload';
  payload?: Maybe<Scalars['String']['output']>;
};

export type GQL_Mutation = {
  __typename?: 'Mutation';
  abortRental: GQL_AbortRentalResponse;
  addActivityArea: GQL_AddActivityAreaResponse;
  addBatteryReading: GQL_AddBatteryReadingResponse;
  addBox: GQL_AddBoxResponse;
  addCreditCard: GQL_AddCreditCardResponse;
  addFeedback: GQL_AddFeedbackResponse;
  addOrganisation: GQL_AddOrganisationResponse;
  addPendingUsers: Array<GQL_AddPendingUserResponse>;
  addPriceWeight: GQL_AddPriceWeightResponse;
  addProduct: GQL_AddProductResponse;
  addRole: GQL_AddRoleResponse;
  addSponsor: GQL_AddSponsorResponse;
  addUser: GQL_AddUserResponse;
  chargeRental: GQL_ChargeRentalResponse;
  createBoxBatteryPayload: GQL_CreateBoxBatteryPayloadResponse;
  createCompartmentOpenPayload: GQL_CreateCompartmentOpenPayloadResponse;
  createCompartmentSensePayload: GQL_CreateCompartmentOpenPayloadResponse;
  createPaymentSetupIntent: GQL_CreatePaymentSetupIntentResponse;
  createRentalImageGetUrl: GQL_CreateRentalImageGetUrlResponse;
  createRentalImagePutUrl: GQL_CreateRentalImagePutUrlResponse;
  deleteBox: GQL_DeleteBoxResponse;
  deleteCurrentUser: GQL_DeleteCurrentUserResponse;
  editActivityArea: GQL_EditActivityAreaResponse;
  editBox: GQL_EditBoxResponse;
  editCompartment: GQL_EditCompartmentResponse;
  editCompartments: GQL_EditCompartmentsResponse;
  editOrganisation: GQL_EditOrganisationResponse;
  editPendingUser: GQL_EditPendingUserResponse;
  editPriceStructure: GQL_EditPriceStructureResponse;
  editPriceWeight: GQL_EditPriceWeightResponse;
  editProduct: GQL_EditProductResponse;
  editRole: GQL_EditRoleResponse;
  editSponsor: GQL_EditSponsorResponse;
  editUser: GQL_EditUserResponse;
  lockCompartment: GQL_LockCompartmentResponse;
  openCompartmentPressed: GQL_OpenCompartmentPressedResponse;
  removeCreditCard: GQL_RemoveCreditCardResponse;
  removePendingUser: GQL_RemovePendingUserResponse;
  startRental: GQL_StartRentalResponse;
  stopRental: GQL_StopRentalResponse;
  unlockCompartment: GQL_UnlockCompartmentResponse;
  upsertRentalReview: GQL_UpsertRentalReviewResponse;
};

export type GQL_MutationAbortRentalArgs = {
  rentalInput: GQL_AbortRentalInput;
};

export type GQL_MutationAddActivityAreaArgs = {
  activityArea: GQL_AddActivityAreaInput;
};

export type GQL_MutationAddBatteryReadingArgs = {
  batteryReading: GQL_BatteryReadingInput;
};

export type GQL_MutationAddBoxArgs = {
  box: GQL_AddBoxInput;
};

export type GQL_MutationAddCreditCardArgs = {
  addCreditCard: GQL_AddCreditCardInput;
};

export type GQL_MutationAddFeedbackArgs = {
  feedback: GQL_AddFeedbackInput;
};

export type GQL_MutationAddOrganisationArgs = {
  organisation: GQL_AddOrganisationInput;
};

export type GQL_MutationAddPendingUsersArgs = {
  pendingUsers: GQL_AddPendingUsersInput;
};

export type GQL_MutationAddPriceWeightArgs = {
  priceWeight: GQL_AddPriceWeightInput;
};

export type GQL_MutationAddProductArgs = {
  product: GQL_AddProductInput;
};

export type GQL_MutationAddRoleArgs = {
  role: GQL_AddRoleInput;
};

export type GQL_MutationAddSponsorArgs = {
  sponsor: GQL_AddSponsorInput;
};

export type GQL_MutationAddUserArgs = {
  user: GQL_AddUserInput;
};

export type GQL_MutationChargeRentalArgs = {
  chargeRentalInput: GQL_ChargeRentalInput;
};

export type GQL_MutationCreateBoxBatteryPayloadArgs = {
  boxId: Scalars['ID']['input'];
};

export type GQL_MutationCreateCompartmentOpenPayloadArgs = {
  compartmentId: Scalars['ID']['input'];
};

export type GQL_MutationCreateCompartmentSensePayloadArgs = {
  compartmentId: Scalars['ID']['input'];
};

export type GQL_MutationCreateRentalImageGetUrlArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_MutationCreateRentalImagePutUrlArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_MutationDeleteBoxArgs = {
  boxId: Scalars['ID']['input'];
};

export type GQL_MutationEditActivityAreaArgs = {
  activityArea: GQL_EditActivityAreaInput;
  activityAreaId: Scalars['ID']['input'];
};

export type GQL_MutationEditBoxArgs = {
  box: GQL_EditBoxInput;
  boxId: Scalars['ID']['input'];
};

export type GQL_MutationEditCompartmentArgs = {
  compartment: GQL_EditCompartmentInput;
  compartmentId: Scalars['ID']['input'];
};

export type GQL_MutationEditCompartmentsArgs = {
  compartments: Array<GQL_EditCompartmentsInput>;
};

export type GQL_MutationEditOrganisationArgs = {
  organisation: GQL_EditOrganisationInput;
  organisationId: Scalars['ID']['input'];
};

export type GQL_MutationEditPendingUserArgs = {
  pendingUser: GQL_EditPendingUserInput;
  pendingUserId: Scalars['ID']['input'];
};

export type GQL_MutationEditPriceStructureArgs = {
  editPriceStructureInput: GQL_EditPriceStructureInput;
  priceStructureId: Scalars['ID']['input'];
};

export type GQL_MutationEditPriceWeightArgs = {
  priceWeight: GQL_EditPriceWeightInput;
  priceWeightId: Scalars['ID']['input'];
};

export type GQL_MutationEditProductArgs = {
  product: GQL_EditProductInput;
  productId: Scalars['ID']['input'];
};

export type GQL_MutationEditRoleArgs = {
  role: GQL_EditRoleInput;
  roleId: Scalars['ID']['input'];
};

export type GQL_MutationEditSponsorArgs = {
  sponsor: GQL_EditSponsorInput;
  sponsorId: Scalars['ID']['input'];
};

export type GQL_MutationEditUserArgs = {
  user: GQL_EditUserInput;
  userId: Scalars['ID']['input'];
};

export type GQL_MutationLockCompartmentArgs = {
  compartmentId: Scalars['ID']['input'];
};

export type GQL_MutationOpenCompartmentPressedArgs = {
  input: GQL_OpenCompartmentPressedInput;
};

export type GQL_MutationRemovePendingUserArgs = {
  pendingUserId: Scalars['ID']['input'];
};

export type GQL_MutationStartRentalArgs = {
  rentalInput: GQL_StartRentalInput;
};

export type GQL_MutationStopRentalArgs = {
  rentalInput: GQL_StopRentalInput;
};

export type GQL_MutationUnlockCompartmentArgs = {
  compartmentId: Scalars['ID']['input'];
};

export type GQL_MutationUpsertRentalReviewArgs = {
  rentalReviewInput: GQL_UpsertRentalReviewInput;
};

export type GQL_OpenCompartmentPressedInput = {
  rentalId: Scalars['String']['input'];
  success: Scalars['Boolean']['input'];
};

export type GQL_OpenCompartmentPressedResponse = {
  __typename?: 'OpenCompartmentPressedResponse';
  rental?: Maybe<GQL_Rental>;
  type?: Maybe<GQL_OpenCompartmentPressedResponseType>;
};

export enum GQL_OpenCompartmentPressedResponseType {
  Fail = 'Fail',
  Success = 'Success',
}

export type GQL_Organisation = {
  __typename?: 'Organisation';
  boxes: Array<GQL_Box>;
  city?: Maybe<Scalars['String']['output']>;
  contactEmail: Scalars['String']['output'];
  contactName: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  users: Array<GQL_User>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type GQL_OrganisationSearchRoot = {
  __typename?: 'OrganisationSearchRoot';
  allOrganisations: Array<GQL_Organisation>;
  organisationById?: Maybe<GQL_Organisation>;
};

export type GQL_OrganisationSearchRootOrganisationByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type GQL_PaymentCard = {
  __typename?: 'PaymentCard';
  brand: Scalars['String']['output'];
  clientSecret?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last4: Scalars['String']['output'];
  status?: Maybe<GQL_PaymentCardStatus>;
};

export enum GQL_PaymentCardStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
}

export enum GQL_PaymentChargeFailStatus {
  AuthenticationRequired = 'authentication_required',
  Generic = 'generic',
  InsufficientFunds = 'insufficient_funds',
  LostCard = 'lost_card',
  StolenCard = 'stolen_card',
}

export type GQL_PaymentChargeInformation =
  | GQL_PaymentChargeInformationFail
  | GQL_PaymentChargeInformationFreeRent
  | GQL_PaymentChargeInformationSuccess;

export type GQL_PaymentChargeInformationFail =
  GQL_PaymentChargeInformationInterface & {
    __typename?: 'PaymentChargeInformationFail';
    amount: Scalars['Int']['output'];
    clientSecret?: Maybe<Scalars['String']['output']>;
    currency: Scalars['String']['output'];
    fail_status: GQL_PaymentChargeFailStatus;
    id: Scalars['ID']['output'];
    paymentIntentId?: Maybe<Scalars['String']['output']>;
    type: GQL_PaymentChargeInformationType;
  };

export type GQL_PaymentChargeInformationFreeRent =
  GQL_PaymentChargeInformationInterface & {
    __typename?: 'PaymentChargeInformationFreeRent';
    amount: Scalars['Int']['output'];
    currency: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    type: GQL_PaymentChargeInformationType;
  };

export type GQL_PaymentChargeInformationInterface = {
  id: Scalars['ID']['output'];
  type: GQL_PaymentChargeInformationType;
};

export type GQL_PaymentChargeInformationSuccess =
  GQL_PaymentChargeInformationInterface & {
    __typename?: 'PaymentChargeInformationSuccess';
    amount: Scalars['Int']['output'];
    clientSecret?: Maybe<Scalars['String']['output']>;
    currency: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    paymentIntentId?: Maybe<Scalars['String']['output']>;
    success_status: GQL_PaymentChargeSuccessStatus;
    type: GQL_PaymentChargeInformationType;
  };

export enum GQL_PaymentChargeInformationType {
  Fail = 'fail',
  FreeRent = 'free_rent',
  Success = 'success',
}

export enum GQL_PaymentChargeSuccessStatus {
  Canceled = 'canceled',
  Processing = 'processing',
  RequiresAction = 'requires_action',
  RequiresCapture = 'requires_capture',
  RequiresConfirmation = 'requires_confirmation',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded',
}

export type GQL_PaymentInformation = {
  __typename?: 'PaymentInformation';
  cards: Array<GQL_PaymentCard>;
  customerId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type GQL_PaymentSearchRoot = {
  __typename?: 'PaymentSearchRoot';
  allPriceWeights: Array<GQL_PriceWeight>;
  priceStructure: GQL_PriceStructure;
  priceWeightById?: Maybe<GQL_PriceWeight>;
};

export type GQL_PaymentSearchRootPriceWeightByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_PendingUser = {
  __typename?: 'PendingUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organisations: Array<GQL_Organisation>;
  roles: Array<GQL_Role>;
};

export type GQL_PendingUserSearchRoot = {
  __typename?: 'PendingUserSearchRoot';
  allPendingUsers: Array<GQL_PendingUser>;
  pendingUserById?: Maybe<GQL_PendingUser>;
};

export type GQL_PendingUserSearchRootPendingUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_Permission = {
  __typename?: 'Permission';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type: GQL_PermissionType;
};

export type GQL_PermissionSearchRoot = {
  __typename?: 'PermissionSearchRoot';
  allPermissions: Array<GQL_Permission>;
  permissionById?: Maybe<GQL_Permission>;
};

export type GQL_PermissionSearchRootPermissionByIdArgs = {
  permissionId: Scalars['ID']['input'];
};

export enum GQL_PermissionType {
  AddActivityAreas = 'ADD_ACTIVITY_AREAS',
  AddBatteryReadings = 'ADD_BATTERY_READINGS',
  AddBoxes = 'ADD_BOXES',
  AddFeedback = 'ADD_FEEDBACK',
  AddOrganisations = 'ADD_ORGANISATIONS',
  AddPendingUsers = 'ADD_PENDING_USERS',
  AddPermissions = 'ADD_PERMISSIONS',
  AddPriceWeights = 'ADD_PRICE_WEIGHTS',
  AddProducts = 'ADD_PRODUCTS',
  AddRoles = 'ADD_ROLES',
  AddSponsors = 'ADD_SPONSORS',
  AddUsers = 'ADD_USERS',
  DeleteBoxes = 'DELETE_BOXES',
  EditActivityAreas = 'EDIT_ACTIVITY_AREAS',
  EditBoxes = 'EDIT_BOXES',
  EditBoxesInOrganisation = 'EDIT_BOXES_IN_ORGANISATION',
  EditCompartments = 'EDIT_COMPARTMENTS',
  EditCompartmentsInOrganisation = 'EDIT_COMPARTMENTS_IN_ORGANISATION',
  EditFeedback = 'EDIT_FEEDBACK',
  EditOrganisations = 'EDIT_ORGANISATIONS',
  EditPendingUsers = 'EDIT_PENDING_USERS',
  EditPermissions = 'EDIT_PERMISSIONS',
  EditPriceWeights = 'EDIT_PRICE_WEIGHTS',
  EditProducts = 'EDIT_PRODUCTS',
  EditRentals = 'EDIT_RENTALS',
  EditRoles = 'EDIT_ROLES',
  EditSponsors = 'EDIT_SPONSORS',
  EditTranslations = 'EDIT_TRANSLATIONS',
  EditUsers = 'EDIT_USERS',
  EditUsersInOrganisation = 'EDIT_USERS_IN_ORGANISATION',
  FreeRent = 'FREE_RENT',
  FreeRentInOrganisation = 'FREE_RENT_IN_ORGANISATION',
  MakeRentals = 'MAKE_RENTALS',
  MakeRentalsPrivateCompartment = 'MAKE_RENTALS_PRIVATE_COMPARTMENT',
  MakeRentalsPrivateCompartmentInOrganisation = 'MAKE_RENTALS_PRIVATE_COMPARTMENT_IN_ORGANISATION',
  OpenCompartments = 'OPEN_COMPARTMENTS',
  OpenCompartmentsInOrganisation = 'OPEN_COMPARTMENTS_IN_ORGANISATION',
  StartChargeOnRental = 'START_CHARGE_ON_RENTAL',
  StopRentals = 'STOP_RENTALS',
  ViewActivityAreas = 'VIEW_ACTIVITY_AREAS',
  ViewBatteryReadings = 'VIEW_BATTERY_READINGS',
  ViewBoxes = 'VIEW_BOXES',
  ViewFeedback = 'VIEW_FEEDBACK',
  ViewInactiveActivityAreas = 'VIEW_INACTIVE_ACTIVITY_AREAS',
  ViewInactiveBoxes = 'VIEW_INACTIVE_BOXES',
  ViewInactiveBoxesInOrganisation = 'VIEW_INACTIVE_BOXES_IN_ORGANISATION',
  ViewOrganisations = 'VIEW_ORGANISATIONS',
  ViewPendingUsers = 'VIEW_PENDING_USERS',
  ViewPermissions = 'VIEW_PERMISSIONS',
  ViewPriceWeights = 'VIEW_PRICE_WEIGHTS',
  ViewProducts = 'VIEW_PRODUCTS',
  ViewRentals = 'VIEW_RENTALS',
  ViewReviews = 'VIEW_REVIEWS',
  ViewRoles = 'VIEW_ROLES',
  ViewSponsors = 'VIEW_SPONSORS',
  ViewUsers = 'VIEW_USERS',
}

export type GQL_Price = {
  __typename?: 'Price';
  amount: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
};

export type GQL_PriceInfoTranslations = {
  __typename?: 'PriceInfoTranslations';
  detailedInfo: Scalars['String']['output'];
  shortInfo: Scalars['String']['output'];
};

export type GQL_PriceStructure = {
  __typename?: 'PriceStructure';
  /** @deprecated No longer supported */
  after_sixth_hour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  basePrice: Scalars['Int']['output'];
  fifthHour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  fifth_hour: Scalars['Int']['output'];
  firstHour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  first_hour: Scalars['Int']['output'];
  fourthHour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  fourth_hour: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  penalty: Scalars['Int']['output'];
  secondHour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  second_hour: Scalars['Int']['output'];
  sixthHour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  sixth_hour: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  thirdHour: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  third_hour: Scalars['Int']['output'];
};

export type GQL_PriceWeight = {
  __typename?: 'PriceWeight';
  fifthHour: Scalars['Int']['output'];
  firstHour: Scalars['Int']['output'];
  fourthHour: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  penalty: Scalars['Int']['output'];
  secondHour: Scalars['Int']['output'];
  sixthHour: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
  thirdHour: Scalars['Int']['output'];
};

export type GQL_Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated No longer supported */
  priceWeight: Scalars['Int']['output'];
  priceWeights: GQL_PriceWeight;
  rentalType: GQL_ProductRentalType;
  translationKey?: Maybe<Scalars['String']['output']>;
};

export enum GQL_ProductRentalType {
  Buy = 'Buy',
  Rent = 'Rent',
}

export type GQL_ProductSearchRoot = {
  __typename?: 'ProductSearchRoot';
  allProducts: Array<GQL_Product>;
  productById?: Maybe<GQL_Product>;
};

export type GQL_ProductSearchRootProductByIdArgs = {
  id: Scalars['ID']['input'];
};

export enum GQL_ProductStatus {
  Broken = 'BROKEN',
  Dirty = 'DIRTY',
  Lost = 'LOST',
  MissingParts = 'MISSING_PARTS',
  Ok = 'OK',
  Other = 'OTHER',
}

export type GQL_Query = {
  __typename?: 'Query';
  activityArea?: Maybe<GQL_ActivityAreaSearchRoot>;
  battery?: Maybe<GQL_BatteryReadingRoot>;
  box?: Maybe<GQL_BoxSearchRoot>;
  compartment?: Maybe<GQL_CompartmentSearchRoot>;
  feedback?: Maybe<GQL_FeedbackSearchRoot>;
  organisation?: Maybe<GQL_OrganisationSearchRoot>;
  payment?: Maybe<GQL_PaymentSearchRoot>;
  pendingUser?: Maybe<GQL_PendingUserSearchRoot>;
  permission?: Maybe<GQL_PermissionSearchRoot>;
  product?: Maybe<GQL_ProductSearchRoot>;
  rental?: Maybe<GQL_RentalSearchRoot>;
  review?: Maybe<GQL_ReviewSearchRoot>;
  role?: Maybe<GQL_RoleSearchRoot>;
  sponsor?: Maybe<GQL_SponsorSearchRoot>;
  user?: Maybe<GQL_UserSearchRoot>;
  viewer?: Maybe<GQL_ViewerSearchRoot>;
};

export type GQL_Question = {
  __typename?: 'Question';
  eng: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  swe?: Maybe<Scalars['String']['output']>;
};

export type GQL_RatingQuestionAnswer = {
  __typename?: 'RatingQuestionAnswer';
  id: Scalars['ID']['output'];
  question: GQL_Question;
  rating?: Maybe<Scalars['Int']['output']>;
};

export type GQL_Receipt = {
  __typename?: 'Receipt';
  amount: Scalars['Int']['output'];
  boxLat: Scalars['Float']['output'];
  boxLocationName: Scalars['String']['output'];
  boxLong: Scalars['Float']['output'];
  boxName: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  maxRentTimeInMinutes: Scalars['Int']['output'];
  paymentCard: Scalars['String']['output'];
  priceHourFive: Scalars['Int']['output'];
  priceHourFour: Scalars['Int']['output'];
  priceHourOne: Scalars['Int']['output'];
  priceHourSix: Scalars['Int']['output'];
  priceHourThree: Scalars['Int']['output'];
  priceHourTwo: Scalars['Int']['output'];
  pricePenaltyFee: Scalars['Int']['output'];
  priceStartingFee: Scalars['Int']['output'];
  /** @deprecated No longer supported */
  priceWeight: Scalars['Int']['output'];
  productName: Scalars['String']['output'];
  rentEnd: Scalars['String']['output'];
  rentStart: Scalars['String']['output'];
  rentalType: GQL_ProductRentalType;
  reviewRating?: Maybe<Scalars['Int']['output']>;
};

export type GQL_RemoveCreditCardResponse = {
  __typename?: 'RemoveCreditCardResponse';
  type: GQL_RemoveCreditCardResponseType;
};

export enum GQL_RemoveCreditCardResponseType {
  CouldNotRemoveCreditCard = 'CouldNotRemoveCreditCard',
  NoPermissionToRemove = 'NoPermissionToRemove',
  Success = 'Success',
}

export type GQL_RemovePendingUserResponse = {
  __typename?: 'RemovePendingUserResponse';
  pendingUser?: Maybe<GQL_PendingUser>;
  status: GQL_RemovePendingUserResponseType;
};

export enum GQL_RemovePendingUserResponseType {
  Generic = 'Generic',
  NoPermissionToRemovePendingUser = 'NoPermissionToRemovePendingUser',
  Success = 'Success',
}

export type GQL_Rental = {
  __typename?: 'Rental';
  aborted?: Maybe<Scalars['Boolean']['output']>;
  box: GQL_RentalBox;
  compartment: GQL_RentalCompartment;
  id: Scalars['ID']['output'];
  maxRentTimeInMinutes: Scalars['Int']['output'];
  numberOfFailedOpenings: Scalars['Int']['output'];
  numberOfSuccessfulOpenings: Scalars['Int']['output'];
  paymentChargeInformation?: Maybe<GQL_PaymentChargeInformation>;
  price?: Maybe<GQL_Price>;
  priceStructure: GQL_PriceStructure;
  product: GQL_Product;
  productStatus?: Maybe<GQL_ProductStatus>;
  productStatusText?: Maybe<Scalars['String']['output']>;
  receipt?: Maybe<GQL_Receipt>;
  rentBy: GQL_User;
  rentEnd?: Maybe<Scalars['String']['output']>;
  rentEndBy?: Maybe<GQL_User>;
  rentStart: Scalars['String']['output'];
  rentStartBy: GQL_User;
  review?: Maybe<GQL_Review>;
};

export type GQL_RentalBox = {
  __typename?: 'RentalBox';
  id: Scalars['ID']['output'];
  location: GQL_Location;
  name: Scalars['String']['output'];
};

export type GQL_RentalCompartment = {
  __typename?: 'RentalCompartment';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GQL_RentalSearchRoot = {
  __typename?: 'RentalSearchRoot';
  allOngoingRentals: Array<GQL_Rental>;
  allRentals: GQL_AllRentalsResponse;
  rentalById?: Maybe<GQL_Rental>;
};

export type GQL_RentalSearchRootAllRentalsArgs = {
  filter?: InputMaybe<GQL_RentalsFilterInput>;
  pagination?: InputMaybe<GQL_Pagination>;
  search?: InputMaybe<GQL_RentalsSearchInput>;
};

export type GQL_RentalSearchRootRentalByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_RentalsFilterInput = {
  boxIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  compartmentIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  endDate?: InputMaybe<GQL_DateFilterInput>;
  genders?: InputMaybe<Array<GQL_Gender>>;
  hasReview?: InputMaybe<Scalars['Boolean']['input']>;
  hasTextFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  mayNeedAction?: InputMaybe<Scalars['Boolean']['input']>;
  paymentChargeInformationTypes?: InputMaybe<
    Array<GQL_PaymentChargeInformationType>
  >;
  productIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  productStatuses?: InputMaybe<Array<GQL_ProductStatus>>;
  rentByUserIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<GQL_DateFilterInput>;
};

export type GQL_RentalsSearchInput = {
  box?: InputMaybe<GQL_StringSearchInput>;
  product?: InputMaybe<GQL_StringSearchInput>;
  rentByUser?: InputMaybe<GQL_StringSearchInput>;
};

export type GQL_Review = {
  __typename?: 'Review';
  freeText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rating?: Maybe<Scalars['Int']['output']>;
  ratingQuestionAnswers: Array<GQL_RatingQuestionAnswer>;
};

export type GQL_ReviewSearchRoot = {
  __typename?: 'ReviewSearchRoot';
  allReviews: Array<GQL_Review>;
  reviewById?: Maybe<GQL_Review>;
};

export type GQL_ReviewSearchRootReviewByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_Role = {
  __typename?: 'Role';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions: Array<GQL_Permission>;
};

export type GQL_RoleSearchRoot = {
  __typename?: 'RoleSearchRoot';
  allRoles: Array<GQL_Role>;
  roleById?: Maybe<GQL_Role>;
};

export type GQL_RoleSearchRootRoleByIdArgs = {
  roleId: Scalars['ID']['input'];
};

export enum GQL_SearchType {
  Contains = 'Contains',
  EndsWith = 'EndsWith',
  Equals = 'Equals',
  StartsWith = 'StartsWith',
}

export type GQL_Sponsor = {
  __typename?: 'Sponsor';
  id: Scalars['ID']['output'];
  imageName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type GQL_SponsorSearchRoot = {
  __typename?: 'SponsorSearchRoot';
  allSponsors: Array<GQL_Sponsor>;
  sponsorById?: Maybe<GQL_Sponsor>;
};

export type GQL_SponsorSearchRootSponsorByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_StartRentalInput = {
  compartmentId: Scalars['ID']['input'];
};

export type GQL_StartRentalResponse = {
  __typename?: 'StartRentalResponse';
  compartment?: Maybe<GQL_Compartment>;
  paymentChargeInformation?: Maybe<GQL_PaymentChargeInformation>;
  rental?: Maybe<GQL_Rental>;
  type?: Maybe<GQL_StartRentalResponseType>;
};

export enum GQL_StartRentalResponseType {
  CompartmentAlreadyRented = 'CompartmentAlreadyRented',
  CompartmentNotOpen = 'CompartmentNotOpen',
  NoPaymentMethodProvided = 'NoPaymentMethodProvided',
  NoPermissionToStartRental = 'NoPermissionToStartRental',
  NoProductInCompartment = 'NoProductInCompartment',
  PaymentFailed = 'PaymentFailed',
  Success = 'Success',
  UserHasUnpaidRentals = 'UserHasUnpaidRentals',
}

export type GQL_StopRentalInput = {
  chargeAmount?: InputMaybe<GQL_InputPrice>;
  productStatus: GQL_ProductStatus;
  productStatusText?: InputMaybe<Scalars['String']['input']>;
  rentalId: Scalars['ID']['input'];
};

export type GQL_StopRentalResponse = {
  __typename?: 'StopRentalResponse';
  compartment?: Maybe<GQL_Compartment>;
  paymentChargeInformation?: Maybe<GQL_PaymentChargeInformation>;
  rental?: Maybe<GQL_Rental>;
  type?: Maybe<GQL_StopRentalResponseType>;
};

export enum GQL_StopRentalResponseType {
  CompartmentIsNotRented = 'CompartmentIsNotRented',
  NoPermissionToStopRental = 'NoPermissionToStopRental',
  RentalAlreadyStopped = 'RentalAlreadyStopped',
  Success = 'Success',
}

export type GQL_StringSearchInput = {
  query: Scalars['String']['input'];
  type: GQL_SearchType;
};

export enum GQL_User_Platform {
  App = 'APP',
  WebAdmin = 'WEB_ADMIN',
}

export enum GQL_User_Provider {
  Apple = 'APPLE',
  Auth0 = 'AUTH0',
  Firebase = 'FIREBASE',
  GoogleOauth2 = 'GOOGLE_OAUTH2',
}

export enum GQL_User_Type {
  GuestUser = 'GUEST_USER',
  PowerUser = 'POWER_USER',
  PublicUser = 'PUBLIC_USER',
}

export type GQL_UnlockCompartmentResponse = {
  __typename?: 'UnlockCompartmentResponse';
  compartment?: Maybe<GQL_Compartment>;
};

export type GQL_UpsertRentalReviewInput = {
  freeText?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  rentalId: Scalars['ID']['input'];
};

export type GQL_UpsertRentalReviewResponse = {
  __typename?: 'UpsertRentalReviewResponse';
  review?: Maybe<GQL_Review>;
  type?: Maybe<GQL_UpsertRentalReviewResponseType>;
};

export enum GQL_UpsertRentalReviewResponseType {
  Generic = 'Generic',
  NoPermissionAddReviewToRental = 'NoPermissionAddReviewToRental',
  RentalDoesNotExist = 'RentalDoesNotExist',
  Success = 'Success',
}

export type GQL_User = {
  __typename?: 'User';
  accountCreated?: Maybe<Scalars['String']['output']>;
  accountUpdated?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  birthYear?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GQL_Gender>;
  id: Scalars['ID']['output'];
  lastLogin?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  numberOfLogins?: Maybe<Scalars['Int']['output']>;
  ongoingRental?: Maybe<GQL_Rental>;
  paymentInformation?: Maybe<GQL_PaymentInformation>;
  permissions: Array<GQL_Permission>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  picturePath?: Maybe<Scalars['String']['output']>;
  /** @deprecated Replaced by permissions */
  platform?: Maybe<GQL_User_Platform>;
  /** @deprecated No longer supported */
  priceWeight: Scalars['Int']['output'];
  priceWeights: GQL_PriceWeight;
  privateEmail?: Maybe<Scalars['Boolean']['output']>;
  provider?: Maybe<GQL_User_Provider>;
  rentals: Array<GQL_Rental>;
  roles: Array<GQL_Role>;
  /** @deprecated Replaced by permissions */
  type: GQL_User_Type;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type GQL_UserProfile = {
  __typename?: 'UserProfile';
  appPermissions: Array<GQL_AppPermission>;
  birthYear?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<GQL_Gender>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  picturePath?: Maybe<Scalars['String']['output']>;
  priceWeight: Scalars['Int']['output'];
  priceWeights: GQL_PriceWeight;
  privateEmail?: Maybe<Scalars['Boolean']['output']>;
  provider?: Maybe<GQL_User_Provider>;
  /** @deprecated Replaced by permissions */
  type: GQL_User_Type;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type GQL_UserSearchInput = {
  email?: InputMaybe<GQL_StringSearchInput>;
};

export type GQL_UserSearchRoot = {
  __typename?: 'UserSearchRoot';
  allUsers: GQL_AllUsersResponse;
  userById?: Maybe<GQL_User>;
};

export type GQL_UserSearchRootAllUsersArgs = {
  filter?: InputMaybe<GQL_UsersFilterInput>;
  pagination?: InputMaybe<GQL_Pagination>;
  search?: InputMaybe<GQL_UserSearchInput>;
};

export type GQL_UserSearchRootUserByIdArgs = {
  id: Scalars['ID']['input'];
};

export type GQL_UsersFilterInput = {
  accountCreated?: InputMaybe<GQL_DateFilterInput>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type GQL_ViewerSearchRoot = {
  __typename?: 'ViewerSearchRoot';
  adminBoxes: Array<GQL_Box>;
  ongoingRental?: Maybe<GQL_Rental>;
  paymentInformation?: Maybe<GQL_PaymentInformation>;
  rentals: Array<GQL_Rental>;
  userProfile?: Maybe<GQL_UserProfile>;
};

export type GQL_ActivityAreaFragment = {
  __typename?: 'ActivityArea';
  active: boolean;
  activities: Array<string>;
  id: string;
  type: string;
  verified: boolean;
  areaInfos: Array<{
    __typename?: 'AreaInfo';
    count: number;
    id: string;
    type: string;
  }>;
  location: {
    __typename?: 'Location';
    id: string;
    description?: string | null;
    name?: string | null;
    coordinates?: {
      __typename?: 'Coordinates';
      lat: number;
      long: number;
    } | null;
  };
};

export type GQL_AllActivityAreasQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllActivityAreasQuery = {
  __typename?: 'Query';
  activityArea?: {
    __typename?: 'ActivityAreaSearchRoot';
    allActivityAreas: Array<{
      __typename?: 'ActivityArea';
      active: boolean;
      activities: Array<string>;
      id: string;
      type: string;
      verified: boolean;
      areaInfos: Array<{
        __typename?: 'AreaInfo';
        count: number;
        id: string;
        type: string;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    }>;
  } | null;
};

export type GQL_ActivityAreaByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_ActivityAreaByIdQuery = {
  __typename?: 'Query';
  activityArea?: {
    __typename?: 'ActivityAreaSearchRoot';
    activityAreaById?: {
      __typename?: 'ActivityArea';
      active: boolean;
      activities: Array<string>;
      id: string;
      type: string;
      verified: boolean;
      areaInfos: Array<{
        __typename?: 'AreaInfo';
        count: number;
        id: string;
        type: string;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    } | null;
  } | null;
};

export type GQL_AllActivitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllActivitiesQuery = {
  __typename?: 'Query';
  activityArea?: {
    __typename?: 'ActivityAreaSearchRoot';
    allActivities: Array<{ __typename?: 'Activity'; code: string }>;
  } | null;
};

export type GQL_AddActivityAreaMutationVariables = Exact<{
  activityAreaInput: GQL_AddActivityAreaInput;
}>;

export type GQL_AddActivityAreaMutation = {
  __typename?: 'Mutation';
  addActivityArea: {
    __typename?: 'AddActivityAreaResponse';
    type?: GQL_AddActivityAreaResponseType | null;
    activityArea?: {
      __typename?: 'ActivityArea';
      active: boolean;
      activities: Array<string>;
      id: string;
      type: string;
      verified: boolean;
      areaInfos: Array<{
        __typename?: 'AreaInfo';
        count: number;
        id: string;
        type: string;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    } | null;
  };
};

export type GQL_EditActivityAreaMutationVariables = Exact<{
  activityAreaId: Scalars['ID']['input'];
  activityAreaInput: GQL_EditActivityAreaInput;
}>;

export type GQL_EditActivityAreaMutation = {
  __typename?: 'Mutation';
  editActivityArea: {
    __typename?: 'EditActivityAreaResponse';
    type?: GQL_EditActivityAreaResponseType | null;
    activityArea?: {
      __typename?: 'ActivityArea';
      active: boolean;
      activities: Array<string>;
      id: string;
      type: string;
      verified: boolean;
      areaInfos: Array<{
        __typename?: 'AreaInfo';
        count: number;
        id: string;
        type: string;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    } | null;
  };
};

export type GQL_BatteryReadingBoxFragment = {
  __typename?: 'Box';
  id: string;
  name: string;
  location: {
    __typename?: 'Location';
    id: string;
    description?: string | null;
    name?: string | null;
    coordinates?: {
      __typename?: 'Coordinates';
      lat: number;
      long: number;
    } | null;
  };
};

export type GQL_BatteryReadingFragment = {
  __typename?: 'BatteryReading';
  id: string;
  userId: string;
  voltage: number;
  createdAt: string;
  box: {
    __typename?: 'Box';
    id: string;
    name: string;
    location: {
      __typename?: 'Location';
      id: string;
      description?: string | null;
      name?: string | null;
      coordinates?: {
        __typename?: 'Coordinates';
        lat: number;
        long: number;
      } | null;
    };
  };
};

export type GQL_AllBatteryReadingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllBatteryReadingsQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryReadingRoot';
    allBatteryReadings: Array<{
      __typename?: 'BatteryReading';
      id: string;
      userId: string;
      voltage: number;
      createdAt: string;
      box: {
        __typename?: 'Box';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
    }>;
  } | null;
};

export type GQL_BatteryReadingsByBoxIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_BatteryReadingsByBoxIdQuery = {
  __typename?: 'Query';
  battery?: {
    __typename?: 'BatteryReadingRoot';
    batteryReadingsByBoxId: Array<{
      __typename?: 'BatteryReading';
      id: string;
      userId: string;
      voltage: number;
      createdAt: string;
      box: {
        __typename?: 'Box';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
    }>;
  } | null;
};

export type GQL_LocationFragment = {
  __typename?: 'Location';
  id: string;
  description?: string | null;
  name?: string | null;
  coordinates?: {
    __typename?: 'Coordinates';
    lat: number;
    long: number;
  } | null;
};

export type GQL_BoxFragment = {
  __typename?: 'Box';
  id: string;
  active: boolean;
  circuitBoardId: string;
  color: GQL_BoxColor;
  deprecated: boolean;
  name: string;
  initialActivationTime?: string | null;
  openStartTime: string;
  openEndTime: string;
  compartments: Array<{
    __typename?: 'Compartment';
    id: string;
    locked?: boolean | null;
    circuitBoardPort: number;
    name: string;
    private?: boolean | null;
    rentals: Array<{
      __typename?: 'Rental';
      id: string;
      maxRentTimeInMinutes: number;
      numberOfFailedOpenings: number;
      numberOfSuccessfulOpenings: number;
      productStatus?: GQL_ProductStatus | null;
      productStatusText?: string | null;
      aborted?: boolean | null;
      rentEnd?: string | null;
      rentStart: string;
      box: {
        __typename?: 'RentalBox';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
      compartment: {
        __typename?: 'RentalCompartment';
        id: string;
        name: string;
      };
      paymentChargeInformation?:
        | {
            __typename?: 'PaymentChargeInformationFail';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            fail_status: GQL_PaymentChargeFailStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationFreeRent';
            id: string;
            amount: number;
            currency: string;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationSuccess';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            success_status: GQL_PaymentChargeSuccessStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
      priceStructure: {
        __typename?: 'PriceStructure';
        id: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentBy: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        gender?: GQL_Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        birthYear?: string | null;
      };
      rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
      rentEndBy?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
      } | null;
      review?: {
        __typename?: 'Review';
        id: string;
        freeText?: string | null;
        rating?: number | null;
        ratingQuestionAnswers: Array<{
          __typename?: 'RatingQuestionAnswer';
          id: string;
          rating?: number | null;
          question: {
            __typename?: 'Question';
            id: string;
            eng: string;
            swe?: string | null;
          };
        }>;
      } | null;
    }>;
    product?: {
      __typename?: 'CompartmentProduct';
      productStatus?: GQL_ProductStatus | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
    } | null;
  }>;
  location: {
    __typename?: 'Location';
    id: string;
    description?: string | null;
    name?: string | null;
    coordinates?: {
      __typename?: 'Coordinates';
      lat: number;
      long: number;
    } | null;
  };
  priceWeights: {
    __typename?: 'PriceWeight';
    id: string;
    name: string;
    start: number;
    firstHour: number;
    secondHour: number;
    thirdHour: number;
    fourthHour: number;
    fifthHour: number;
    sixthHour: number;
    penalty: number;
  };
  sponsor?: {
    __typename?: 'Sponsor';
    id: string;
    imageName: string;
    name: string;
    url?: string | null;
  } | null;
};

export type GQL_BoxLiteFragment = {
  __typename?: 'Box';
  id: string;
  active: boolean;
  circuitBoardId: string;
  color: GQL_BoxColor;
  deprecated: boolean;
  name: string;
  initialActivationTime?: string | null;
  openStartTime: string;
  openEndTime: string;
  compartments: Array<{
    __typename?: 'Compartment';
    id: string;
    locked?: boolean | null;
    circuitBoardPort: number;
    name: string;
    private?: boolean | null;
    product?: {
      __typename?: 'CompartmentProduct';
      productStatus?: GQL_ProductStatus | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
    } | null;
    rentals: Array<{
      __typename?: 'Rental';
      id: string;
      rentEnd?: string | null;
    }>;
  }>;
  location: {
    __typename?: 'Location';
    id: string;
    description?: string | null;
    name?: string | null;
    coordinates?: {
      __typename?: 'Coordinates';
      lat: number;
      long: number;
    } | null;
  };
  priceWeights: {
    __typename?: 'PriceWeight';
    id: string;
    name: string;
    start: number;
    firstHour: number;
    secondHour: number;
    thirdHour: number;
    fourthHour: number;
    fifthHour: number;
    sixthHour: number;
    penalty: number;
  };
  sponsor?: {
    __typename?: 'Sponsor';
    id: string;
    imageName: string;
    name: string;
    url?: string | null;
  } | null;
};

export type GQL_BoxWithoutRentalsFragment = {
  __typename?: 'Box';
  id: string;
  active: boolean;
  circuitBoardId: string;
  color: GQL_BoxColor;
  deprecated: boolean;
  name: string;
  initialActivationTime?: string | null;
  openStartTime: string;
  openEndTime: string;
  compartments: Array<{
    __typename?: 'Compartment';
    id: string;
    locked?: boolean | null;
    circuitBoardPort: number;
    name: string;
    private?: boolean | null;
    product?: {
      __typename?: 'CompartmentProduct';
      productStatus?: GQL_ProductStatus | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
    } | null;
  }>;
  location: {
    __typename?: 'Location';
    id: string;
    description?: string | null;
    name?: string | null;
    coordinates?: {
      __typename?: 'Coordinates';
      lat: number;
      long: number;
    } | null;
  };
  priceWeights: {
    __typename?: 'PriceWeight';
    id: string;
    name: string;
    start: number;
    firstHour: number;
    secondHour: number;
    thirdHour: number;
    fourthHour: number;
    fifthHour: number;
    sixthHour: number;
    penalty: number;
  };
  sponsor?: {
    __typename?: 'Sponsor';
    id: string;
    imageName: string;
    name: string;
    url?: string | null;
  } | null;
};

export type GQL_AllBoxesQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllBoxesQuery = {
  __typename?: 'Query';
  box?: {
    __typename?: 'BoxSearchRoot';
    allBoxes: Array<{
      __typename?: 'Box';
      id: string;
      active: boolean;
      circuitBoardId: string;
      color: GQL_BoxColor;
      deprecated: boolean;
      name: string;
      initialActivationTime?: string | null;
      openStartTime: string;
      openEndTime: string;
      compartments: Array<{
        __typename?: 'Compartment';
        id: string;
        locked?: boolean | null;
        circuitBoardPort: number;
        name: string;
        private?: boolean | null;
        rentals: Array<{
          __typename?: 'Rental';
          id: string;
          maxRentTimeInMinutes: number;
          numberOfFailedOpenings: number;
          numberOfSuccessfulOpenings: number;
          productStatus?: GQL_ProductStatus | null;
          productStatusText?: string | null;
          aborted?: boolean | null;
          rentEnd?: string | null;
          rentStart: string;
          box: {
            __typename?: 'RentalBox';
            id: string;
            name: string;
            location: {
              __typename?: 'Location';
              id: string;
              description?: string | null;
              name?: string | null;
              coordinates?: {
                __typename?: 'Coordinates';
                lat: number;
                long: number;
              } | null;
            };
          };
          compartment: {
            __typename?: 'RentalCompartment';
            id: string;
            name: string;
          };
          paymentChargeInformation?:
            | {
                __typename?: 'PaymentChargeInformationFail';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                fail_status: GQL_PaymentChargeFailStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationFreeRent';
                id: string;
                amount: number;
                currency: string;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationSuccess';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                success_status: GQL_PaymentChargeSuccessStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
          priceStructure: {
            __typename?: 'PriceStructure';
            id: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
          rentBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
            gender?: GQL_Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber?: string | null;
            birthYear?: string | null;
          };
          rentStartBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          };
          rentEndBy?: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          } | null;
          review?: {
            __typename?: 'Review';
            id: string;
            freeText?: string | null;
            rating?: number | null;
            ratingQuestionAnswers: Array<{
              __typename?: 'RatingQuestionAnswer';
              id: string;
              rating?: number | null;
              question: {
                __typename?: 'Question';
                id: string;
                eng: string;
                swe?: string | null;
              };
            }>;
          } | null;
        }>;
        product?: {
          __typename?: 'CompartmentProduct';
          productStatus?: GQL_ProductStatus | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
        } | null;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      sponsor?: {
        __typename?: 'Sponsor';
        id: string;
        imageName: string;
        name: string;
        url?: string | null;
      } | null;
    }>;
  } | null;
};

export type GQL_AllBoxesLiteQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllBoxesLiteQuery = {
  __typename?: 'Query';
  box?: {
    __typename?: 'BoxSearchRoot';
    allBoxes: Array<{
      __typename?: 'Box';
      id: string;
      active: boolean;
      circuitBoardId: string;
      color: GQL_BoxColor;
      deprecated: boolean;
      name: string;
      initialActivationTime?: string | null;
      openStartTime: string;
      openEndTime: string;
      compartments: Array<{
        __typename?: 'Compartment';
        id: string;
        locked?: boolean | null;
        circuitBoardPort: number;
        name: string;
        private?: boolean | null;
        product?: {
          __typename?: 'CompartmentProduct';
          productStatus?: GQL_ProductStatus | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
        } | null;
        rentals: Array<{
          __typename?: 'Rental';
          id: string;
          rentEnd?: string | null;
        }>;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      sponsor?: {
        __typename?: 'Sponsor';
        id: string;
        imageName: string;
        name: string;
        url?: string | null;
      } | null;
    }>;
  } | null;
};

export type GQL_AllBoxesWithoutRentalsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllBoxesWithoutRentalsQuery = {
  __typename?: 'Query';
  box?: {
    __typename?: 'BoxSearchRoot';
    allBoxes: Array<{
      __typename?: 'Box';
      id: string;
      active: boolean;
      circuitBoardId: string;
      color: GQL_BoxColor;
      deprecated: boolean;
      name: string;
      initialActivationTime?: string | null;
      openStartTime: string;
      openEndTime: string;
      compartments: Array<{
        __typename?: 'Compartment';
        id: string;
        locked?: boolean | null;
        circuitBoardPort: number;
        name: string;
        private?: boolean | null;
        product?: {
          __typename?: 'CompartmentProduct';
          productStatus?: GQL_ProductStatus | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
        } | null;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      sponsor?: {
        __typename?: 'Sponsor';
        id: string;
        imageName: string;
        name: string;
        url?: string | null;
      } | null;
    }>;
  } | null;
};

export type GQL_AllBoxesForSelectQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllBoxesForSelectQuery = {
  __typename?: 'Query';
  box?: {
    __typename?: 'BoxSearchRoot';
    allBoxes: Array<{ __typename?: 'Box'; id: string; name: string }>;
  } | null;
};

export type GQL_BoxByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_BoxByIdQuery = {
  __typename?: 'Query';
  box?: {
    __typename?: 'BoxSearchRoot';
    boxById?: {
      __typename?: 'Box';
      id: string;
      active: boolean;
      circuitBoardId: string;
      color: GQL_BoxColor;
      deprecated: boolean;
      name: string;
      initialActivationTime?: string | null;
      openStartTime: string;
      openEndTime: string;
      compartments: Array<{
        __typename?: 'Compartment';
        id: string;
        locked?: boolean | null;
        circuitBoardPort: number;
        name: string;
        private?: boolean | null;
        rentals: Array<{
          __typename?: 'Rental';
          id: string;
          maxRentTimeInMinutes: number;
          numberOfFailedOpenings: number;
          numberOfSuccessfulOpenings: number;
          productStatus?: GQL_ProductStatus | null;
          productStatusText?: string | null;
          aborted?: boolean | null;
          rentEnd?: string | null;
          rentStart: string;
          box: {
            __typename?: 'RentalBox';
            id: string;
            name: string;
            location: {
              __typename?: 'Location';
              id: string;
              description?: string | null;
              name?: string | null;
              coordinates?: {
                __typename?: 'Coordinates';
                lat: number;
                long: number;
              } | null;
            };
          };
          compartment: {
            __typename?: 'RentalCompartment';
            id: string;
            name: string;
          };
          paymentChargeInformation?:
            | {
                __typename?: 'PaymentChargeInformationFail';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                fail_status: GQL_PaymentChargeFailStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationFreeRent';
                id: string;
                amount: number;
                currency: string;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationSuccess';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                success_status: GQL_PaymentChargeSuccessStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
          priceStructure: {
            __typename?: 'PriceStructure';
            id: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
          rentBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
            gender?: GQL_Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber?: string | null;
            birthYear?: string | null;
          };
          rentStartBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          };
          rentEndBy?: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          } | null;
          review?: {
            __typename?: 'Review';
            id: string;
            freeText?: string | null;
            rating?: number | null;
            ratingQuestionAnswers: Array<{
              __typename?: 'RatingQuestionAnswer';
              id: string;
              rating?: number | null;
              question: {
                __typename?: 'Question';
                id: string;
                eng: string;
                swe?: string | null;
              };
            }>;
          } | null;
        }>;
        product?: {
          __typename?: 'CompartmentProduct';
          productStatus?: GQL_ProductStatus | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
        } | null;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      sponsor?: {
        __typename?: 'Sponsor';
        id: string;
        imageName: string;
        name: string;
        url?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GQL_AddBoxMutationVariables = Exact<{
  boxInput: GQL_AddBoxInput;
}>;

export type GQL_AddBoxMutation = {
  __typename?: 'Mutation';
  addBox: {
    __typename?: 'AddBoxResponse';
    type?: GQL_AddBoxResponseType | null;
    box?: {
      __typename?: 'Box';
      id: string;
      active: boolean;
      circuitBoardId: string;
      color: GQL_BoxColor;
      deprecated: boolean;
      name: string;
      initialActivationTime?: string | null;
      openStartTime: string;
      openEndTime: string;
      compartments: Array<{
        __typename?: 'Compartment';
        id: string;
        locked?: boolean | null;
        circuitBoardPort: number;
        name: string;
        private?: boolean | null;
        rentals: Array<{
          __typename?: 'Rental';
          id: string;
          maxRentTimeInMinutes: number;
          numberOfFailedOpenings: number;
          numberOfSuccessfulOpenings: number;
          productStatus?: GQL_ProductStatus | null;
          productStatusText?: string | null;
          aborted?: boolean | null;
          rentEnd?: string | null;
          rentStart: string;
          box: {
            __typename?: 'RentalBox';
            id: string;
            name: string;
            location: {
              __typename?: 'Location';
              id: string;
              description?: string | null;
              name?: string | null;
              coordinates?: {
                __typename?: 'Coordinates';
                lat: number;
                long: number;
              } | null;
            };
          };
          compartment: {
            __typename?: 'RentalCompartment';
            id: string;
            name: string;
          };
          paymentChargeInformation?:
            | {
                __typename?: 'PaymentChargeInformationFail';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                fail_status: GQL_PaymentChargeFailStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationFreeRent';
                id: string;
                amount: number;
                currency: string;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationSuccess';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                success_status: GQL_PaymentChargeSuccessStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
          priceStructure: {
            __typename?: 'PriceStructure';
            id: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
          rentBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
            gender?: GQL_Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber?: string | null;
            birthYear?: string | null;
          };
          rentStartBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          };
          rentEndBy?: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          } | null;
          review?: {
            __typename?: 'Review';
            id: string;
            freeText?: string | null;
            rating?: number | null;
            ratingQuestionAnswers: Array<{
              __typename?: 'RatingQuestionAnswer';
              id: string;
              rating?: number | null;
              question: {
                __typename?: 'Question';
                id: string;
                eng: string;
                swe?: string | null;
              };
            }>;
          } | null;
        }>;
        product?: {
          __typename?: 'CompartmentProduct';
          productStatus?: GQL_ProductStatus | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
        } | null;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      sponsor?: {
        __typename?: 'Sponsor';
        id: string;
        imageName: string;
        name: string;
        url?: string | null;
      } | null;
    } | null;
  };
};

export type GQL_EditBoxMutationVariables = Exact<{
  boxId: Scalars['ID']['input'];
  boxInput: GQL_EditBoxInput;
}>;

export type GQL_EditBoxMutation = {
  __typename?: 'Mutation';
  editBox: {
    __typename?: 'EditBoxResponse';
    type?: GQL_EditBoxResponseType | null;
    box?: {
      __typename?: 'Box';
      id: string;
      active: boolean;
      circuitBoardId: string;
      color: GQL_BoxColor;
      deprecated: boolean;
      name: string;
      initialActivationTime?: string | null;
      openStartTime: string;
      openEndTime: string;
      compartments: Array<{
        __typename?: 'Compartment';
        id: string;
        locked?: boolean | null;
        circuitBoardPort: number;
        name: string;
        private?: boolean | null;
        rentals: Array<{
          __typename?: 'Rental';
          id: string;
          maxRentTimeInMinutes: number;
          numberOfFailedOpenings: number;
          numberOfSuccessfulOpenings: number;
          productStatus?: GQL_ProductStatus | null;
          productStatusText?: string | null;
          aborted?: boolean | null;
          rentEnd?: string | null;
          rentStart: string;
          box: {
            __typename?: 'RentalBox';
            id: string;
            name: string;
            location: {
              __typename?: 'Location';
              id: string;
              description?: string | null;
              name?: string | null;
              coordinates?: {
                __typename?: 'Coordinates';
                lat: number;
                long: number;
              } | null;
            };
          };
          compartment: {
            __typename?: 'RentalCompartment';
            id: string;
            name: string;
          };
          paymentChargeInformation?:
            | {
                __typename?: 'PaymentChargeInformationFail';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                fail_status: GQL_PaymentChargeFailStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationFreeRent';
                id: string;
                amount: number;
                currency: string;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationSuccess';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                success_status: GQL_PaymentChargeSuccessStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
          priceStructure: {
            __typename?: 'PriceStructure';
            id: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
          rentBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
            gender?: GQL_Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber?: string | null;
            birthYear?: string | null;
          };
          rentStartBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          };
          rentEndBy?: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          } | null;
          review?: {
            __typename?: 'Review';
            id: string;
            freeText?: string | null;
            rating?: number | null;
            ratingQuestionAnswers: Array<{
              __typename?: 'RatingQuestionAnswer';
              id: string;
              rating?: number | null;
              question: {
                __typename?: 'Question';
                id: string;
                eng: string;
                swe?: string | null;
              };
            }>;
          } | null;
        }>;
        product?: {
          __typename?: 'CompartmentProduct';
          productStatus?: GQL_ProductStatus | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
        } | null;
      }>;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      sponsor?: {
        __typename?: 'Sponsor';
        id: string;
        imageName: string;
        name: string;
        url?: string | null;
      } | null;
    } | null;
  };
};

export type GQL_DeleteBoxMutationVariables = Exact<{
  boxId: Scalars['ID']['input'];
}>;

export type GQL_DeleteBoxMutation = {
  __typename?: 'Mutation';
  deleteBox: {
    __typename?: 'DeleteBoxResponse';
    type: GQL_DeleteBoxResponseType;
  };
};

export type GQL_CompartmentProductFragment = {
  __typename?: 'CompartmentProduct';
  productStatus?: GQL_ProductStatus | null;
  product: {
    __typename?: 'Product';
    id: string;
    description?: string | null;
    imageName?: string | null;
    name: string;
    rentalType: GQL_ProductRentalType;
    translationKey?: string | null;
    priceWeights: {
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
  };
};

export type GQL_CompartmentFragment = {
  __typename?: 'Compartment';
  id: string;
  locked?: boolean | null;
  circuitBoardPort: number;
  name: string;
  private?: boolean | null;
  product?: {
    __typename?: 'CompartmentProduct';
    productStatus?: GQL_ProductStatus | null;
    product: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    };
  } | null;
};

export type GQL_CompartmentWithRentalsFragment = {
  __typename?: 'Compartment';
  id: string;
  locked?: boolean | null;
  circuitBoardPort: number;
  name: string;
  private?: boolean | null;
  rentals: Array<{
    __typename?: 'Rental';
    id: string;
    maxRentTimeInMinutes: number;
    numberOfFailedOpenings: number;
    numberOfSuccessfulOpenings: number;
    productStatus?: GQL_ProductStatus | null;
    productStatusText?: string | null;
    aborted?: boolean | null;
    rentEnd?: string | null;
    rentStart: string;
    box: {
      __typename?: 'RentalBox';
      id: string;
      name: string;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    };
    compartment: { __typename?: 'RentalCompartment'; id: string; name: string };
    paymentChargeInformation?:
      | {
          __typename?: 'PaymentChargeInformationFail';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          fail_status: GQL_PaymentChargeFailStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationFreeRent';
          id: string;
          amount: number;
          currency: string;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationSuccess';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          success_status: GQL_PaymentChargeSuccessStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | null;
    product: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    };
    priceStructure: {
      __typename?: 'PriceStructure';
      id: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
    rentBy: {
      __typename?: 'User';
      id: string;
      email?: string | null;
      gender?: GQL_Gender | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      birthYear?: string | null;
    };
    rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
    rentEndBy?: {
      __typename?: 'User';
      id: string;
      email?: string | null;
    } | null;
    review?: {
      __typename?: 'Review';
      id: string;
      freeText?: string | null;
      rating?: number | null;
      ratingQuestionAnswers: Array<{
        __typename?: 'RatingQuestionAnswer';
        id: string;
        rating?: number | null;
        question: {
          __typename?: 'Question';
          id: string;
          eng: string;
          swe?: string | null;
        };
      }>;
    } | null;
  }>;
  product?: {
    __typename?: 'CompartmentProduct';
    productStatus?: GQL_ProductStatus | null;
    product: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    };
  } | null;
};

export type GQL_CompartmentLiteFragment = {
  __typename?: 'Compartment';
  id: string;
  locked?: boolean | null;
  circuitBoardPort: number;
  name: string;
  private?: boolean | null;
  product?: {
    __typename?: 'CompartmentProduct';
    productStatus?: GQL_ProductStatus | null;
    product: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    };
  } | null;
  rentals: Array<{
    __typename?: 'Rental';
    id: string;
    rentEnd?: string | null;
  }>;
};

export type GQL_CompartmentByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_CompartmentByIdQuery = {
  __typename?: 'Query';
  compartment?: {
    __typename?: 'CompartmentSearchRoot';
    compartmentById?: {
      __typename?: 'Compartment';
      id: string;
      locked?: boolean | null;
      circuitBoardPort: number;
      name: string;
      private?: boolean | null;
      rentals: Array<{
        __typename?: 'Rental';
        id: string;
        maxRentTimeInMinutes: number;
        numberOfFailedOpenings: number;
        numberOfSuccessfulOpenings: number;
        productStatus?: GQL_ProductStatus | null;
        productStatusText?: string | null;
        aborted?: boolean | null;
        rentEnd?: string | null;
        rentStart: string;
        box: {
          __typename?: 'RentalBox';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        };
        compartment: {
          __typename?: 'RentalCompartment';
          id: string;
          name: string;
        };
        paymentChargeInformation?:
          | {
              __typename?: 'PaymentChargeInformationFail';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              fail_status: GQL_PaymentChargeFailStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationFreeRent';
              id: string;
              amount: number;
              currency: string;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationSuccess';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              success_status: GQL_PaymentChargeSuccessStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
        priceStructure: {
          __typename?: 'PriceStructure';
          id: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
        rentBy: {
          __typename?: 'User';
          id: string;
          email?: string | null;
          gender?: GQL_Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber?: string | null;
          birthYear?: string | null;
        };
        rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
        rentEndBy?: {
          __typename?: 'User';
          id: string;
          email?: string | null;
        } | null;
        review?: {
          __typename?: 'Review';
          id: string;
          freeText?: string | null;
          rating?: number | null;
          ratingQuestionAnswers: Array<{
            __typename?: 'RatingQuestionAnswer';
            id: string;
            rating?: number | null;
            question: {
              __typename?: 'Question';
              id: string;
              eng: string;
              swe?: string | null;
            };
          }>;
        } | null;
      }>;
      product?: {
        __typename?: 'CompartmentProduct';
        productStatus?: GQL_ProductStatus | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
      } | null;
    } | null;
  } | null;
};

export type GQL_EditCompartmentMutationVariables = Exact<{
  compartmentId: Scalars['ID']['input'];
  compartment: GQL_EditCompartmentInput;
}>;

export type GQL_EditCompartmentMutation = {
  __typename?: 'Mutation';
  editCompartment: {
    __typename?: 'EditCompartmentResponse';
    compartment?: {
      __typename?: 'Compartment';
      id: string;
      locked?: boolean | null;
      circuitBoardPort: number;
      name: string;
      private?: boolean | null;
      product?: {
        __typename?: 'CompartmentProduct';
        productStatus?: GQL_ProductStatus | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
      } | null;
    } | null;
  };
};

export type GQL_EditCompartmentsMutationVariables = Exact<{
  compartments: Array<GQL_EditCompartmentsInput> | GQL_EditCompartmentsInput;
}>;

export type GQL_EditCompartmentsMutation = {
  __typename?: 'Mutation';
  editCompartments: {
    __typename?: 'EditCompartmentsResponse';
    compartments: Array<{
      __typename?: 'Compartment';
      id: string;
      locked?: boolean | null;
      circuitBoardPort: number;
      name: string;
      private?: boolean | null;
      product?: {
        __typename?: 'CompartmentProduct';
        productStatus?: GQL_ProductStatus | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
      } | null;
    }>;
  };
};

export type GQL_LockCompartmentMutationVariables = Exact<{
  compartmentId: Scalars['ID']['input'];
}>;

export type GQL_LockCompartmentMutation = {
  __typename?: 'Mutation';
  lockCompartment: {
    __typename?: 'LockCompartmentResponse';
    compartment?: {
      __typename?: 'Compartment';
      id: string;
      locked?: boolean | null;
      circuitBoardPort: number;
      name: string;
      private?: boolean | null;
      product?: {
        __typename?: 'CompartmentProduct';
        productStatus?: GQL_ProductStatus | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
      } | null;
    } | null;
  };
};

export type GQL_UnlockCompartmentMutationVariables = Exact<{
  compartmentId: Scalars['ID']['input'];
}>;

export type GQL_UnlockCompartmentMutation = {
  __typename?: 'Mutation';
  unlockCompartment: {
    __typename?: 'UnlockCompartmentResponse';
    compartment?: {
      __typename?: 'Compartment';
      id: string;
      locked?: boolean | null;
      circuitBoardPort: number;
      name: string;
      private?: boolean | null;
      product?: {
        __typename?: 'CompartmentProduct';
        productStatus?: GQL_ProductStatus | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
      } | null;
    } | null;
  };
};

export type GQL_FeedbackFragment = {
  __typename?: 'Feedback';
  id: string;
  freeText: string;
  date: string;
  user?: {
    __typename?: 'User';
    id: string;
    accountCreated?: string | null;
    accountUpdated?: string | null;
    birthDate?: string | null;
    birthYear?: string | null;
    city?: string | null;
    country?: string | null;
    email?: string | null;
    emailVerified?: boolean | null;
    externalId?: string | null;
    firstName?: string | null;
    gender?: GQL_Gender | null;
    lastLogin?: string | null;
    lastName?: string | null;
    numberOfLogins?: number | null;
    phoneNumber?: string | null;
    privateEmail?: boolean | null;
    provider?: GQL_User_Provider | null;
    zipCode?: string | null;
    paymentInformation?: {
      __typename?: 'PaymentInformation';
      customerId: string;
      id: string;
      cards: Array<{
        __typename?: 'PaymentCard';
        brand: string;
        id: string;
        last4: string;
        status?: GQL_PaymentCardStatus | null;
      }>;
    } | null;
    permissions: Array<{
      __typename?: 'Permission';
      id: string;
      description?: string | null;
      type: GQL_PermissionType;
    }>;
    priceWeights: {
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
    rentals: Array<{
      __typename?: 'Rental';
      id: string;
      maxRentTimeInMinutes: number;
      numberOfFailedOpenings: number;
      numberOfSuccessfulOpenings: number;
      productStatus?: GQL_ProductStatus | null;
      productStatusText?: string | null;
      aborted?: boolean | null;
      rentEnd?: string | null;
      rentStart: string;
      box: {
        __typename?: 'RentalBox';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
      compartment: {
        __typename?: 'RentalCompartment';
        id: string;
        name: string;
      };
      paymentChargeInformation?:
        | {
            __typename?: 'PaymentChargeInformationFail';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            fail_status: GQL_PaymentChargeFailStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationFreeRent';
            id: string;
            amount: number;
            currency: string;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationSuccess';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            success_status: GQL_PaymentChargeSuccessStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
      priceStructure: {
        __typename?: 'PriceStructure';
        id: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentBy: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        gender?: GQL_Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        birthYear?: string | null;
      };
      rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
      rentEndBy?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
      } | null;
      review?: {
        __typename?: 'Review';
        id: string;
        freeText?: string | null;
        rating?: number | null;
        ratingQuestionAnswers: Array<{
          __typename?: 'RatingQuestionAnswer';
          id: string;
          rating?: number | null;
          question: {
            __typename?: 'Question';
            id: string;
            eng: string;
            swe?: string | null;
          };
        }>;
      } | null;
    }>;
    roles: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
    }>;
  } | null;
};

export type GQL_AllFeedbacksQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllFeedbacksQuery = {
  __typename?: 'Query';
  feedback?: {
    __typename?: 'FeedbackSearchRoot';
    allFeedbacks: Array<{
      __typename?: 'Feedback';
      id: string;
      freeText: string;
      date: string;
      user?: {
        __typename?: 'User';
        id: string;
        accountCreated?: string | null;
        accountUpdated?: string | null;
        birthDate?: string | null;
        birthYear?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        emailVerified?: boolean | null;
        externalId?: string | null;
        firstName?: string | null;
        gender?: GQL_Gender | null;
        lastLogin?: string | null;
        lastName?: string | null;
        numberOfLogins?: number | null;
        phoneNumber?: string | null;
        privateEmail?: boolean | null;
        provider?: GQL_User_Provider | null;
        zipCode?: string | null;
        paymentInformation?: {
          __typename?: 'PaymentInformation';
          customerId: string;
          id: string;
          cards: Array<{
            __typename?: 'PaymentCard';
            brand: string;
            id: string;
            last4: string;
            status?: GQL_PaymentCardStatus | null;
          }>;
        } | null;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
        rentals: Array<{
          __typename?: 'Rental';
          id: string;
          maxRentTimeInMinutes: number;
          numberOfFailedOpenings: number;
          numberOfSuccessfulOpenings: number;
          productStatus?: GQL_ProductStatus | null;
          productStatusText?: string | null;
          aborted?: boolean | null;
          rentEnd?: string | null;
          rentStart: string;
          box: {
            __typename?: 'RentalBox';
            id: string;
            name: string;
            location: {
              __typename?: 'Location';
              id: string;
              description?: string | null;
              name?: string | null;
              coordinates?: {
                __typename?: 'Coordinates';
                lat: number;
                long: number;
              } | null;
            };
          };
          compartment: {
            __typename?: 'RentalCompartment';
            id: string;
            name: string;
          };
          paymentChargeInformation?:
            | {
                __typename?: 'PaymentChargeInformationFail';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                fail_status: GQL_PaymentChargeFailStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationFreeRent';
                id: string;
                amount: number;
                currency: string;
                type: GQL_PaymentChargeInformationType;
              }
            | {
                __typename?: 'PaymentChargeInformationSuccess';
                id: string;
                amount: number;
                clientSecret?: string | null;
                currency: string;
                success_status: GQL_PaymentChargeSuccessStatus;
                type: GQL_PaymentChargeInformationType;
              }
            | null;
          product: {
            __typename?: 'Product';
            id: string;
            description?: string | null;
            imageName?: string | null;
            name: string;
            rentalType: GQL_ProductRentalType;
            translationKey?: string | null;
            priceWeights: {
              __typename?: 'PriceWeight';
              id: string;
              name: string;
              start: number;
              firstHour: number;
              secondHour: number;
              thirdHour: number;
              fourthHour: number;
              fifthHour: number;
              sixthHour: number;
              penalty: number;
            };
          };
          priceStructure: {
            __typename?: 'PriceStructure';
            id: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
          rentBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
            gender?: GQL_Gender | null;
            firstName?: string | null;
            lastName?: string | null;
            phoneNumber?: string | null;
            birthYear?: string | null;
          };
          rentStartBy: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          };
          rentEndBy?: {
            __typename?: 'User';
            id: string;
            email?: string | null;
          } | null;
          review?: {
            __typename?: 'Review';
            id: string;
            freeText?: string | null;
            rating?: number | null;
            ratingQuestionAnswers: Array<{
              __typename?: 'RatingQuestionAnswer';
              id: string;
              rating?: number | null;
              question: {
                __typename?: 'Question';
                id: string;
                eng: string;
                swe?: string | null;
              };
            }>;
          } | null;
        }>;
        roles: Array<{
          __typename?: 'Role';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'Permission';
            id: string;
            description?: string | null;
            type: GQL_PermissionType;
          }>;
        }>;
      } | null;
    }>;
  } | null;
};

export type GQL_OrganisationUserFragment = {
  __typename?: 'User';
  id: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  roles: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'Permission';
      id: string;
      description?: string | null;
      type: GQL_PermissionType;
    }>;
  }>;
};

export type GQL_OrganisationBoxFragment = {
  __typename?: 'Box';
  id: string;
  name: string;
  location: {
    __typename?: 'Location';
    id: string;
    description?: string | null;
    name?: string | null;
    coordinates?: {
      __typename?: 'Coordinates';
      lat: number;
      long: number;
    } | null;
  };
};

export type GQL_OrganisationFragment = {
  __typename?: 'Organisation';
  id: string;
  city?: string | null;
  country?: string | null;
  phoneNumber?: string | null;
  zipCode?: string | null;
  name: string;
  contactName: string;
  contactEmail: string;
  users: Array<{
    __typename?: 'User';
    id: string;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    roles: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
    }>;
  }>;
  boxes: Array<{
    __typename?: 'Box';
    id: string;
    name: string;
    location: {
      __typename?: 'Location';
      id: string;
      description?: string | null;
      name?: string | null;
      coordinates?: {
        __typename?: 'Coordinates';
        lat: number;
        long: number;
      } | null;
    };
  }>;
};

export type GQL_AllOrganisationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllOrganisationsQuery = {
  __typename?: 'Query';
  organisation?: {
    __typename?: 'OrganisationSearchRoot';
    allOrganisations: Array<{
      __typename?: 'Organisation';
      id: string;
      city?: string | null;
      country?: string | null;
      phoneNumber?: string | null;
      zipCode?: string | null;
      name: string;
      contactName: string;
      contactEmail: string;
      users: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        roles: Array<{
          __typename?: 'Role';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'Permission';
            id: string;
            description?: string | null;
            type: GQL_PermissionType;
          }>;
        }>;
      }>;
      boxes: Array<{
        __typename?: 'Box';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      }>;
    }>;
  } | null;
};

export type GQL_OrganisationByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_OrganisationByIdQuery = {
  __typename?: 'Query';
  organisation?: {
    __typename?: 'OrganisationSearchRoot';
    organisationById?: {
      __typename?: 'Organisation';
      id: string;
      city?: string | null;
      country?: string | null;
      phoneNumber?: string | null;
      zipCode?: string | null;
      name: string;
      contactName: string;
      contactEmail: string;
      users: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        roles: Array<{
          __typename?: 'Role';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'Permission';
            id: string;
            description?: string | null;
            type: GQL_PermissionType;
          }>;
        }>;
      }>;
      boxes: Array<{
        __typename?: 'Box';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      }>;
    } | null;
  } | null;
};

export type GQL_AddOrganisationMutationVariables = Exact<{
  organisation: GQL_AddOrganisationInput;
}>;

export type GQL_AddOrganisationMutation = {
  __typename?: 'Mutation';
  addOrganisation: {
    __typename?: 'AddOrganisationResponse';
    organisation?: {
      __typename?: 'Organisation';
      id: string;
      city?: string | null;
      country?: string | null;
      phoneNumber?: string | null;
      zipCode?: string | null;
      name: string;
      contactName: string;
      contactEmail: string;
      users: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        roles: Array<{
          __typename?: 'Role';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'Permission';
            id: string;
            description?: string | null;
            type: GQL_PermissionType;
          }>;
        }>;
      }>;
      boxes: Array<{
        __typename?: 'Box';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      }>;
    } | null;
  };
};

export type GQL_EditOrganisationMutationVariables = Exact<{
  organisationId: Scalars['ID']['input'];
  editOrganisation: GQL_EditOrganisationInput;
}>;

export type GQL_EditOrganisationMutation = {
  __typename?: 'Mutation';
  editOrganisation: {
    __typename?: 'EditOrganisationResponse';
    organisation?: {
      __typename?: 'Organisation';
      id: string;
      city?: string | null;
      country?: string | null;
      phoneNumber?: string | null;
      zipCode?: string | null;
      name: string;
      contactName: string;
      contactEmail: string;
      users: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        roles: Array<{
          __typename?: 'Role';
          id: string;
          name: string;
          permissions: Array<{
            __typename?: 'Permission';
            id: string;
            description?: string | null;
            type: GQL_PermissionType;
          }>;
        }>;
      }>;
      boxes: Array<{
        __typename?: 'Box';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      }>;
    } | null;
  };
};

export type GQL_PendingUserFragment = {
  __typename?: 'PendingUser';
  id: string;
  email: string;
  roles: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'Permission';
      id: string;
      description?: string | null;
      type: GQL_PermissionType;
    }>;
  }>;
  organisations: Array<{
    __typename?: 'Organisation';
    id: string;
    city?: string | null;
    country?: string | null;
    phoneNumber?: string | null;
    zipCode?: string | null;
    name: string;
    contactName: string;
    contactEmail: string;
    users: Array<{
      __typename?: 'User';
      id: string;
      email?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
    }>;
    boxes: Array<{
      __typename?: 'Box';
      id: string;
      name: string;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    }>;
  }>;
};

export type GQL_AllPendingUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllPendingUsersQuery = {
  __typename?: 'Query';
  pendingUser?: {
    __typename?: 'PendingUserSearchRoot';
    allPendingUsers: Array<{
      __typename?: 'PendingUser';
      id: string;
      email: string;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
      organisations: Array<{
        __typename?: 'Organisation';
        id: string;
        city?: string | null;
        country?: string | null;
        phoneNumber?: string | null;
        zipCode?: string | null;
        name: string;
        contactName: string;
        contactEmail: string;
        users: Array<{
          __typename?: 'User';
          id: string;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          roles: Array<{
            __typename?: 'Role';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'Permission';
              id: string;
              description?: string | null;
              type: GQL_PermissionType;
            }>;
          }>;
        }>;
        boxes: Array<{
          __typename?: 'Box';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        }>;
      }>;
    }>;
  } | null;
};

export type GQL_PendingUserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_PendingUserByIdQuery = {
  __typename?: 'Query';
  pendingUser?: {
    __typename?: 'PendingUserSearchRoot';
    pendingUserById?: {
      __typename?: 'PendingUser';
      id: string;
      email: string;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
      organisations: Array<{
        __typename?: 'Organisation';
        id: string;
        city?: string | null;
        country?: string | null;
        phoneNumber?: string | null;
        zipCode?: string | null;
        name: string;
        contactName: string;
        contactEmail: string;
        users: Array<{
          __typename?: 'User';
          id: string;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          roles: Array<{
            __typename?: 'Role';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'Permission';
              id: string;
              description?: string | null;
              type: GQL_PermissionType;
            }>;
          }>;
        }>;
        boxes: Array<{
          __typename?: 'Box';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        }>;
      }>;
    } | null;
  } | null;
};

export type GQL_AddPendingUserMutationVariables = Exact<{
  pendingUser: GQL_AddPendingUsersInput;
}>;

export type GQL_AddPendingUserMutation = {
  __typename?: 'Mutation';
  addPendingUsers: Array<{
    __typename?: 'AddPendingUserResponse';
    pendingUser?: {
      __typename?: 'PendingUser';
      id: string;
      email: string;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
      organisations: Array<{
        __typename?: 'Organisation';
        id: string;
        city?: string | null;
        country?: string | null;
        phoneNumber?: string | null;
        zipCode?: string | null;
        name: string;
        contactName: string;
        contactEmail: string;
        users: Array<{
          __typename?: 'User';
          id: string;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          roles: Array<{
            __typename?: 'Role';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'Permission';
              id: string;
              description?: string | null;
              type: GQL_PermissionType;
            }>;
          }>;
        }>;
        boxes: Array<{
          __typename?: 'Box';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        }>;
      }>;
    } | null;
  }>;
};

export type GQL_EditPendingUserMutationVariables = Exact<{
  pendingUserId: Scalars['ID']['input'];
  pendingUser: GQL_EditPendingUserInput;
}>;

export type GQL_EditPendingUserMutation = {
  __typename?: 'Mutation';
  editPendingUser: {
    __typename?: 'EditPendingUserResponse';
    pendingUser?: {
      __typename?: 'PendingUser';
      id: string;
      email: string;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
      organisations: Array<{
        __typename?: 'Organisation';
        id: string;
        city?: string | null;
        country?: string | null;
        phoneNumber?: string | null;
        zipCode?: string | null;
        name: string;
        contactName: string;
        contactEmail: string;
        users: Array<{
          __typename?: 'User';
          id: string;
          email?: string | null;
          firstName?: string | null;
          lastName?: string | null;
          roles: Array<{
            __typename?: 'Role';
            id: string;
            name: string;
            permissions: Array<{
              __typename?: 'Permission';
              id: string;
              description?: string | null;
              type: GQL_PermissionType;
            }>;
          }>;
        }>;
        boxes: Array<{
          __typename?: 'Box';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        }>;
      }>;
    } | null;
  };
};

export type GQL_PermissionFragment = {
  __typename?: 'Permission';
  id: string;
  description?: string | null;
  type: GQL_PermissionType;
};

export type GQL_AllPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllPermissionsQuery = {
  __typename?: 'Query';
  permission?: {
    __typename?: 'PermissionSearchRoot';
    allPermissions: Array<{
      __typename?: 'Permission';
      id: string;
      description?: string | null;
      type: GQL_PermissionType;
    }>;
  } | null;
};

export type GQL_PriceWeightFragment = {
  __typename?: 'PriceWeight';
  id: string;
  name: string;
  start: number;
  firstHour: number;
  secondHour: number;
  thirdHour: number;
  fourthHour: number;
  fifthHour: number;
  sixthHour: number;
  penalty: number;
};

export type GQL_PriceStructureFragment = {
  __typename?: 'PriceStructure';
  id: string;
  start: number;
  firstHour: number;
  secondHour: number;
  thirdHour: number;
  fourthHour: number;
  fifthHour: number;
  sixthHour: number;
  penalty: number;
};

export type GQL_AllPriceWeightsQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllPriceWeightsQuery = {
  __typename?: 'Query';
  payment?: {
    __typename?: 'PaymentSearchRoot';
    allPriceWeights: Array<{
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    }>;
  } | null;
};

export type GQL_PriceWeightByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_PriceWeightByIdQuery = {
  __typename?: 'Query';
  payment?: {
    __typename?: 'PaymentSearchRoot';
    priceWeightById?: {
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    } | null;
  } | null;
};

export type GQL_PriceStructureQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_PriceStructureQuery = {
  __typename?: 'Query';
  payment?: {
    __typename?: 'PaymentSearchRoot';
    priceStructure: {
      __typename?: 'PriceStructure';
      id: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
  } | null;
};

export type GQL_EditPriceStructureMutationVariables = Exact<{
  priceStructureId: Scalars['ID']['input'];
  editPriceStructureInput: GQL_EditPriceStructureInput;
}>;

export type GQL_EditPriceStructureMutation = {
  __typename?: 'Mutation';
  editPriceStructure: {
    __typename?: 'EditPriceStructureResponse';
    priceStructure: {
      __typename?: 'PriceStructure';
      id: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
  };
};

export type GQL_AddPriceWeightMutationVariables = Exact<{
  priceWeight: GQL_AddPriceWeightInput;
}>;

export type GQL_AddPriceWeightMutation = {
  __typename?: 'Mutation';
  addPriceWeight: {
    __typename?: 'AddPriceWeightResponse';
    priceWeight?: {
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    } | null;
  };
};

export type GQL_EditPriceWeightMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  priceWeight: GQL_EditPriceWeightInput;
}>;

export type GQL_EditPriceWeightMutation = {
  __typename?: 'Mutation';
  editPriceWeight: {
    __typename?: 'EditPriceWeightResponse';
    priceWeight?: {
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    } | null;
  };
};

export type GQL_ProductFragment = {
  __typename?: 'Product';
  id: string;
  description?: string | null;
  imageName?: string | null;
  name: string;
  rentalType: GQL_ProductRentalType;
  translationKey?: string | null;
  priceWeights: {
    __typename?: 'PriceWeight';
    id: string;
    name: string;
    start: number;
    firstHour: number;
    secondHour: number;
    thirdHour: number;
    fourthHour: number;
    fifthHour: number;
    sixthHour: number;
    penalty: number;
  };
};

export type GQL_AllProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllProductsQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'ProductSearchRoot';
    allProducts: Array<{
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    }>;
  } | null;
};

export type GQL_ProductByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_ProductByIdQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'ProductSearchRoot';
    productById?: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    } | null;
  } | null;
};

export type GQL_AddProductMutationVariables = Exact<{
  addProduct: GQL_AddProductInput;
}>;

export type GQL_AddProductMutation = {
  __typename?: 'Mutation';
  addProduct: {
    __typename?: 'AddProductResponse';
    product?: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    } | null;
  };
};

export type GQL_EditProductMutationVariables = Exact<{
  productId: Scalars['ID']['input'];
  editProduct: GQL_EditProductInput;
}>;

export type GQL_EditProductMutation = {
  __typename?: 'Mutation';
  editProduct: {
    __typename?: 'EditProductResponse';
    product?: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    } | null;
  };
};

export type GQL_PaymentChargeInformationSuccessFragment = {
  __typename?: 'PaymentChargeInformationSuccess';
  id: string;
  amount: number;
  clientSecret?: string | null;
  currency: string;
  success_status: GQL_PaymentChargeSuccessStatus;
  type: GQL_PaymentChargeInformationType;
};

export type GQL_PaymentChargeInformationFailFragment = {
  __typename?: 'PaymentChargeInformationFail';
  id: string;
  amount: number;
  clientSecret?: string | null;
  currency: string;
  fail_status: GQL_PaymentChargeFailStatus;
  type: GQL_PaymentChargeInformationType;
};

export type GQL_PaymentChargeInformationFreeRentFragment = {
  __typename?: 'PaymentChargeInformationFreeRent';
  id: string;
  amount: number;
  currency: string;
  type: GQL_PaymentChargeInformationType;
};

export type GQL_RentalFragment = {
  __typename?: 'Rental';
  id: string;
  maxRentTimeInMinutes: number;
  numberOfFailedOpenings: number;
  numberOfSuccessfulOpenings: number;
  productStatus?: GQL_ProductStatus | null;
  productStatusText?: string | null;
  aborted?: boolean | null;
  rentEnd?: string | null;
  rentStart: string;
  box: {
    __typename?: 'RentalBox';
    id: string;
    name: string;
    location: {
      __typename?: 'Location';
      id: string;
      description?: string | null;
      name?: string | null;
      coordinates?: {
        __typename?: 'Coordinates';
        lat: number;
        long: number;
      } | null;
    };
  };
  compartment: { __typename?: 'RentalCompartment'; id: string; name: string };
  paymentChargeInformation?:
    | {
        __typename?: 'PaymentChargeInformationFail';
        id: string;
        amount: number;
        clientSecret?: string | null;
        currency: string;
        fail_status: GQL_PaymentChargeFailStatus;
        type: GQL_PaymentChargeInformationType;
      }
    | {
        __typename?: 'PaymentChargeInformationFreeRent';
        id: string;
        amount: number;
        currency: string;
        type: GQL_PaymentChargeInformationType;
      }
    | {
        __typename?: 'PaymentChargeInformationSuccess';
        id: string;
        amount: number;
        clientSecret?: string | null;
        currency: string;
        success_status: GQL_PaymentChargeSuccessStatus;
        type: GQL_PaymentChargeInformationType;
      }
    | null;
  product: {
    __typename?: 'Product';
    id: string;
    description?: string | null;
    imageName?: string | null;
    name: string;
    rentalType: GQL_ProductRentalType;
    translationKey?: string | null;
    priceWeights: {
      __typename?: 'PriceWeight';
      id: string;
      name: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
  };
  priceStructure: {
    __typename?: 'PriceStructure';
    id: string;
    start: number;
    firstHour: number;
    secondHour: number;
    thirdHour: number;
    fourthHour: number;
    fifthHour: number;
    sixthHour: number;
    penalty: number;
  };
  rentBy: {
    __typename?: 'User';
    id: string;
    email?: string | null;
    gender?: GQL_Gender | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
    birthYear?: string | null;
  };
  rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
  rentEndBy?: { __typename?: 'User'; id: string; email?: string | null } | null;
  review?: {
    __typename?: 'Review';
    id: string;
    freeText?: string | null;
    rating?: number | null;
    ratingQuestionAnswers: Array<{
      __typename?: 'RatingQuestionAnswer';
      id: string;
      rating?: number | null;
      question: {
        __typename?: 'Question';
        id: string;
        eng: string;
        swe?: string | null;
      };
    }>;
  } | null;
};

export type GQL_AllOngoingRentalsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllOngoingRentalsQuery = {
  __typename?: 'Query';
  rental?: {
    __typename?: 'RentalSearchRoot';
    allOngoingRentals: Array<{
      __typename?: 'Rental';
      id: string;
      maxRentTimeInMinutes: number;
      numberOfFailedOpenings: number;
      numberOfSuccessfulOpenings: number;
      productStatus?: GQL_ProductStatus | null;
      productStatusText?: string | null;
      aborted?: boolean | null;
      rentEnd?: string | null;
      rentStart: string;
      box: {
        __typename?: 'RentalBox';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
      compartment: {
        __typename?: 'RentalCompartment';
        id: string;
        name: string;
      };
      paymentChargeInformation?:
        | {
            __typename?: 'PaymentChargeInformationFail';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            fail_status: GQL_PaymentChargeFailStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationFreeRent';
            id: string;
            amount: number;
            currency: string;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationSuccess';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            success_status: GQL_PaymentChargeSuccessStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
      priceStructure: {
        __typename?: 'PriceStructure';
        id: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentBy: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        gender?: GQL_Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        birthYear?: string | null;
      };
      rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
      rentEndBy?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
      } | null;
      review?: {
        __typename?: 'Review';
        id: string;
        freeText?: string | null;
        rating?: number | null;
        ratingQuestionAnswers: Array<{
          __typename?: 'RatingQuestionAnswer';
          id: string;
          rating?: number | null;
          question: {
            __typename?: 'Question';
            id: string;
            eng: string;
            swe?: string | null;
          };
        }>;
      } | null;
    }>;
  } | null;
};

export type GQL_AllRentalsQueryVariables = Exact<{
  filter?: InputMaybe<GQL_RentalsFilterInput>;
  pagination?: InputMaybe<GQL_Pagination>;
  search?: InputMaybe<GQL_RentalsSearchInput>;
}>;

export type GQL_AllRentalsQuery = {
  __typename?: 'Query';
  rental?: {
    __typename?: 'RentalSearchRoot';
    allRentals: {
      __typename?: 'AllRentalsResponse';
      totalCount: number;
      nodes: Array<{
        __typename?: 'Rental';
        id: string;
        maxRentTimeInMinutes: number;
        numberOfFailedOpenings: number;
        numberOfSuccessfulOpenings: number;
        productStatus?: GQL_ProductStatus | null;
        productStatusText?: string | null;
        aborted?: boolean | null;
        rentEnd?: string | null;
        rentStart: string;
        box: {
          __typename?: 'RentalBox';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        };
        compartment: {
          __typename?: 'RentalCompartment';
          id: string;
          name: string;
        };
        paymentChargeInformation?:
          | {
              __typename?: 'PaymentChargeInformationFail';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              fail_status: GQL_PaymentChargeFailStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationFreeRent';
              id: string;
              amount: number;
              currency: string;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationSuccess';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              success_status: GQL_PaymentChargeSuccessStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
        priceStructure: {
          __typename?: 'PriceStructure';
          id: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
        rentBy: {
          __typename?: 'User';
          id: string;
          email?: string | null;
          gender?: GQL_Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber?: string | null;
          birthYear?: string | null;
        };
        rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
        rentEndBy?: {
          __typename?: 'User';
          id: string;
          email?: string | null;
        } | null;
        review?: {
          __typename?: 'Review';
          id: string;
          freeText?: string | null;
          rating?: number | null;
          ratingQuestionAnswers: Array<{
            __typename?: 'RatingQuestionAnswer';
            id: string;
            rating?: number | null;
            question: {
              __typename?: 'Question';
              id: string;
              eng: string;
              swe?: string | null;
            };
          }>;
        } | null;
      }>;
    };
  } | null;
};

export type GQL_RentalByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_RentalByIdQuery = {
  __typename?: 'Query';
  rental?: {
    __typename?: 'RentalSearchRoot';
    rentalById?: {
      __typename?: 'Rental';
      id: string;
      maxRentTimeInMinutes: number;
      numberOfFailedOpenings: number;
      numberOfSuccessfulOpenings: number;
      productStatus?: GQL_ProductStatus | null;
      productStatusText?: string | null;
      aborted?: boolean | null;
      rentEnd?: string | null;
      rentStart: string;
      box: {
        __typename?: 'RentalBox';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
      compartment: {
        __typename?: 'RentalCompartment';
        id: string;
        name: string;
      };
      paymentChargeInformation?:
        | {
            __typename?: 'PaymentChargeInformationFail';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            fail_status: GQL_PaymentChargeFailStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationFreeRent';
            id: string;
            amount: number;
            currency: string;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationSuccess';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            success_status: GQL_PaymentChargeSuccessStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
      priceStructure: {
        __typename?: 'PriceStructure';
        id: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentBy: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        gender?: GQL_Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        birthYear?: string | null;
      };
      rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
      rentEndBy?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
      } | null;
      review?: {
        __typename?: 'Review';
        id: string;
        freeText?: string | null;
        rating?: number | null;
        ratingQuestionAnswers: Array<{
          __typename?: 'RatingQuestionAnswer';
          id: string;
          rating?: number | null;
          question: {
            __typename?: 'Question';
            id: string;
            eng: string;
            swe?: string | null;
          };
        }>;
      } | null;
    } | null;
  } | null;
};

export type GQL_StopRentalMutationVariables = Exact<{
  rentalInput: GQL_StopRentalInput;
}>;

export type GQL_StopRentalMutation = {
  __typename?: 'Mutation';
  stopRental: {
    __typename?: 'StopRentalResponse';
    type?: GQL_StopRentalResponseType | null;
    paymentChargeInformation?:
      | {
          __typename?: 'PaymentChargeInformationFail';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          fail_status: GQL_PaymentChargeFailStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationFreeRent';
          id: string;
          amount: number;
          currency: string;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationSuccess';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          success_status: GQL_PaymentChargeSuccessStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | null;
    compartment?: {
      __typename?: 'Compartment';
      id: string;
      locked?: boolean | null;
      circuitBoardPort: number;
      name: string;
      private?: boolean | null;
      product?: {
        __typename?: 'CompartmentProduct';
        productStatus?: GQL_ProductStatus | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
      } | null;
    } | null;
    rental?: {
      __typename?: 'Rental';
      id: string;
      maxRentTimeInMinutes: number;
      numberOfFailedOpenings: number;
      numberOfSuccessfulOpenings: number;
      productStatus?: GQL_ProductStatus | null;
      productStatusText?: string | null;
      aborted?: boolean | null;
      rentEnd?: string | null;
      rentStart: string;
      box: {
        __typename?: 'RentalBox';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
      compartment: {
        __typename?: 'RentalCompartment';
        id: string;
        name: string;
      };
      paymentChargeInformation?:
        | {
            __typename?: 'PaymentChargeInformationFail';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            fail_status: GQL_PaymentChargeFailStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationFreeRent';
            id: string;
            amount: number;
            currency: string;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationSuccess';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            success_status: GQL_PaymentChargeSuccessStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
      priceStructure: {
        __typename?: 'PriceStructure';
        id: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentBy: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        gender?: GQL_Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        birthYear?: string | null;
      };
      rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
      rentEndBy?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
      } | null;
      review?: {
        __typename?: 'Review';
        id: string;
        freeText?: string | null;
        rating?: number | null;
        ratingQuestionAnswers: Array<{
          __typename?: 'RatingQuestionAnswer';
          id: string;
          rating?: number | null;
          question: {
            __typename?: 'Question';
            id: string;
            eng: string;
            swe?: string | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type GQL_ChargeRentalMutationVariables = Exact<{
  chargeRentalInput: GQL_ChargeRentalInput;
}>;

export type GQL_ChargeRentalMutation = {
  __typename?: 'Mutation';
  chargeRental: {
    __typename?: 'ChargeRentalResponse';
    type?: GQL_ChargeRentalResponseType | null;
    paymentChargeInformation?:
      | {
          __typename?: 'PaymentChargeInformationFail';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          fail_status: GQL_PaymentChargeFailStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationFreeRent';
          id: string;
          amount: number;
          currency: string;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationSuccess';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          success_status: GQL_PaymentChargeSuccessStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | null;
    rental?: {
      __typename?: 'Rental';
      id: string;
      maxRentTimeInMinutes: number;
      numberOfFailedOpenings: number;
      numberOfSuccessfulOpenings: number;
      productStatus?: GQL_ProductStatus | null;
      productStatusText?: string | null;
      aborted?: boolean | null;
      rentEnd?: string | null;
      rentStart: string;
      box: {
        __typename?: 'RentalBox';
        id: string;
        name: string;
        location: {
          __typename?: 'Location';
          id: string;
          description?: string | null;
          name?: string | null;
          coordinates?: {
            __typename?: 'Coordinates';
            lat: number;
            long: number;
          } | null;
        };
      };
      compartment: {
        __typename?: 'RentalCompartment';
        id: string;
        name: string;
      };
      paymentChargeInformation?:
        | {
            __typename?: 'PaymentChargeInformationFail';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            fail_status: GQL_PaymentChargeFailStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationFreeRent';
            id: string;
            amount: number;
            currency: string;
            type: GQL_PaymentChargeInformationType;
          }
        | {
            __typename?: 'PaymentChargeInformationSuccess';
            id: string;
            amount: number;
            clientSecret?: string | null;
            currency: string;
            success_status: GQL_PaymentChargeSuccessStatus;
            type: GQL_PaymentChargeInformationType;
          }
        | null;
      product: {
        __typename?: 'Product';
        id: string;
        description?: string | null;
        imageName?: string | null;
        name: string;
        rentalType: GQL_ProductRentalType;
        translationKey?: string | null;
        priceWeights: {
          __typename?: 'PriceWeight';
          id: string;
          name: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
      };
      priceStructure: {
        __typename?: 'PriceStructure';
        id: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentBy: {
        __typename?: 'User';
        id: string;
        email?: string | null;
        gender?: GQL_Gender | null;
        firstName?: string | null;
        lastName?: string | null;
        phoneNumber?: string | null;
        birthYear?: string | null;
      };
      rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
      rentEndBy?: {
        __typename?: 'User';
        id: string;
        email?: string | null;
      } | null;
      review?: {
        __typename?: 'Review';
        id: string;
        freeText?: string | null;
        rating?: number | null;
        ratingQuestionAnswers: Array<{
          __typename?: 'RatingQuestionAnswer';
          id: string;
          rating?: number | null;
          question: {
            __typename?: 'Question';
            id: string;
            eng: string;
            swe?: string | null;
          };
        }>;
      } | null;
    } | null;
  };
};

export type GQL_CreateRentalImageGetUrlMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_CreateRentalImageGetUrlMutation = {
  __typename?: 'Mutation';
  createRentalImageGetUrl: {
    __typename?: 'CreateRentalImageGetUrlResponse';
    imageUrl?: string | null;
  };
};

export type GQL_QuestionFragment = {
  __typename?: 'Question';
  id: string;
  eng: string;
  swe?: string | null;
};

export type GQL_RatingQuestionAnswerFragment = {
  __typename?: 'RatingQuestionAnswer';
  id: string;
  rating?: number | null;
  question: {
    __typename?: 'Question';
    id: string;
    eng: string;
    swe?: string | null;
  };
};

export type GQL_ReviewFragment = {
  __typename?: 'Review';
  id: string;
  freeText?: string | null;
  rating?: number | null;
  ratingQuestionAnswers: Array<{
    __typename?: 'RatingQuestionAnswer';
    id: string;
    rating?: number | null;
    question: {
      __typename?: 'Question';
      id: string;
      eng: string;
      swe?: string | null;
    };
  }>;
};

export type GQL_ReviewByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_ReviewByIdQuery = {
  __typename?: 'Query';
  review?: {
    __typename?: 'ReviewSearchRoot';
    reviewById?: {
      __typename?: 'Review';
      id: string;
      freeText?: string | null;
      rating?: number | null;
      ratingQuestionAnswers: Array<{
        __typename?: 'RatingQuestionAnswer';
        id: string;
        rating?: number | null;
        question: {
          __typename?: 'Question';
          id: string;
          eng: string;
          swe?: string | null;
        };
      }>;
    } | null;
  } | null;
};

export type GQL_AllReviewsQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllReviewsQuery = {
  __typename?: 'Query';
  review?: {
    __typename?: 'ReviewSearchRoot';
    allReviews: Array<{
      __typename?: 'Review';
      id: string;
      freeText?: string | null;
      rating?: number | null;
      ratingQuestionAnswers: Array<{
        __typename?: 'RatingQuestionAnswer';
        id: string;
        rating?: number | null;
        question: {
          __typename?: 'Question';
          id: string;
          eng: string;
          swe?: string | null;
        };
      }>;
    }>;
  } | null;
};

export type GQL_RoleFragment = {
  __typename?: 'Role';
  id: string;
  name: string;
  permissions: Array<{
    __typename?: 'Permission';
    id: string;
    description?: string | null;
    type: GQL_PermissionType;
  }>;
};

export type GQL_AllRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllRolesQuery = {
  __typename?: 'Query';
  role?: {
    __typename?: 'RoleSearchRoot';
    allRoles: Array<{
      __typename?: 'Role';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
    }>;
  } | null;
};

export type GQL_RoleByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_RoleByIdQuery = {
  __typename?: 'Query';
  role?: {
    __typename?: 'RoleSearchRoot';
    roleById?: {
      __typename?: 'Role';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
    } | null;
  } | null;
};

export type GQL_AddRoleMutationVariables = Exact<{
  addRole: GQL_AddRoleInput;
}>;

export type GQL_AddRoleMutation = {
  __typename?: 'Mutation';
  addRole: {
    __typename?: 'AddRoleResponse';
    role?: {
      __typename?: 'Role';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
    } | null;
  };
};

export type GQL_EditRoleMutationVariables = Exact<{
  roleId: Scalars['ID']['input'];
  editRole: GQL_EditRoleInput;
}>;

export type GQL_EditRoleMutation = {
  __typename?: 'Mutation';
  editRole: {
    __typename?: 'EditRoleResponse';
    role?: {
      __typename?: 'Role';
      id: string;
      name: string;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
    } | null;
  };
};

export type GQL_SponsorFragment = {
  __typename?: 'Sponsor';
  id: string;
  imageName: string;
  name: string;
  url?: string | null;
};

export type GQL_AllSponsorsQueryVariables = Exact<{ [key: string]: never }>;

export type GQL_AllSponsorsQuery = {
  __typename?: 'Query';
  sponsor?: {
    __typename?: 'SponsorSearchRoot';
    allSponsors: Array<{
      __typename?: 'Sponsor';
      id: string;
      imageName: string;
      name: string;
      url?: string | null;
    }>;
  } | null;
};

export type GQL_SponsorByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_SponsorByIdQuery = {
  __typename?: 'Query';
  sponsor?: {
    __typename?: 'SponsorSearchRoot';
    sponsorById?: {
      __typename?: 'Sponsor';
      id: string;
      imageName: string;
      name: string;
      url?: string | null;
    } | null;
  } | null;
};

export type GQL_AddSponsorMutationVariables = Exact<{
  addSponsor: GQL_AddSponsorInput;
}>;

export type GQL_AddSponsorMutation = {
  __typename?: 'Mutation';
  addSponsor: {
    __typename?: 'AddSponsorResponse';
    sponsor?: {
      __typename?: 'Sponsor';
      id: string;
      imageName: string;
      name: string;
      url?: string | null;
    } | null;
  };
};

export type GQL_EditSponsorMutationVariables = Exact<{
  sponsorId: Scalars['ID']['input'];
  editSponsor: GQL_EditSponsorInput;
}>;

export type GQL_EditSponsorMutation = {
  __typename?: 'Mutation';
  editSponsor: {
    __typename?: 'EditSponsorResponse';
    sponsor?: {
      __typename?: 'Sponsor';
      id: string;
      imageName: string;
      name: string;
      url?: string | null;
    } | null;
  };
};

export type GQL_UserFragment = {
  __typename?: 'User';
  id: string;
  accountCreated?: string | null;
  accountUpdated?: string | null;
  birthDate?: string | null;
  birthYear?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  emailVerified?: boolean | null;
  externalId?: string | null;
  firstName?: string | null;
  gender?: GQL_Gender | null;
  lastLogin?: string | null;
  lastName?: string | null;
  numberOfLogins?: number | null;
  phoneNumber?: string | null;
  privateEmail?: boolean | null;
  provider?: GQL_User_Provider | null;
  zipCode?: string | null;
  paymentInformation?: {
    __typename?: 'PaymentInformation';
    customerId: string;
    id: string;
    cards: Array<{
      __typename?: 'PaymentCard';
      brand: string;
      id: string;
      last4: string;
      status?: GQL_PaymentCardStatus | null;
    }>;
  } | null;
  permissions: Array<{
    __typename?: 'Permission';
    id: string;
    description?: string | null;
    type: GQL_PermissionType;
  }>;
  priceWeights: {
    __typename?: 'PriceWeight';
    id: string;
    name: string;
    start: number;
    firstHour: number;
    secondHour: number;
    thirdHour: number;
    fourthHour: number;
    fifthHour: number;
    sixthHour: number;
    penalty: number;
  };
  rentals: Array<{
    __typename?: 'Rental';
    id: string;
    maxRentTimeInMinutes: number;
    numberOfFailedOpenings: number;
    numberOfSuccessfulOpenings: number;
    productStatus?: GQL_ProductStatus | null;
    productStatusText?: string | null;
    aborted?: boolean | null;
    rentEnd?: string | null;
    rentStart: string;
    box: {
      __typename?: 'RentalBox';
      id: string;
      name: string;
      location: {
        __typename?: 'Location';
        id: string;
        description?: string | null;
        name?: string | null;
        coordinates?: {
          __typename?: 'Coordinates';
          lat: number;
          long: number;
        } | null;
      };
    };
    compartment: { __typename?: 'RentalCompartment'; id: string; name: string };
    paymentChargeInformation?:
      | {
          __typename?: 'PaymentChargeInformationFail';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          fail_status: GQL_PaymentChargeFailStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationFreeRent';
          id: string;
          amount: number;
          currency: string;
          type: GQL_PaymentChargeInformationType;
        }
      | {
          __typename?: 'PaymentChargeInformationSuccess';
          id: string;
          amount: number;
          clientSecret?: string | null;
          currency: string;
          success_status: GQL_PaymentChargeSuccessStatus;
          type: GQL_PaymentChargeInformationType;
        }
      | null;
    product: {
      __typename?: 'Product';
      id: string;
      description?: string | null;
      imageName?: string | null;
      name: string;
      rentalType: GQL_ProductRentalType;
      translationKey?: string | null;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
    };
    priceStructure: {
      __typename?: 'PriceStructure';
      id: string;
      start: number;
      firstHour: number;
      secondHour: number;
      thirdHour: number;
      fourthHour: number;
      fifthHour: number;
      sixthHour: number;
      penalty: number;
    };
    rentBy: {
      __typename?: 'User';
      id: string;
      email?: string | null;
      gender?: GQL_Gender | null;
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      birthYear?: string | null;
    };
    rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
    rentEndBy?: {
      __typename?: 'User';
      id: string;
      email?: string | null;
    } | null;
    review?: {
      __typename?: 'Review';
      id: string;
      freeText?: string | null;
      rating?: number | null;
      ratingQuestionAnswers: Array<{
        __typename?: 'RatingQuestionAnswer';
        id: string;
        rating?: number | null;
        question: {
          __typename?: 'Question';
          id: string;
          eng: string;
          swe?: string | null;
        };
      }>;
    } | null;
  }>;
  roles: Array<{
    __typename?: 'Role';
    id: string;
    name: string;
    permissions: Array<{
      __typename?: 'Permission';
      id: string;
      description?: string | null;
      type: GQL_PermissionType;
    }>;
  }>;
};

export type GQL_UserLiteFragment = {
  __typename?: 'User';
  id: string;
  accountCreated?: string | null;
  accountUpdated?: string | null;
  birthDate?: string | null;
  birthYear?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  emailVerified?: boolean | null;
  externalId?: string | null;
  firstName?: string | null;
  gender?: GQL_Gender | null;
  lastLogin?: string | null;
  lastName?: string | null;
  numberOfLogins?: number | null;
  phoneNumber?: string | null;
  priceWeight: number;
  privateEmail?: boolean | null;
  provider?: GQL_User_Provider | null;
  zipCode?: string | null;
  paymentInformation?: {
    __typename?: 'PaymentInformation';
    customerId: string;
    id: string;
    cards: Array<{
      __typename?: 'PaymentCard';
      brand: string;
      id: string;
      last4: string;
      status?: GQL_PaymentCardStatus | null;
    }>;
  } | null;
  priceWeights: { __typename?: 'PriceWeight'; id: string; name: string };
  rentals: Array<{ __typename?: 'Rental'; id: string }>;
  roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
};

export type GQL_AllUsersQueryVariables = Exact<{
  filter?: InputMaybe<GQL_UsersFilterInput>;
  pagination?: InputMaybe<GQL_Pagination>;
  search?: InputMaybe<GQL_UserSearchInput>;
}>;

export type GQL_AllUsersQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserSearchRoot';
    allUsers: {
      __typename?: 'AllUsersResponse';
      totalCount: number;
      nodes: Array<{
        __typename?: 'User';
        id: string;
        accountCreated?: string | null;
        accountUpdated?: string | null;
        birthDate?: string | null;
        birthYear?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        emailVerified?: boolean | null;
        externalId?: string | null;
        firstName?: string | null;
        gender?: GQL_Gender | null;
        lastLogin?: string | null;
        lastName?: string | null;
        numberOfLogins?: number | null;
        phoneNumber?: string | null;
        priceWeight: number;
        privateEmail?: boolean | null;
        provider?: GQL_User_Provider | null;
        zipCode?: string | null;
        paymentInformation?: {
          __typename?: 'PaymentInformation';
          customerId: string;
          id: string;
          cards: Array<{
            __typename?: 'PaymentCard';
            brand: string;
            id: string;
            last4: string;
            status?: GQL_PaymentCardStatus | null;
          }>;
        } | null;
        priceWeights: { __typename?: 'PriceWeight'; id: string; name: string };
        rentals: Array<{ __typename?: 'Rental'; id: string }>;
        roles: Array<{ __typename?: 'Role'; id: string; name: string }>;
      }>;
    };
  } | null;
};

export type GQL_AllUsersForSelectQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GQL_AllUsersForSelectQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserSearchRoot';
    allUsers: {
      __typename?: 'AllUsersResponse';
      nodes: Array<{
        __typename?: 'User';
        id: string;
        email?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      }>;
    };
  } | null;
};

export type GQL_UserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type GQL_UserByIdQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'UserSearchRoot';
    userById?: {
      __typename?: 'User';
      id: string;
      accountCreated?: string | null;
      accountUpdated?: string | null;
      birthDate?: string | null;
      birthYear?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      emailVerified?: boolean | null;
      externalId?: string | null;
      firstName?: string | null;
      gender?: GQL_Gender | null;
      lastLogin?: string | null;
      lastName?: string | null;
      numberOfLogins?: number | null;
      phoneNumber?: string | null;
      privateEmail?: boolean | null;
      provider?: GQL_User_Provider | null;
      zipCode?: string | null;
      paymentInformation?: {
        __typename?: 'PaymentInformation';
        customerId: string;
        id: string;
        cards: Array<{
          __typename?: 'PaymentCard';
          brand: string;
          id: string;
          last4: string;
          status?: GQL_PaymentCardStatus | null;
        }>;
      } | null;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentals: Array<{
        __typename?: 'Rental';
        id: string;
        maxRentTimeInMinutes: number;
        numberOfFailedOpenings: number;
        numberOfSuccessfulOpenings: number;
        productStatus?: GQL_ProductStatus | null;
        productStatusText?: string | null;
        aborted?: boolean | null;
        rentEnd?: string | null;
        rentStart: string;
        box: {
          __typename?: 'RentalBox';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        };
        compartment: {
          __typename?: 'RentalCompartment';
          id: string;
          name: string;
        };
        paymentChargeInformation?:
          | {
              __typename?: 'PaymentChargeInformationFail';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              fail_status: GQL_PaymentChargeFailStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationFreeRent';
              id: string;
              amount: number;
              currency: string;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationSuccess';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              success_status: GQL_PaymentChargeSuccessStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
        priceStructure: {
          __typename?: 'PriceStructure';
          id: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
        rentBy: {
          __typename?: 'User';
          id: string;
          email?: string | null;
          gender?: GQL_Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber?: string | null;
          birthYear?: string | null;
        };
        rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
        rentEndBy?: {
          __typename?: 'User';
          id: string;
          email?: string | null;
        } | null;
        review?: {
          __typename?: 'Review';
          id: string;
          freeText?: string | null;
          rating?: number | null;
          ratingQuestionAnswers: Array<{
            __typename?: 'RatingQuestionAnswer';
            id: string;
            rating?: number | null;
            question: {
              __typename?: 'Question';
              id: string;
              eng: string;
              swe?: string | null;
            };
          }>;
        } | null;
      }>;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
    } | null;
  } | null;
};

export type GQL_EditUserMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  editUser: GQL_EditUserInput;
}>;

export type GQL_EditUserMutation = {
  __typename?: 'Mutation';
  editUser: {
    __typename?: 'EditUserResponse';
    user?: {
      __typename?: 'User';
      id: string;
      accountCreated?: string | null;
      accountUpdated?: string | null;
      birthDate?: string | null;
      birthYear?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      emailVerified?: boolean | null;
      externalId?: string | null;
      firstName?: string | null;
      gender?: GQL_Gender | null;
      lastLogin?: string | null;
      lastName?: string | null;
      numberOfLogins?: number | null;
      phoneNumber?: string | null;
      privateEmail?: boolean | null;
      provider?: GQL_User_Provider | null;
      zipCode?: string | null;
      paymentInformation?: {
        __typename?: 'PaymentInformation';
        customerId: string;
        id: string;
        cards: Array<{
          __typename?: 'PaymentCard';
          brand: string;
          id: string;
          last4: string;
          status?: GQL_PaymentCardStatus | null;
        }>;
      } | null;
      permissions: Array<{
        __typename?: 'Permission';
        id: string;
        description?: string | null;
        type: GQL_PermissionType;
      }>;
      priceWeights: {
        __typename?: 'PriceWeight';
        id: string;
        name: string;
        start: number;
        firstHour: number;
        secondHour: number;
        thirdHour: number;
        fourthHour: number;
        fifthHour: number;
        sixthHour: number;
        penalty: number;
      };
      rentals: Array<{
        __typename?: 'Rental';
        id: string;
        maxRentTimeInMinutes: number;
        numberOfFailedOpenings: number;
        numberOfSuccessfulOpenings: number;
        productStatus?: GQL_ProductStatus | null;
        productStatusText?: string | null;
        aborted?: boolean | null;
        rentEnd?: string | null;
        rentStart: string;
        box: {
          __typename?: 'RentalBox';
          id: string;
          name: string;
          location: {
            __typename?: 'Location';
            id: string;
            description?: string | null;
            name?: string | null;
            coordinates?: {
              __typename?: 'Coordinates';
              lat: number;
              long: number;
            } | null;
          };
        };
        compartment: {
          __typename?: 'RentalCompartment';
          id: string;
          name: string;
        };
        paymentChargeInformation?:
          | {
              __typename?: 'PaymentChargeInformationFail';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              fail_status: GQL_PaymentChargeFailStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationFreeRent';
              id: string;
              amount: number;
              currency: string;
              type: GQL_PaymentChargeInformationType;
            }
          | {
              __typename?: 'PaymentChargeInformationSuccess';
              id: string;
              amount: number;
              clientSecret?: string | null;
              currency: string;
              success_status: GQL_PaymentChargeSuccessStatus;
              type: GQL_PaymentChargeInformationType;
            }
          | null;
        product: {
          __typename?: 'Product';
          id: string;
          description?: string | null;
          imageName?: string | null;
          name: string;
          rentalType: GQL_ProductRentalType;
          translationKey?: string | null;
          priceWeights: {
            __typename?: 'PriceWeight';
            id: string;
            name: string;
            start: number;
            firstHour: number;
            secondHour: number;
            thirdHour: number;
            fourthHour: number;
            fifthHour: number;
            sixthHour: number;
            penalty: number;
          };
        };
        priceStructure: {
          __typename?: 'PriceStructure';
          id: string;
          start: number;
          firstHour: number;
          secondHour: number;
          thirdHour: number;
          fourthHour: number;
          fifthHour: number;
          sixthHour: number;
          penalty: number;
        };
        rentBy: {
          __typename?: 'User';
          id: string;
          email?: string | null;
          gender?: GQL_Gender | null;
          firstName?: string | null;
          lastName?: string | null;
          phoneNumber?: string | null;
          birthYear?: string | null;
        };
        rentStartBy: { __typename?: 'User'; id: string; email?: string | null };
        rentEndBy?: {
          __typename?: 'User';
          id: string;
          email?: string | null;
        } | null;
        review?: {
          __typename?: 'Review';
          id: string;
          freeText?: string | null;
          rating?: number | null;
          ratingQuestionAnswers: Array<{
            __typename?: 'RatingQuestionAnswer';
            id: string;
            rating?: number | null;
            question: {
              __typename?: 'Question';
              id: string;
              eng: string;
              swe?: string | null;
            };
          }>;
        } | null;
      }>;
      roles: Array<{
        __typename?: 'Role';
        id: string;
        name: string;
        permissions: Array<{
          __typename?: 'Permission';
          id: string;
          description?: string | null;
          type: GQL_PermissionType;
        }>;
      }>;
    } | null;
  };
};

export const LocationFragmentDoc = gql`
  fragment location on Location {
    id
    coordinates {
      lat
      long
    }
    description
    name
  }
`;
export const ActivityAreaFragmentDoc = gql`
  fragment activityArea on ActivityArea {
    active
    activities
    areaInfos {
      count
      id
      type
    }
    id
    location {
      ...location
    }
    type
    verified
  }
  ${LocationFragmentDoc}
`;
export const BatteryReadingBoxFragmentDoc = gql`
  fragment batteryReadingBox on Box {
    id
    location {
      ...location
    }
    name
  }
  ${LocationFragmentDoc}
`;
export const BatteryReadingFragmentDoc = gql`
  fragment batteryReading on BatteryReading {
    id
    box {
      ...batteryReadingBox
    }
    userId
    voltage
    createdAt
  }
  ${BatteryReadingBoxFragmentDoc}
`;
export const PriceWeightFragmentDoc = gql`
  fragment priceWeight on PriceWeight {
    id
    name
    start
    firstHour
    secondHour
    thirdHour
    fourthHour
    fifthHour
    sixthHour
    penalty
  }
`;
export const ProductFragmentDoc = gql`
  fragment product on Product {
    id
    description
    imageName
    name
    priceWeights {
      ...priceWeight
    }
    rentalType
    translationKey
  }
  ${PriceWeightFragmentDoc}
`;
export const CompartmentProductFragmentDoc = gql`
  fragment compartmentProduct on CompartmentProduct {
    productStatus
    product {
      ...product
    }
  }
  ${ProductFragmentDoc}
`;
export const CompartmentFragmentDoc = gql`
  fragment compartment on Compartment {
    id
    locked
    circuitBoardPort
    name
    private
    product {
      ...compartmentProduct
    }
  }
  ${CompartmentProductFragmentDoc}
`;
export const PaymentChargeInformationFreeRentFragmentDoc = gql`
  fragment paymentChargeInformationFreeRent on PaymentChargeInformationFreeRent {
    id
    amount
    currency
    type
  }
`;
export const PaymentChargeInformationSuccessFragmentDoc = gql`
  fragment paymentChargeInformationSuccess on PaymentChargeInformationSuccess {
    id
    amount
    clientSecret
    currency
    success_status
    type
  }
`;
export const PaymentChargeInformationFailFragmentDoc = gql`
  fragment paymentChargeInformationFail on PaymentChargeInformationFail {
    id
    amount
    clientSecret
    currency
    fail_status
    type
  }
`;
export const PriceStructureFragmentDoc = gql`
  fragment priceStructure on PriceStructure {
    id
    start
    firstHour
    secondHour
    thirdHour
    fourthHour
    fifthHour
    sixthHour
    penalty
  }
`;
export const QuestionFragmentDoc = gql`
  fragment question on Question {
    id
    eng
    swe
  }
`;
export const RatingQuestionAnswerFragmentDoc = gql`
  fragment ratingQuestionAnswer on RatingQuestionAnswer {
    id
    question {
      ...question
    }
    rating
  }
  ${QuestionFragmentDoc}
`;
export const ReviewFragmentDoc = gql`
  fragment review on Review {
    id
    freeText
    rating
    ratingQuestionAnswers {
      ...ratingQuestionAnswer
    }
  }
  ${RatingQuestionAnswerFragmentDoc}
`;
export const RentalFragmentDoc = gql`
  fragment rental on Rental {
    id
    box {
      id
      location {
        ...location
      }
      name
    }
    compartment {
      id
      name
    }
    maxRentTimeInMinutes
    numberOfFailedOpenings
    numberOfSuccessfulOpenings
    paymentChargeInformation {
      ... on PaymentChargeInformationFreeRent {
        ...paymentChargeInformationFreeRent
      }
      ... on PaymentChargeInformationSuccess {
        ...paymentChargeInformationSuccess
      }
      ... on PaymentChargeInformationFail {
        ...paymentChargeInformationFail
      }
    }
    product {
      ...product
    }
    productStatus
    productStatusText
    priceStructure {
      ...priceStructure
    }
    aborted
    rentBy {
      id
      email
      gender
      firstName
      lastName
      phoneNumber
      birthYear
    }
    rentStartBy {
      id
      email
    }
    rentEndBy {
      id
      email
    }
    rentEnd
    rentStart
    review {
      ...review
    }
  }
  ${LocationFragmentDoc}
  ${PaymentChargeInformationFreeRentFragmentDoc}
  ${PaymentChargeInformationSuccessFragmentDoc}
  ${PaymentChargeInformationFailFragmentDoc}
  ${ProductFragmentDoc}
  ${PriceStructureFragmentDoc}
  ${ReviewFragmentDoc}
`;
export const CompartmentWithRentalsFragmentDoc = gql`
  fragment compartmentWithRentals on Compartment {
    ...compartment
    rentals {
      ...rental
    }
  }
  ${CompartmentFragmentDoc}
  ${RentalFragmentDoc}
`;
export const SponsorFragmentDoc = gql`
  fragment sponsor on Sponsor {
    id
    imageName
    name
    url
  }
`;
export const BoxFragmentDoc = gql`
  fragment box on Box {
    id
    active
    circuitBoardId
    color
    compartments {
      ...compartmentWithRentals
    }
    deprecated
    name
    initialActivationTime
    location {
      ...location
    }
    openStartTime
    openEndTime
    priceWeights {
      ...priceWeight
    }
    sponsor {
      ...sponsor
    }
  }
  ${CompartmentWithRentalsFragmentDoc}
  ${LocationFragmentDoc}
  ${PriceWeightFragmentDoc}
  ${SponsorFragmentDoc}
`;
export const CompartmentLiteFragmentDoc = gql`
  fragment compartmentLite on Compartment {
    id
    locked
    circuitBoardPort
    name
    private
    product {
      ...compartmentProduct
    }
    rentals {
      id
      rentEnd
    }
  }
  ${CompartmentProductFragmentDoc}
`;
export const BoxLiteFragmentDoc = gql`
  fragment boxLite on Box {
    id
    active
    circuitBoardId
    color
    compartments {
      ...compartmentLite
    }
    deprecated
    name
    initialActivationTime
    location {
      ...location
    }
    openStartTime
    openEndTime
    priceWeights {
      ...priceWeight
    }
    sponsor {
      ...sponsor
    }
  }
  ${CompartmentLiteFragmentDoc}
  ${LocationFragmentDoc}
  ${PriceWeightFragmentDoc}
  ${SponsorFragmentDoc}
`;
export const BoxWithoutRentalsFragmentDoc = gql`
  fragment boxWithoutRentals on Box {
    id
    active
    circuitBoardId
    color
    compartments {
      ...compartment
    }
    deprecated
    name
    initialActivationTime
    location {
      ...location
    }
    openStartTime
    openEndTime
    priceWeights {
      ...priceWeight
    }
    sponsor {
      ...sponsor
    }
  }
  ${CompartmentFragmentDoc}
  ${LocationFragmentDoc}
  ${PriceWeightFragmentDoc}
  ${SponsorFragmentDoc}
`;
export const PermissionFragmentDoc = gql`
  fragment permission on Permission {
    id
    description
    type
  }
`;
export const RoleFragmentDoc = gql`
  fragment role on Role {
    id
    name
    permissions {
      ...permission
    }
  }
  ${PermissionFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment user on User {
    id
    accountCreated
    accountUpdated
    birthDate
    birthYear
    city
    country
    email
    emailVerified
    externalId
    firstName
    gender
    lastLogin
    lastName
    numberOfLogins
    paymentInformation {
      cards {
        brand
        id
        last4
        status
      }
      customerId
      id
    }
    permissions {
      ...permission
    }
    phoneNumber
    priceWeights {
      ...priceWeight
    }
    privateEmail
    provider
    rentals {
      ...rental
    }
    roles {
      ...role
    }
    zipCode
  }
  ${PermissionFragmentDoc}
  ${PriceWeightFragmentDoc}
  ${RentalFragmentDoc}
  ${RoleFragmentDoc}
`;
export const FeedbackFragmentDoc = gql`
  fragment feedback on Feedback {
    id
    freeText
    date
    user {
      ...user
    }
  }
  ${UserFragmentDoc}
`;
export const OrganisationUserFragmentDoc = gql`
  fragment organisationUser on User {
    id
    email
    firstName
    lastName
    roles {
      ...role
    }
  }
  ${RoleFragmentDoc}
`;
export const OrganisationBoxFragmentDoc = gql`
  fragment organisationBox on Box {
    id
    location {
      ...location
    }
    name
  }
  ${LocationFragmentDoc}
`;
export const OrganisationFragmentDoc = gql`
  fragment organisation on Organisation {
    id
    city
    country
    phoneNumber
    zipCode
    name
    contactName
    contactEmail
    users {
      ...organisationUser
    }
    boxes {
      ...organisationBox
    }
  }
  ${OrganisationUserFragmentDoc}
  ${OrganisationBoxFragmentDoc}
`;
export const PendingUserFragmentDoc = gql`
  fragment pendingUser on PendingUser {
    id
    email
    roles {
      ...role
    }
    organisations {
      ...organisation
    }
  }
  ${RoleFragmentDoc}
  ${OrganisationFragmentDoc}
`;
export const UserLiteFragmentDoc = gql`
  fragment userLite on User {
    id
    accountCreated
    accountUpdated
    birthDate
    birthYear
    city
    country
    email
    emailVerified
    externalId
    firstName
    gender
    lastLogin
    lastName
    numberOfLogins
    paymentInformation {
      cards {
        brand
        id
        last4
        status
      }
      customerId
      id
    }
    phoneNumber
    priceWeight
    priceWeights {
      id
      name
    }
    privateEmail
    provider
    rentals {
      id
    }
    roles {
      id
      name
    }
    zipCode
  }
`;
export const AllActivityAreasDocument = gql`
  query allActivityAreas {
    activityArea {
      allActivityAreas {
        ...activityArea
      }
    }
  }
  ${ActivityAreaFragmentDoc}
`;

/**
 * __useAllActivityAreasQuery__
 *
 * To run a query within a React component, call `useAllActivityAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivityAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivityAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllActivityAreasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >(AllActivityAreasDocument, options);
}
export function useAllActivityAreasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >(AllActivityAreasDocument, options);
}
export function useAllActivityAreasSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >(AllActivityAreasDocument, options);
}
export type AllActivityAreasQueryHookResult = ReturnType<
  typeof useAllActivityAreasQuery
>;
export type AllActivityAreasLazyQueryHookResult = ReturnType<
  typeof useAllActivityAreasLazyQuery
>;
export type AllActivityAreasSuspenseQueryHookResult = ReturnType<
  typeof useAllActivityAreasSuspenseQuery
>;
export type AllActivityAreasQueryResult = Apollo.QueryResult<
  GQL_AllActivityAreasQuery,
  GQL_AllActivityAreasQueryVariables
>;
export const ActivityAreaByIdDocument = gql`
  query activityAreaById($id: ID!) {
    activityArea {
      activityAreaById(id: $id) {
        ...activityArea
      }
    }
  }
  ${ActivityAreaFragmentDoc}
`;

/**
 * __useActivityAreaByIdQuery__
 *
 * To run a query within a React component, call `useActivityAreaByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityAreaByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityAreaByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivityAreaByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  > &
    (
      | { variables: GQL_ActivityAreaByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  >(ActivityAreaByIdDocument, options);
}
export function useActivityAreaByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  >(ActivityAreaByIdDocument, options);
}
export function useActivityAreaByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  >(ActivityAreaByIdDocument, options);
}
export type ActivityAreaByIdQueryHookResult = ReturnType<
  typeof useActivityAreaByIdQuery
>;
export type ActivityAreaByIdLazyQueryHookResult = ReturnType<
  typeof useActivityAreaByIdLazyQuery
>;
export type ActivityAreaByIdSuspenseQueryHookResult = ReturnType<
  typeof useActivityAreaByIdSuspenseQuery
>;
export type ActivityAreaByIdQueryResult = Apollo.QueryResult<
  GQL_ActivityAreaByIdQuery,
  GQL_ActivityAreaByIdQueryVariables
>;
export const AllActivitiesDocument = gql`
  query allActivities {
    activityArea {
      allActivities {
        code
      }
    }
  }
`;

/**
 * __useAllActivitiesQuery__
 *
 * To run a query within a React component, call `useAllActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >(AllActivitiesDocument, options);
}
export function useAllActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >(AllActivitiesDocument, options);
}
export function useAllActivitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >(AllActivitiesDocument, options);
}
export type AllActivitiesQueryHookResult = ReturnType<
  typeof useAllActivitiesQuery
>;
export type AllActivitiesLazyQueryHookResult = ReturnType<
  typeof useAllActivitiesLazyQuery
>;
export type AllActivitiesSuspenseQueryHookResult = ReturnType<
  typeof useAllActivitiesSuspenseQuery
>;
export type AllActivitiesQueryResult = Apollo.QueryResult<
  GQL_AllActivitiesQuery,
  GQL_AllActivitiesQueryVariables
>;
export const AddActivityAreaDocument = gql`
  mutation addActivityArea($activityAreaInput: AddActivityAreaInput!) {
    addActivityArea(activityArea: $activityAreaInput) {
      activityArea {
        ...activityArea
      }
      type
    }
  }
  ${ActivityAreaFragmentDoc}
`;
export type GQL_AddActivityAreaMutationFn = Apollo.MutationFunction<
  GQL_AddActivityAreaMutation,
  GQL_AddActivityAreaMutationVariables
>;

/**
 * __useAddActivityAreaMutation__
 *
 * To run a mutation, you first call `useAddActivityAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddActivityAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addActivityAreaMutation, { data, loading, error }] = useAddActivityAreaMutation({
 *   variables: {
 *      activityAreaInput: // value for 'activityAreaInput'
 *   },
 * });
 */
export function useAddActivityAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddActivityAreaMutation,
    GQL_AddActivityAreaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_AddActivityAreaMutation,
    GQL_AddActivityAreaMutationVariables
  >(AddActivityAreaDocument, options);
}
export type AddActivityAreaMutationHookResult = ReturnType<
  typeof useAddActivityAreaMutation
>;
export type AddActivityAreaMutationResult =
  Apollo.MutationResult<GQL_AddActivityAreaMutation>;
export type AddActivityAreaMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddActivityAreaMutation,
  GQL_AddActivityAreaMutationVariables
>;
export const EditActivityAreaDocument = gql`
  mutation editActivityArea(
    $activityAreaId: ID!
    $activityAreaInput: EditActivityAreaInput!
  ) {
    editActivityArea(
      activityAreaId: $activityAreaId
      activityArea: $activityAreaInput
    ) {
      activityArea {
        ...activityArea
      }
      type
    }
  }
  ${ActivityAreaFragmentDoc}
`;
export type GQL_EditActivityAreaMutationFn = Apollo.MutationFunction<
  GQL_EditActivityAreaMutation,
  GQL_EditActivityAreaMutationVariables
>;

/**
 * __useEditActivityAreaMutation__
 *
 * To run a mutation, you first call `useEditActivityAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditActivityAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editActivityAreaMutation, { data, loading, error }] = useEditActivityAreaMutation({
 *   variables: {
 *      activityAreaId: // value for 'activityAreaId'
 *      activityAreaInput: // value for 'activityAreaInput'
 *   },
 * });
 */
export function useEditActivityAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditActivityAreaMutation,
    GQL_EditActivityAreaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditActivityAreaMutation,
    GQL_EditActivityAreaMutationVariables
  >(EditActivityAreaDocument, options);
}
export type EditActivityAreaMutationHookResult = ReturnType<
  typeof useEditActivityAreaMutation
>;
export type EditActivityAreaMutationResult =
  Apollo.MutationResult<GQL_EditActivityAreaMutation>;
export type EditActivityAreaMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditActivityAreaMutation,
  GQL_EditActivityAreaMutationVariables
>;
export const AllBatteryReadingsDocument = gql`
  query allBatteryReadings {
    battery {
      allBatteryReadings {
        ...batteryReading
      }
    }
  }
  ${BatteryReadingFragmentDoc}
`;

/**
 * __useAllBatteryReadingsQuery__
 *
 * To run a query within a React component, call `useAllBatteryReadingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBatteryReadingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBatteryReadingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBatteryReadingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >(AllBatteryReadingsDocument, options);
}
export function useAllBatteryReadingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >(AllBatteryReadingsDocument, options);
}
export function useAllBatteryReadingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >(AllBatteryReadingsDocument, options);
}
export type AllBatteryReadingsQueryHookResult = ReturnType<
  typeof useAllBatteryReadingsQuery
>;
export type AllBatteryReadingsLazyQueryHookResult = ReturnType<
  typeof useAllBatteryReadingsLazyQuery
>;
export type AllBatteryReadingsSuspenseQueryHookResult = ReturnType<
  typeof useAllBatteryReadingsSuspenseQuery
>;
export type AllBatteryReadingsQueryResult = Apollo.QueryResult<
  GQL_AllBatteryReadingsQuery,
  GQL_AllBatteryReadingsQueryVariables
>;
export const BatteryReadingsByBoxIdDocument = gql`
  query batteryReadingsByBoxId($id: ID!) {
    battery {
      batteryReadingsByBoxId(id: $id) {
        ...batteryReading
      }
    }
  }
  ${BatteryReadingFragmentDoc}
`;

/**
 * __useBatteryReadingsByBoxIdQuery__
 *
 * To run a query within a React component, call `useBatteryReadingsByBoxIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBatteryReadingsByBoxIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBatteryReadingsByBoxIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBatteryReadingsByBoxIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  > &
    (
      | { variables: GQL_BatteryReadingsByBoxIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  >(BatteryReadingsByBoxIdDocument, options);
}
export function useBatteryReadingsByBoxIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  >(BatteryReadingsByBoxIdDocument, options);
}
export function useBatteryReadingsByBoxIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  >(BatteryReadingsByBoxIdDocument, options);
}
export type BatteryReadingsByBoxIdQueryHookResult = ReturnType<
  typeof useBatteryReadingsByBoxIdQuery
>;
export type BatteryReadingsByBoxIdLazyQueryHookResult = ReturnType<
  typeof useBatteryReadingsByBoxIdLazyQuery
>;
export type BatteryReadingsByBoxIdSuspenseQueryHookResult = ReturnType<
  typeof useBatteryReadingsByBoxIdSuspenseQuery
>;
export type BatteryReadingsByBoxIdQueryResult = Apollo.QueryResult<
  GQL_BatteryReadingsByBoxIdQuery,
  GQL_BatteryReadingsByBoxIdQueryVariables
>;
export const AllBoxesDocument = gql`
  query allBoxes {
    box {
      allBoxes {
        ...box
      }
    }
  }
  ${BoxFragmentDoc}
`;

/**
 * __useAllBoxesQuery__
 *
 * To run a query within a React component, call `useAllBoxesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBoxesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesQuery,
    GQL_AllBoxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllBoxesQuery, GQL_AllBoxesQueryVariables>(
    AllBoxesDocument,
    options
  );
}
export function useAllBoxesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllBoxesQuery,
    GQL_AllBoxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_AllBoxesQuery, GQL_AllBoxesQueryVariables>(
    AllBoxesDocument,
    options
  );
}
export function useAllBoxesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllBoxesQuery,
    GQL_AllBoxesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GQL_AllBoxesQuery, GQL_AllBoxesQueryVariables>(
    AllBoxesDocument,
    options
  );
}
export type AllBoxesQueryHookResult = ReturnType<typeof useAllBoxesQuery>;
export type AllBoxesLazyQueryHookResult = ReturnType<
  typeof useAllBoxesLazyQuery
>;
export type AllBoxesSuspenseQueryHookResult = ReturnType<
  typeof useAllBoxesSuspenseQuery
>;
export type AllBoxesQueryResult = Apollo.QueryResult<
  GQL_AllBoxesQuery,
  GQL_AllBoxesQueryVariables
>;
export const AllBoxesLiteDocument = gql`
  query allBoxesLite {
    box {
      allBoxes {
        ...boxLite
      }
    }
  }
  ${BoxLiteFragmentDoc}
`;

/**
 * __useAllBoxesLiteQuery__
 *
 * To run a query within a React component, call `useAllBoxesLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxesLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxesLiteQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBoxesLiteQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesLiteQuery,
    GQL_AllBoxesLiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllBoxesLiteQuery, GQL_AllBoxesLiteQueryVariables>(
    AllBoxesLiteDocument,
    options
  );
}
export function useAllBoxesLiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllBoxesLiteQuery,
    GQL_AllBoxesLiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllBoxesLiteQuery,
    GQL_AllBoxesLiteQueryVariables
  >(AllBoxesLiteDocument, options);
}
export function useAllBoxesLiteSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllBoxesLiteQuery,
    GQL_AllBoxesLiteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllBoxesLiteQuery,
    GQL_AllBoxesLiteQueryVariables
  >(AllBoxesLiteDocument, options);
}
export type AllBoxesLiteQueryHookResult = ReturnType<
  typeof useAllBoxesLiteQuery
>;
export type AllBoxesLiteLazyQueryHookResult = ReturnType<
  typeof useAllBoxesLiteLazyQuery
>;
export type AllBoxesLiteSuspenseQueryHookResult = ReturnType<
  typeof useAllBoxesLiteSuspenseQuery
>;
export type AllBoxesLiteQueryResult = Apollo.QueryResult<
  GQL_AllBoxesLiteQuery,
  GQL_AllBoxesLiteQueryVariables
>;
export const AllBoxesWithoutRentalsDocument = gql`
  query allBoxesWithoutRentals {
    box {
      allBoxes {
        ...boxWithoutRentals
      }
    }
  }
  ${BoxWithoutRentalsFragmentDoc}
`;

/**
 * __useAllBoxesWithoutRentalsQuery__
 *
 * To run a query within a React component, call `useAllBoxesWithoutRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxesWithoutRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxesWithoutRentalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBoxesWithoutRentalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesWithoutRentalsQuery,
    GQL_AllBoxesWithoutRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllBoxesWithoutRentalsQuery,
    GQL_AllBoxesWithoutRentalsQueryVariables
  >(AllBoxesWithoutRentalsDocument, options);
}
export function useAllBoxesWithoutRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllBoxesWithoutRentalsQuery,
    GQL_AllBoxesWithoutRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllBoxesWithoutRentalsQuery,
    GQL_AllBoxesWithoutRentalsQueryVariables
  >(AllBoxesWithoutRentalsDocument, options);
}
export function useAllBoxesWithoutRentalsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllBoxesWithoutRentalsQuery,
    GQL_AllBoxesWithoutRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllBoxesWithoutRentalsQuery,
    GQL_AllBoxesWithoutRentalsQueryVariables
  >(AllBoxesWithoutRentalsDocument, options);
}
export type AllBoxesWithoutRentalsQueryHookResult = ReturnType<
  typeof useAllBoxesWithoutRentalsQuery
>;
export type AllBoxesWithoutRentalsLazyQueryHookResult = ReturnType<
  typeof useAllBoxesWithoutRentalsLazyQuery
>;
export type AllBoxesWithoutRentalsSuspenseQueryHookResult = ReturnType<
  typeof useAllBoxesWithoutRentalsSuspenseQuery
>;
export type AllBoxesWithoutRentalsQueryResult = Apollo.QueryResult<
  GQL_AllBoxesWithoutRentalsQuery,
  GQL_AllBoxesWithoutRentalsQueryVariables
>;
export const AllBoxesForSelectDocument = gql`
  query allBoxesForSelect {
    box {
      allBoxes {
        id
        name
      }
    }
  }
`;

/**
 * __useAllBoxesForSelectQuery__
 *
 * To run a query within a React component, call `useAllBoxesForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBoxesForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBoxesForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllBoxesForSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >(AllBoxesForSelectDocument, options);
}
export function useAllBoxesForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >(AllBoxesForSelectDocument, options);
}
export function useAllBoxesForSelectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >(AllBoxesForSelectDocument, options);
}
export type AllBoxesForSelectQueryHookResult = ReturnType<
  typeof useAllBoxesForSelectQuery
>;
export type AllBoxesForSelectLazyQueryHookResult = ReturnType<
  typeof useAllBoxesForSelectLazyQuery
>;
export type AllBoxesForSelectSuspenseQueryHookResult = ReturnType<
  typeof useAllBoxesForSelectSuspenseQuery
>;
export type AllBoxesForSelectQueryResult = Apollo.QueryResult<
  GQL_AllBoxesForSelectQuery,
  GQL_AllBoxesForSelectQueryVariables
>;
export const BoxByIdDocument = gql`
  query boxById($id: ID!) {
    box {
      boxById(id: $id) {
        ...box
      }
    }
  }
  ${BoxFragmentDoc}
`;

/**
 * __useBoxByIdQuery__
 *
 * To run a query within a React component, call `useBoxByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoxByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoxByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoxByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_BoxByIdQuery,
    GQL_BoxByIdQueryVariables
  > &
    (
      | { variables: GQL_BoxByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_BoxByIdQuery, GQL_BoxByIdQueryVariables>(
    BoxByIdDocument,
    options
  );
}
export function useBoxByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_BoxByIdQuery,
    GQL_BoxByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_BoxByIdQuery, GQL_BoxByIdQueryVariables>(
    BoxByIdDocument,
    options
  );
}
export function useBoxByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_BoxByIdQuery,
    GQL_BoxByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GQL_BoxByIdQuery, GQL_BoxByIdQueryVariables>(
    BoxByIdDocument,
    options
  );
}
export type BoxByIdQueryHookResult = ReturnType<typeof useBoxByIdQuery>;
export type BoxByIdLazyQueryHookResult = ReturnType<typeof useBoxByIdLazyQuery>;
export type BoxByIdSuspenseQueryHookResult = ReturnType<
  typeof useBoxByIdSuspenseQuery
>;
export type BoxByIdQueryResult = Apollo.QueryResult<
  GQL_BoxByIdQuery,
  GQL_BoxByIdQueryVariables
>;
export const AddBoxDocument = gql`
  mutation addBox($boxInput: AddBoxInput!) {
    addBox(box: $boxInput) {
      box {
        ...box
      }
      type
    }
  }
  ${BoxFragmentDoc}
`;
export type GQL_AddBoxMutationFn = Apollo.MutationFunction<
  GQL_AddBoxMutation,
  GQL_AddBoxMutationVariables
>;

/**
 * __useAddBoxMutation__
 *
 * To run a mutation, you first call `useAddBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBoxMutation, { data, loading, error }] = useAddBoxMutation({
 *   variables: {
 *      boxInput: // value for 'boxInput'
 *   },
 * });
 */
export function useAddBoxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddBoxMutation,
    GQL_AddBoxMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQL_AddBoxMutation, GQL_AddBoxMutationVariables>(
    AddBoxDocument,
    options
  );
}
export type AddBoxMutationHookResult = ReturnType<typeof useAddBoxMutation>;
export type AddBoxMutationResult = Apollo.MutationResult<GQL_AddBoxMutation>;
export type AddBoxMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddBoxMutation,
  GQL_AddBoxMutationVariables
>;
export const EditBoxDocument = gql`
  mutation editBox($boxId: ID!, $boxInput: EditBoxInput!) {
    editBox(boxId: $boxId, box: $boxInput) {
      box {
        ...box
      }
      type
    }
  }
  ${BoxFragmentDoc}
`;
export type GQL_EditBoxMutationFn = Apollo.MutationFunction<
  GQL_EditBoxMutation,
  GQL_EditBoxMutationVariables
>;

/**
 * __useEditBoxMutation__
 *
 * To run a mutation, you first call `useEditBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBoxMutation, { data, loading, error }] = useEditBoxMutation({
 *   variables: {
 *      boxId: // value for 'boxId'
 *      boxInput: // value for 'boxInput'
 *   },
 * });
 */
export function useEditBoxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditBoxMutation,
    GQL_EditBoxMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQL_EditBoxMutation, GQL_EditBoxMutationVariables>(
    EditBoxDocument,
    options
  );
}
export type EditBoxMutationHookResult = ReturnType<typeof useEditBoxMutation>;
export type EditBoxMutationResult = Apollo.MutationResult<GQL_EditBoxMutation>;
export type EditBoxMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditBoxMutation,
  GQL_EditBoxMutationVariables
>;
export const DeleteBoxDocument = gql`
  mutation deleteBox($boxId: ID!) {
    deleteBox(boxId: $boxId) {
      type
    }
  }
`;
export type GQL_DeleteBoxMutationFn = Apollo.MutationFunction<
  GQL_DeleteBoxMutation,
  GQL_DeleteBoxMutationVariables
>;

/**
 * __useDeleteBoxMutation__
 *
 * To run a mutation, you first call `useDeleteBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBoxMutation, { data, loading, error }] = useDeleteBoxMutation({
 *   variables: {
 *      boxId: // value for 'boxId'
 *   },
 * });
 */
export function useDeleteBoxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_DeleteBoxMutation,
    GQL_DeleteBoxMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_DeleteBoxMutation,
    GQL_DeleteBoxMutationVariables
  >(DeleteBoxDocument, options);
}
export type DeleteBoxMutationHookResult = ReturnType<
  typeof useDeleteBoxMutation
>;
export type DeleteBoxMutationResult =
  Apollo.MutationResult<GQL_DeleteBoxMutation>;
export type DeleteBoxMutationOptions = Apollo.BaseMutationOptions<
  GQL_DeleteBoxMutation,
  GQL_DeleteBoxMutationVariables
>;
export const CompartmentByIdDocument = gql`
  query compartmentById($id: ID!) {
    compartment {
      compartmentById(id: $id) {
        ...compartmentWithRentals
      }
    }
  }
  ${CompartmentWithRentalsFragmentDoc}
`;

/**
 * __useCompartmentByIdQuery__
 *
 * To run a query within a React component, call `useCompartmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompartmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompartmentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompartmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  > &
    (
      | { variables: GQL_CompartmentByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  >(CompartmentByIdDocument, options);
}
export function useCompartmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  >(CompartmentByIdDocument, options);
}
export function useCompartmentByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  >(CompartmentByIdDocument, options);
}
export type CompartmentByIdQueryHookResult = ReturnType<
  typeof useCompartmentByIdQuery
>;
export type CompartmentByIdLazyQueryHookResult = ReturnType<
  typeof useCompartmentByIdLazyQuery
>;
export type CompartmentByIdSuspenseQueryHookResult = ReturnType<
  typeof useCompartmentByIdSuspenseQuery
>;
export type CompartmentByIdQueryResult = Apollo.QueryResult<
  GQL_CompartmentByIdQuery,
  GQL_CompartmentByIdQueryVariables
>;
export const EditCompartmentDocument = gql`
  mutation editCompartment(
    $compartmentId: ID!
    $compartment: EditCompartmentInput!
  ) {
    editCompartment(compartmentId: $compartmentId, compartment: $compartment) {
      compartment {
        ...compartment
      }
    }
  }
  ${CompartmentFragmentDoc}
`;
export type GQL_EditCompartmentMutationFn = Apollo.MutationFunction<
  GQL_EditCompartmentMutation,
  GQL_EditCompartmentMutationVariables
>;

/**
 * __useEditCompartmentMutation__
 *
 * To run a mutation, you first call `useEditCompartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompartmentMutation, { data, loading, error }] = useEditCompartmentMutation({
 *   variables: {
 *      compartmentId: // value for 'compartmentId'
 *      compartment: // value for 'compartment'
 *   },
 * });
 */
export function useEditCompartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditCompartmentMutation,
    GQL_EditCompartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditCompartmentMutation,
    GQL_EditCompartmentMutationVariables
  >(EditCompartmentDocument, options);
}
export type EditCompartmentMutationHookResult = ReturnType<
  typeof useEditCompartmentMutation
>;
export type EditCompartmentMutationResult =
  Apollo.MutationResult<GQL_EditCompartmentMutation>;
export type EditCompartmentMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditCompartmentMutation,
  GQL_EditCompartmentMutationVariables
>;
export const EditCompartmentsDocument = gql`
  mutation editCompartments($compartments: [EditCompartmentsInput!]!) {
    editCompartments(compartments: $compartments) {
      compartments {
        ...compartment
      }
    }
  }
  ${CompartmentFragmentDoc}
`;
export type GQL_EditCompartmentsMutationFn = Apollo.MutationFunction<
  GQL_EditCompartmentsMutation,
  GQL_EditCompartmentsMutationVariables
>;

/**
 * __useEditCompartmentsMutation__
 *
 * To run a mutation, you first call `useEditCompartmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCompartmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCompartmentsMutation, { data, loading, error }] = useEditCompartmentsMutation({
 *   variables: {
 *      compartments: // value for 'compartments'
 *   },
 * });
 */
export function useEditCompartmentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditCompartmentsMutation,
    GQL_EditCompartmentsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditCompartmentsMutation,
    GQL_EditCompartmentsMutationVariables
  >(EditCompartmentsDocument, options);
}
export type EditCompartmentsMutationHookResult = ReturnType<
  typeof useEditCompartmentsMutation
>;
export type EditCompartmentsMutationResult =
  Apollo.MutationResult<GQL_EditCompartmentsMutation>;
export type EditCompartmentsMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditCompartmentsMutation,
  GQL_EditCompartmentsMutationVariables
>;
export const LockCompartmentDocument = gql`
  mutation lockCompartment($compartmentId: ID!) {
    lockCompartment(compartmentId: $compartmentId) {
      compartment {
        ...compartment
      }
    }
  }
  ${CompartmentFragmentDoc}
`;
export type GQL_LockCompartmentMutationFn = Apollo.MutationFunction<
  GQL_LockCompartmentMutation,
  GQL_LockCompartmentMutationVariables
>;

/**
 * __useLockCompartmentMutation__
 *
 * To run a mutation, you first call `useLockCompartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockCompartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockCompartmentMutation, { data, loading, error }] = useLockCompartmentMutation({
 *   variables: {
 *      compartmentId: // value for 'compartmentId'
 *   },
 * });
 */
export function useLockCompartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_LockCompartmentMutation,
    GQL_LockCompartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_LockCompartmentMutation,
    GQL_LockCompartmentMutationVariables
  >(LockCompartmentDocument, options);
}
export type LockCompartmentMutationHookResult = ReturnType<
  typeof useLockCompartmentMutation
>;
export type LockCompartmentMutationResult =
  Apollo.MutationResult<GQL_LockCompartmentMutation>;
export type LockCompartmentMutationOptions = Apollo.BaseMutationOptions<
  GQL_LockCompartmentMutation,
  GQL_LockCompartmentMutationVariables
>;
export const UnlockCompartmentDocument = gql`
  mutation unlockCompartment($compartmentId: ID!) {
    unlockCompartment(compartmentId: $compartmentId) {
      compartment {
        ...compartment
      }
    }
  }
  ${CompartmentFragmentDoc}
`;
export type GQL_UnlockCompartmentMutationFn = Apollo.MutationFunction<
  GQL_UnlockCompartmentMutation,
  GQL_UnlockCompartmentMutationVariables
>;

/**
 * __useUnlockCompartmentMutation__
 *
 * To run a mutation, you first call `useUnlockCompartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockCompartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockCompartmentMutation, { data, loading, error }] = useUnlockCompartmentMutation({
 *   variables: {
 *      compartmentId: // value for 'compartmentId'
 *   },
 * });
 */
export function useUnlockCompartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_UnlockCompartmentMutation,
    GQL_UnlockCompartmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_UnlockCompartmentMutation,
    GQL_UnlockCompartmentMutationVariables
  >(UnlockCompartmentDocument, options);
}
export type UnlockCompartmentMutationHookResult = ReturnType<
  typeof useUnlockCompartmentMutation
>;
export type UnlockCompartmentMutationResult =
  Apollo.MutationResult<GQL_UnlockCompartmentMutation>;
export type UnlockCompartmentMutationOptions = Apollo.BaseMutationOptions<
  GQL_UnlockCompartmentMutation,
  GQL_UnlockCompartmentMutationVariables
>;
export const AllFeedbacksDocument = gql`
  query allFeedbacks {
    feedback {
      allFeedbacks {
        ...feedback
      }
    }
  }
  ${FeedbackFragmentDoc}
`;

/**
 * __useAllFeedbacksQuery__
 *
 * To run a query within a React component, call `useAllFeedbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeedbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeedbacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeedbacksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllFeedbacksQuery,
    GQL_AllFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllFeedbacksQuery, GQL_AllFeedbacksQueryVariables>(
    AllFeedbacksDocument,
    options
  );
}
export function useAllFeedbacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllFeedbacksQuery,
    GQL_AllFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllFeedbacksQuery,
    GQL_AllFeedbacksQueryVariables
  >(AllFeedbacksDocument, options);
}
export function useAllFeedbacksSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllFeedbacksQuery,
    GQL_AllFeedbacksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllFeedbacksQuery,
    GQL_AllFeedbacksQueryVariables
  >(AllFeedbacksDocument, options);
}
export type AllFeedbacksQueryHookResult = ReturnType<
  typeof useAllFeedbacksQuery
>;
export type AllFeedbacksLazyQueryHookResult = ReturnType<
  typeof useAllFeedbacksLazyQuery
>;
export type AllFeedbacksSuspenseQueryHookResult = ReturnType<
  typeof useAllFeedbacksSuspenseQuery
>;
export type AllFeedbacksQueryResult = Apollo.QueryResult<
  GQL_AllFeedbacksQuery,
  GQL_AllFeedbacksQueryVariables
>;
export const AllOrganisationsDocument = gql`
  query allOrganisations {
    organisation {
      allOrganisations {
        ...organisation
      }
    }
  }
  ${OrganisationFragmentDoc}
`;

/**
 * __useAllOrganisationsQuery__
 *
 * To run a query within a React component, call `useAllOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOrganisationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >(AllOrganisationsDocument, options);
}
export function useAllOrganisationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >(AllOrganisationsDocument, options);
}
export function useAllOrganisationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >(AllOrganisationsDocument, options);
}
export type AllOrganisationsQueryHookResult = ReturnType<
  typeof useAllOrganisationsQuery
>;
export type AllOrganisationsLazyQueryHookResult = ReturnType<
  typeof useAllOrganisationsLazyQuery
>;
export type AllOrganisationsSuspenseQueryHookResult = ReturnType<
  typeof useAllOrganisationsSuspenseQuery
>;
export type AllOrganisationsQueryResult = Apollo.QueryResult<
  GQL_AllOrganisationsQuery,
  GQL_AllOrganisationsQueryVariables
>;
export const OrganisationByIdDocument = gql`
  query organisationById($id: ID!) {
    organisation {
      organisationById(id: $id) {
        ...organisation
      }
    }
  }
  ${OrganisationFragmentDoc}
`;

/**
 * __useOrganisationByIdQuery__
 *
 * To run a query within a React component, call `useOrganisationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  > &
    (
      | { variables: GQL_OrganisationByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  >(OrganisationByIdDocument, options);
}
export function useOrganisationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  >(OrganisationByIdDocument, options);
}
export function useOrganisationByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  >(OrganisationByIdDocument, options);
}
export type OrganisationByIdQueryHookResult = ReturnType<
  typeof useOrganisationByIdQuery
>;
export type OrganisationByIdLazyQueryHookResult = ReturnType<
  typeof useOrganisationByIdLazyQuery
>;
export type OrganisationByIdSuspenseQueryHookResult = ReturnType<
  typeof useOrganisationByIdSuspenseQuery
>;
export type OrganisationByIdQueryResult = Apollo.QueryResult<
  GQL_OrganisationByIdQuery,
  GQL_OrganisationByIdQueryVariables
>;
export const AddOrganisationDocument = gql`
  mutation addOrganisation($organisation: AddOrganisationInput!) {
    addOrganisation(organisation: $organisation) {
      organisation {
        ...organisation
      }
    }
  }
  ${OrganisationFragmentDoc}
`;
export type GQL_AddOrganisationMutationFn = Apollo.MutationFunction<
  GQL_AddOrganisationMutation,
  GQL_AddOrganisationMutationVariables
>;

/**
 * __useAddOrganisationMutation__
 *
 * To run a mutation, you first call `useAddOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrganisationMutation, { data, loading, error }] = useAddOrganisationMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useAddOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddOrganisationMutation,
    GQL_AddOrganisationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_AddOrganisationMutation,
    GQL_AddOrganisationMutationVariables
  >(AddOrganisationDocument, options);
}
export type AddOrganisationMutationHookResult = ReturnType<
  typeof useAddOrganisationMutation
>;
export type AddOrganisationMutationResult =
  Apollo.MutationResult<GQL_AddOrganisationMutation>;
export type AddOrganisationMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddOrganisationMutation,
  GQL_AddOrganisationMutationVariables
>;
export const EditOrganisationDocument = gql`
  mutation editOrganisation(
    $organisationId: ID!
    $editOrganisation: EditOrganisationInput!
  ) {
    editOrganisation(
      organisationId: $organisationId
      organisation: $editOrganisation
    ) {
      organisation {
        ...organisation
      }
    }
  }
  ${OrganisationFragmentDoc}
`;
export type GQL_EditOrganisationMutationFn = Apollo.MutationFunction<
  GQL_EditOrganisationMutation,
  GQL_EditOrganisationMutationVariables
>;

/**
 * __useEditOrganisationMutation__
 *
 * To run a mutation, you first call `useEditOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrganisationMutation, { data, loading, error }] = useEditOrganisationMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      editOrganisation: // value for 'editOrganisation'
 *   },
 * });
 */
export function useEditOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditOrganisationMutation,
    GQL_EditOrganisationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditOrganisationMutation,
    GQL_EditOrganisationMutationVariables
  >(EditOrganisationDocument, options);
}
export type EditOrganisationMutationHookResult = ReturnType<
  typeof useEditOrganisationMutation
>;
export type EditOrganisationMutationResult =
  Apollo.MutationResult<GQL_EditOrganisationMutation>;
export type EditOrganisationMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditOrganisationMutation,
  GQL_EditOrganisationMutationVariables
>;
export const AllPendingUsersDocument = gql`
  query allPendingUsers {
    pendingUser {
      allPendingUsers {
        ...pendingUser
      }
    }
  }
  ${PendingUserFragmentDoc}
`;

/**
 * __useAllPendingUsersQuery__
 *
 * To run a query within a React component, call `useAllPendingUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPendingUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPendingUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPendingUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >(AllPendingUsersDocument, options);
}
export function useAllPendingUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >(AllPendingUsersDocument, options);
}
export function useAllPendingUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >(AllPendingUsersDocument, options);
}
export type AllPendingUsersQueryHookResult = ReturnType<
  typeof useAllPendingUsersQuery
>;
export type AllPendingUsersLazyQueryHookResult = ReturnType<
  typeof useAllPendingUsersLazyQuery
>;
export type AllPendingUsersSuspenseQueryHookResult = ReturnType<
  typeof useAllPendingUsersSuspenseQuery
>;
export type AllPendingUsersQueryResult = Apollo.QueryResult<
  GQL_AllPendingUsersQuery,
  GQL_AllPendingUsersQueryVariables
>;
export const PendingUserByIdDocument = gql`
  query pendingUserById($id: ID!) {
    pendingUser {
      pendingUserById(id: $id) {
        ...pendingUser
      }
    }
  }
  ${PendingUserFragmentDoc}
`;

/**
 * __usePendingUserByIdQuery__
 *
 * To run a query within a React component, call `usePendingUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePendingUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  > &
    (
      | { variables: GQL_PendingUserByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  >(PendingUserByIdDocument, options);
}
export function usePendingUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  >(PendingUserByIdDocument, options);
}
export function usePendingUserByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  >(PendingUserByIdDocument, options);
}
export type PendingUserByIdQueryHookResult = ReturnType<
  typeof usePendingUserByIdQuery
>;
export type PendingUserByIdLazyQueryHookResult = ReturnType<
  typeof usePendingUserByIdLazyQuery
>;
export type PendingUserByIdSuspenseQueryHookResult = ReturnType<
  typeof usePendingUserByIdSuspenseQuery
>;
export type PendingUserByIdQueryResult = Apollo.QueryResult<
  GQL_PendingUserByIdQuery,
  GQL_PendingUserByIdQueryVariables
>;
export const AddPendingUserDocument = gql`
  mutation addPendingUser($pendingUser: AddPendingUsersInput!) {
    addPendingUsers(pendingUsers: $pendingUser) {
      pendingUser {
        ...pendingUser
      }
    }
  }
  ${PendingUserFragmentDoc}
`;
export type GQL_AddPendingUserMutationFn = Apollo.MutationFunction<
  GQL_AddPendingUserMutation,
  GQL_AddPendingUserMutationVariables
>;

/**
 * __useAddPendingUserMutation__
 *
 * To run a mutation, you first call `useAddPendingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPendingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPendingUserMutation, { data, loading, error }] = useAddPendingUserMutation({
 *   variables: {
 *      pendingUser: // value for 'pendingUser'
 *   },
 * });
 */
export function useAddPendingUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddPendingUserMutation,
    GQL_AddPendingUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_AddPendingUserMutation,
    GQL_AddPendingUserMutationVariables
  >(AddPendingUserDocument, options);
}
export type AddPendingUserMutationHookResult = ReturnType<
  typeof useAddPendingUserMutation
>;
export type AddPendingUserMutationResult =
  Apollo.MutationResult<GQL_AddPendingUserMutation>;
export type AddPendingUserMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddPendingUserMutation,
  GQL_AddPendingUserMutationVariables
>;
export const EditPendingUserDocument = gql`
  mutation editPendingUser(
    $pendingUserId: ID!
    $pendingUser: EditPendingUserInput!
  ) {
    editPendingUser(pendingUserId: $pendingUserId, pendingUser: $pendingUser) {
      pendingUser {
        ...pendingUser
      }
    }
  }
  ${PendingUserFragmentDoc}
`;
export type GQL_EditPendingUserMutationFn = Apollo.MutationFunction<
  GQL_EditPendingUserMutation,
  GQL_EditPendingUserMutationVariables
>;

/**
 * __useEditPendingUserMutation__
 *
 * To run a mutation, you first call `useEditPendingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPendingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPendingUserMutation, { data, loading, error }] = useEditPendingUserMutation({
 *   variables: {
 *      pendingUserId: // value for 'pendingUserId'
 *      pendingUser: // value for 'pendingUser'
 *   },
 * });
 */
export function useEditPendingUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditPendingUserMutation,
    GQL_EditPendingUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditPendingUserMutation,
    GQL_EditPendingUserMutationVariables
  >(EditPendingUserDocument, options);
}
export type EditPendingUserMutationHookResult = ReturnType<
  typeof useEditPendingUserMutation
>;
export type EditPendingUserMutationResult =
  Apollo.MutationResult<GQL_EditPendingUserMutation>;
export type EditPendingUserMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditPendingUserMutation,
  GQL_EditPendingUserMutationVariables
>;
export const AllPermissionsDocument = gql`
  query allPermissions {
    permission {
      allPermissions {
        ...permission
      }
    }
  }
  ${PermissionFragmentDoc}
`;

/**
 * __useAllPermissionsQuery__
 *
 * To run a query within a React component, call `useAllPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllPermissionsQuery,
    GQL_AllPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllPermissionsQuery,
    GQL_AllPermissionsQueryVariables
  >(AllPermissionsDocument, options);
}
export function useAllPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllPermissionsQuery,
    GQL_AllPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllPermissionsQuery,
    GQL_AllPermissionsQueryVariables
  >(AllPermissionsDocument, options);
}
export function useAllPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllPermissionsQuery,
    GQL_AllPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllPermissionsQuery,
    GQL_AllPermissionsQueryVariables
  >(AllPermissionsDocument, options);
}
export type AllPermissionsQueryHookResult = ReturnType<
  typeof useAllPermissionsQuery
>;
export type AllPermissionsLazyQueryHookResult = ReturnType<
  typeof useAllPermissionsLazyQuery
>;
export type AllPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useAllPermissionsSuspenseQuery
>;
export type AllPermissionsQueryResult = Apollo.QueryResult<
  GQL_AllPermissionsQuery,
  GQL_AllPermissionsQueryVariables
>;
export const AllPriceWeightsDocument = gql`
  query allPriceWeights {
    payment {
      allPriceWeights {
        ...priceWeight
      }
    }
  }
  ${PriceWeightFragmentDoc}
`;

/**
 * __useAllPriceWeightsQuery__
 *
 * To run a query within a React component, call `useAllPriceWeightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPriceWeightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPriceWeightsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllPriceWeightsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >(AllPriceWeightsDocument, options);
}
export function useAllPriceWeightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >(AllPriceWeightsDocument, options);
}
export function useAllPriceWeightsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >(AllPriceWeightsDocument, options);
}
export type AllPriceWeightsQueryHookResult = ReturnType<
  typeof useAllPriceWeightsQuery
>;
export type AllPriceWeightsLazyQueryHookResult = ReturnType<
  typeof useAllPriceWeightsLazyQuery
>;
export type AllPriceWeightsSuspenseQueryHookResult = ReturnType<
  typeof useAllPriceWeightsSuspenseQuery
>;
export type AllPriceWeightsQueryResult = Apollo.QueryResult<
  GQL_AllPriceWeightsQuery,
  GQL_AllPriceWeightsQueryVariables
>;
export const PriceWeightByIdDocument = gql`
  query priceWeightById($id: ID!) {
    payment {
      priceWeightById(id: $id) {
        ...priceWeight
      }
    }
  }
  ${PriceWeightFragmentDoc}
`;

/**
 * __usePriceWeightByIdQuery__
 *
 * To run a query within a React component, call `usePriceWeightByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceWeightByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceWeightByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePriceWeightByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_PriceWeightByIdQuery,
    GQL_PriceWeightByIdQueryVariables
  > &
    (
      | { variables: GQL_PriceWeightByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_PriceWeightByIdQuery,
    GQL_PriceWeightByIdQueryVariables
  >(PriceWeightByIdDocument, options);
}
export function usePriceWeightByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_PriceWeightByIdQuery,
    GQL_PriceWeightByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_PriceWeightByIdQuery,
    GQL_PriceWeightByIdQueryVariables
  >(PriceWeightByIdDocument, options);
}
export function usePriceWeightByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_PriceWeightByIdQuery,
    GQL_PriceWeightByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_PriceWeightByIdQuery,
    GQL_PriceWeightByIdQueryVariables
  >(PriceWeightByIdDocument, options);
}
export type PriceWeightByIdQueryHookResult = ReturnType<
  typeof usePriceWeightByIdQuery
>;
export type PriceWeightByIdLazyQueryHookResult = ReturnType<
  typeof usePriceWeightByIdLazyQuery
>;
export type PriceWeightByIdSuspenseQueryHookResult = ReturnType<
  typeof usePriceWeightByIdSuspenseQuery
>;
export type PriceWeightByIdQueryResult = Apollo.QueryResult<
  GQL_PriceWeightByIdQuery,
  GQL_PriceWeightByIdQueryVariables
>;
export const PriceStructureDocument = gql`
  query priceStructure {
    payment {
      priceStructure {
        ...priceStructure
      }
    }
  }
  ${PriceStructureFragmentDoc}
`;

/**
 * __usePriceStructureQuery__
 *
 * To run a query within a React component, call `usePriceStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `usePriceStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePriceStructureQuery({
 *   variables: {
 *   },
 * });
 */
export function usePriceStructureQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >(PriceStructureDocument, options);
}
export function usePriceStructureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >(PriceStructureDocument, options);
}
export function usePriceStructureSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >(PriceStructureDocument, options);
}
export type PriceStructureQueryHookResult = ReturnType<
  typeof usePriceStructureQuery
>;
export type PriceStructureLazyQueryHookResult = ReturnType<
  typeof usePriceStructureLazyQuery
>;
export type PriceStructureSuspenseQueryHookResult = ReturnType<
  typeof usePriceStructureSuspenseQuery
>;
export type PriceStructureQueryResult = Apollo.QueryResult<
  GQL_PriceStructureQuery,
  GQL_PriceStructureQueryVariables
>;
export const EditPriceStructureDocument = gql`
  mutation editPriceStructure(
    $priceStructureId: ID!
    $editPriceStructureInput: EditPriceStructureInput!
  ) {
    editPriceStructure(
      priceStructureId: $priceStructureId
      editPriceStructureInput: $editPriceStructureInput
    ) {
      priceStructure {
        ...priceStructure
      }
    }
  }
  ${PriceStructureFragmentDoc}
`;
export type GQL_EditPriceStructureMutationFn = Apollo.MutationFunction<
  GQL_EditPriceStructureMutation,
  GQL_EditPriceStructureMutationVariables
>;

/**
 * __useEditPriceStructureMutation__
 *
 * To run a mutation, you first call `useEditPriceStructureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPriceStructureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPriceStructureMutation, { data, loading, error }] = useEditPriceStructureMutation({
 *   variables: {
 *      priceStructureId: // value for 'priceStructureId'
 *      editPriceStructureInput: // value for 'editPriceStructureInput'
 *   },
 * });
 */
export function useEditPriceStructureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditPriceStructureMutation,
    GQL_EditPriceStructureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditPriceStructureMutation,
    GQL_EditPriceStructureMutationVariables
  >(EditPriceStructureDocument, options);
}
export type EditPriceStructureMutationHookResult = ReturnType<
  typeof useEditPriceStructureMutation
>;
export type EditPriceStructureMutationResult =
  Apollo.MutationResult<GQL_EditPriceStructureMutation>;
export type EditPriceStructureMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditPriceStructureMutation,
  GQL_EditPriceStructureMutationVariables
>;
export const AddPriceWeightDocument = gql`
  mutation addPriceWeight($priceWeight: AddPriceWeightInput!) {
    addPriceWeight(priceWeight: $priceWeight) {
      priceWeight {
        ...priceWeight
      }
    }
  }
  ${PriceWeightFragmentDoc}
`;
export type GQL_AddPriceWeightMutationFn = Apollo.MutationFunction<
  GQL_AddPriceWeightMutation,
  GQL_AddPriceWeightMutationVariables
>;

/**
 * __useAddPriceWeightMutation__
 *
 * To run a mutation, you first call `useAddPriceWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPriceWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPriceWeightMutation, { data, loading, error }] = useAddPriceWeightMutation({
 *   variables: {
 *      priceWeight: // value for 'priceWeight'
 *   },
 * });
 */
export function useAddPriceWeightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddPriceWeightMutation,
    GQL_AddPriceWeightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_AddPriceWeightMutation,
    GQL_AddPriceWeightMutationVariables
  >(AddPriceWeightDocument, options);
}
export type AddPriceWeightMutationHookResult = ReturnType<
  typeof useAddPriceWeightMutation
>;
export type AddPriceWeightMutationResult =
  Apollo.MutationResult<GQL_AddPriceWeightMutation>;
export type AddPriceWeightMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddPriceWeightMutation,
  GQL_AddPriceWeightMutationVariables
>;
export const EditPriceWeightDocument = gql`
  mutation editPriceWeight($id: ID!, $priceWeight: EditPriceWeightInput!) {
    editPriceWeight(priceWeightId: $id, priceWeight: $priceWeight) {
      priceWeight {
        ...priceWeight
      }
    }
  }
  ${PriceWeightFragmentDoc}
`;
export type GQL_EditPriceWeightMutationFn = Apollo.MutationFunction<
  GQL_EditPriceWeightMutation,
  GQL_EditPriceWeightMutationVariables
>;

/**
 * __useEditPriceWeightMutation__
 *
 * To run a mutation, you first call `useEditPriceWeightMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPriceWeightMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPriceWeightMutation, { data, loading, error }] = useEditPriceWeightMutation({
 *   variables: {
 *      id: // value for 'id'
 *      priceWeight: // value for 'priceWeight'
 *   },
 * });
 */
export function useEditPriceWeightMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditPriceWeightMutation,
    GQL_EditPriceWeightMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditPriceWeightMutation,
    GQL_EditPriceWeightMutationVariables
  >(EditPriceWeightDocument, options);
}
export type EditPriceWeightMutationHookResult = ReturnType<
  typeof useEditPriceWeightMutation
>;
export type EditPriceWeightMutationResult =
  Apollo.MutationResult<GQL_EditPriceWeightMutation>;
export type EditPriceWeightMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditPriceWeightMutation,
  GQL_EditPriceWeightMutationVariables
>;
export const AllProductsDocument = gql`
  query allProducts {
    product {
      allProducts {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useAllProductsQuery__
 *
 * To run a query within a React component, call `useAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllProductsQuery, GQL_AllProductsQueryVariables>(
    AllProductsDocument,
    options
  );
}
export function useAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >(AllProductsDocument, options);
}
export function useAllProductsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >(AllProductsDocument, options);
}
export type AllProductsQueryHookResult = ReturnType<typeof useAllProductsQuery>;
export type AllProductsLazyQueryHookResult = ReturnType<
  typeof useAllProductsLazyQuery
>;
export type AllProductsSuspenseQueryHookResult = ReturnType<
  typeof useAllProductsSuspenseQuery
>;
export type AllProductsQueryResult = Apollo.QueryResult<
  GQL_AllProductsQuery,
  GQL_AllProductsQueryVariables
>;
export const ProductByIdDocument = gql`
  query productById($id: ID!) {
    product {
      productById(id: $id) {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;

/**
 * __useProductByIdQuery__
 *
 * To run a query within a React component, call `useProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_ProductByIdQuery,
    GQL_ProductByIdQueryVariables
  > &
    (
      | { variables: GQL_ProductByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_ProductByIdQuery, GQL_ProductByIdQueryVariables>(
    ProductByIdDocument,
    options
  );
}
export function useProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_ProductByIdQuery,
    GQL_ProductByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_ProductByIdQuery,
    GQL_ProductByIdQueryVariables
  >(ProductByIdDocument, options);
}
export function useProductByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_ProductByIdQuery,
    GQL_ProductByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_ProductByIdQuery,
    GQL_ProductByIdQueryVariables
  >(ProductByIdDocument, options);
}
export type ProductByIdQueryHookResult = ReturnType<typeof useProductByIdQuery>;
export type ProductByIdLazyQueryHookResult = ReturnType<
  typeof useProductByIdLazyQuery
>;
export type ProductByIdSuspenseQueryHookResult = ReturnType<
  typeof useProductByIdSuspenseQuery
>;
export type ProductByIdQueryResult = Apollo.QueryResult<
  GQL_ProductByIdQuery,
  GQL_ProductByIdQueryVariables
>;
export const AddProductDocument = gql`
  mutation addProduct($addProduct: AddProductInput!) {
    addProduct(product: $addProduct) {
      product {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type GQL_AddProductMutationFn = Apollo.MutationFunction<
  GQL_AddProductMutation,
  GQL_AddProductMutationVariables
>;

/**
 * __useAddProductMutation__
 *
 * To run a mutation, you first call `useAddProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProductMutation, { data, loading, error }] = useAddProductMutation({
 *   variables: {
 *      addProduct: // value for 'addProduct'
 *   },
 * });
 */
export function useAddProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddProductMutation,
    GQL_AddProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_AddProductMutation,
    GQL_AddProductMutationVariables
  >(AddProductDocument, options);
}
export type AddProductMutationHookResult = ReturnType<
  typeof useAddProductMutation
>;
export type AddProductMutationResult =
  Apollo.MutationResult<GQL_AddProductMutation>;
export type AddProductMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddProductMutation,
  GQL_AddProductMutationVariables
>;
export const EditProductDocument = gql`
  mutation editProduct($productId: ID!, $editProduct: EditProductInput!) {
    editProduct(productId: $productId, product: $editProduct) {
      product {
        ...product
      }
    }
  }
  ${ProductFragmentDoc}
`;
export type GQL_EditProductMutationFn = Apollo.MutationFunction<
  GQL_EditProductMutation,
  GQL_EditProductMutationVariables
>;

/**
 * __useEditProductMutation__
 *
 * To run a mutation, you first call `useEditProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProductMutation, { data, loading, error }] = useEditProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      editProduct: // value for 'editProduct'
 *   },
 * });
 */
export function useEditProductMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditProductMutation,
    GQL_EditProductMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditProductMutation,
    GQL_EditProductMutationVariables
  >(EditProductDocument, options);
}
export type EditProductMutationHookResult = ReturnType<
  typeof useEditProductMutation
>;
export type EditProductMutationResult =
  Apollo.MutationResult<GQL_EditProductMutation>;
export type EditProductMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditProductMutation,
  GQL_EditProductMutationVariables
>;
export const AllOngoingRentalsDocument = gql`
  query allOngoingRentals {
    rental {
      allOngoingRentals {
        ...rental
      }
    }
  }
  ${RentalFragmentDoc}
`;

/**
 * __useAllOngoingRentalsQuery__
 *
 * To run a query within a React component, call `useAllOngoingRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOngoingRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOngoingRentalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllOngoingRentalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >(AllOngoingRentalsDocument, options);
}
export function useAllOngoingRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >(AllOngoingRentalsDocument, options);
}
export function useAllOngoingRentalsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >(AllOngoingRentalsDocument, options);
}
export type AllOngoingRentalsQueryHookResult = ReturnType<
  typeof useAllOngoingRentalsQuery
>;
export type AllOngoingRentalsLazyQueryHookResult = ReturnType<
  typeof useAllOngoingRentalsLazyQuery
>;
export type AllOngoingRentalsSuspenseQueryHookResult = ReturnType<
  typeof useAllOngoingRentalsSuspenseQuery
>;
export type AllOngoingRentalsQueryResult = Apollo.QueryResult<
  GQL_AllOngoingRentalsQuery,
  GQL_AllOngoingRentalsQueryVariables
>;
export const AllRentalsDocument = gql`
  query allRentals(
    $filter: RentalsFilterInput
    $pagination: Pagination
    $search: RentalsSearchInput
  ) {
    rental {
      allRentals(filter: $filter, pagination: $pagination, search: $search) {
        nodes {
          ...rental
        }
        totalCount
      }
    }
  }
  ${RentalFragmentDoc}
`;

/**
 * __useAllRentalsQuery__
 *
 * To run a query within a React component, call `useAllRentalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRentalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRentalsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllRentalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllRentalsQuery,
    GQL_AllRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllRentalsQuery, GQL_AllRentalsQueryVariables>(
    AllRentalsDocument,
    options
  );
}
export function useAllRentalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllRentalsQuery,
    GQL_AllRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_AllRentalsQuery, GQL_AllRentalsQueryVariables>(
    AllRentalsDocument,
    options
  );
}
export function useAllRentalsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllRentalsQuery,
    GQL_AllRentalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllRentalsQuery,
    GQL_AllRentalsQueryVariables
  >(AllRentalsDocument, options);
}
export type AllRentalsQueryHookResult = ReturnType<typeof useAllRentalsQuery>;
export type AllRentalsLazyQueryHookResult = ReturnType<
  typeof useAllRentalsLazyQuery
>;
export type AllRentalsSuspenseQueryHookResult = ReturnType<
  typeof useAllRentalsSuspenseQuery
>;
export type AllRentalsQueryResult = Apollo.QueryResult<
  GQL_AllRentalsQuery,
  GQL_AllRentalsQueryVariables
>;
export const RentalByIdDocument = gql`
  query rentalById($id: ID!) {
    rental {
      rentalById(id: $id) {
        ...rental
      }
    }
  }
  ${RentalFragmentDoc}
`;

/**
 * __useRentalByIdQuery__
 *
 * To run a query within a React component, call `useRentalByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRentalByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_RentalByIdQuery,
    GQL_RentalByIdQueryVariables
  > &
    (
      | { variables: GQL_RentalByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_RentalByIdQuery, GQL_RentalByIdQueryVariables>(
    RentalByIdDocument,
    options
  );
}
export function useRentalByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_RentalByIdQuery,
    GQL_RentalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_RentalByIdQuery, GQL_RentalByIdQueryVariables>(
    RentalByIdDocument,
    options
  );
}
export function useRentalByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_RentalByIdQuery,
    GQL_RentalByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_RentalByIdQuery,
    GQL_RentalByIdQueryVariables
  >(RentalByIdDocument, options);
}
export type RentalByIdQueryHookResult = ReturnType<typeof useRentalByIdQuery>;
export type RentalByIdLazyQueryHookResult = ReturnType<
  typeof useRentalByIdLazyQuery
>;
export type RentalByIdSuspenseQueryHookResult = ReturnType<
  typeof useRentalByIdSuspenseQuery
>;
export type RentalByIdQueryResult = Apollo.QueryResult<
  GQL_RentalByIdQuery,
  GQL_RentalByIdQueryVariables
>;
export const StopRentalDocument = gql`
  mutation stopRental($rentalInput: StopRentalInput!) {
    stopRental(rentalInput: $rentalInput) {
      paymentChargeInformation {
        ... on PaymentChargeInformationFreeRent {
          ...paymentChargeInformationFreeRent
        }
        ... on PaymentChargeInformationSuccess {
          ...paymentChargeInformationSuccess
        }
        ... on PaymentChargeInformationFail {
          ...paymentChargeInformationFail
        }
      }
      compartment {
        ...compartment
      }
      rental {
        ...rental
      }
      type
    }
  }
  ${PaymentChargeInformationFreeRentFragmentDoc}
  ${PaymentChargeInformationSuccessFragmentDoc}
  ${PaymentChargeInformationFailFragmentDoc}
  ${CompartmentFragmentDoc}
  ${RentalFragmentDoc}
`;
export type GQL_StopRentalMutationFn = Apollo.MutationFunction<
  GQL_StopRentalMutation,
  GQL_StopRentalMutationVariables
>;

/**
 * __useStopRentalMutation__
 *
 * To run a mutation, you first call `useStopRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopRentalMutation, { data, loading, error }] = useStopRentalMutation({
 *   variables: {
 *      rentalInput: // value for 'rentalInput'
 *   },
 * });
 */
export function useStopRentalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_StopRentalMutation,
    GQL_StopRentalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_StopRentalMutation,
    GQL_StopRentalMutationVariables
  >(StopRentalDocument, options);
}
export type StopRentalMutationHookResult = ReturnType<
  typeof useStopRentalMutation
>;
export type StopRentalMutationResult =
  Apollo.MutationResult<GQL_StopRentalMutation>;
export type StopRentalMutationOptions = Apollo.BaseMutationOptions<
  GQL_StopRentalMutation,
  GQL_StopRentalMutationVariables
>;
export const ChargeRentalDocument = gql`
  mutation chargeRental($chargeRentalInput: ChargeRentalInput!) {
    chargeRental(chargeRentalInput: $chargeRentalInput) {
      paymentChargeInformation {
        ... on PaymentChargeInformationFreeRent {
          ...paymentChargeInformationFreeRent
        }
        ... on PaymentChargeInformationSuccess {
          ...paymentChargeInformationSuccess
        }
        ... on PaymentChargeInformationFail {
          ...paymentChargeInformationFail
        }
      }
      rental {
        ...rental
      }
      type
    }
  }
  ${PaymentChargeInformationFreeRentFragmentDoc}
  ${PaymentChargeInformationSuccessFragmentDoc}
  ${PaymentChargeInformationFailFragmentDoc}
  ${RentalFragmentDoc}
`;
export type GQL_ChargeRentalMutationFn = Apollo.MutationFunction<
  GQL_ChargeRentalMutation,
  GQL_ChargeRentalMutationVariables
>;

/**
 * __useChargeRentalMutation__
 *
 * To run a mutation, you first call `useChargeRentalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeRentalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeRentalMutation, { data, loading, error }] = useChargeRentalMutation({
 *   variables: {
 *      chargeRentalInput: // value for 'chargeRentalInput'
 *   },
 * });
 */
export function useChargeRentalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_ChargeRentalMutation,
    GQL_ChargeRentalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_ChargeRentalMutation,
    GQL_ChargeRentalMutationVariables
  >(ChargeRentalDocument, options);
}
export type ChargeRentalMutationHookResult = ReturnType<
  typeof useChargeRentalMutation
>;
export type ChargeRentalMutationResult =
  Apollo.MutationResult<GQL_ChargeRentalMutation>;
export type ChargeRentalMutationOptions = Apollo.BaseMutationOptions<
  GQL_ChargeRentalMutation,
  GQL_ChargeRentalMutationVariables
>;
export const CreateRentalImageGetUrlDocument = gql`
  mutation createRentalImageGetUrl($id: ID!) {
    createRentalImageGetUrl(id: $id) {
      imageUrl
    }
  }
`;
export type GQL_CreateRentalImageGetUrlMutationFn = Apollo.MutationFunction<
  GQL_CreateRentalImageGetUrlMutation,
  GQL_CreateRentalImageGetUrlMutationVariables
>;

/**
 * __useCreateRentalImageGetUrlMutation__
 *
 * To run a mutation, you first call `useCreateRentalImageGetUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRentalImageGetUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRentalImageGetUrlMutation, { data, loading, error }] = useCreateRentalImageGetUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateRentalImageGetUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_CreateRentalImageGetUrlMutation,
    GQL_CreateRentalImageGetUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_CreateRentalImageGetUrlMutation,
    GQL_CreateRentalImageGetUrlMutationVariables
  >(CreateRentalImageGetUrlDocument, options);
}
export type CreateRentalImageGetUrlMutationHookResult = ReturnType<
  typeof useCreateRentalImageGetUrlMutation
>;
export type CreateRentalImageGetUrlMutationResult =
  Apollo.MutationResult<GQL_CreateRentalImageGetUrlMutation>;
export type CreateRentalImageGetUrlMutationOptions = Apollo.BaseMutationOptions<
  GQL_CreateRentalImageGetUrlMutation,
  GQL_CreateRentalImageGetUrlMutationVariables
>;
export const ReviewByIdDocument = gql`
  query reviewById($id: ID!) {
    review {
      reviewById(id: $id) {
        ...review
      }
    }
  }
  ${ReviewFragmentDoc}
`;

/**
 * __useReviewByIdQuery__
 *
 * To run a query within a React component, call `useReviewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReviewByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_ReviewByIdQuery,
    GQL_ReviewByIdQueryVariables
  > &
    (
      | { variables: GQL_ReviewByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_ReviewByIdQuery, GQL_ReviewByIdQueryVariables>(
    ReviewByIdDocument,
    options
  );
}
export function useReviewByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_ReviewByIdQuery,
    GQL_ReviewByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_ReviewByIdQuery, GQL_ReviewByIdQueryVariables>(
    ReviewByIdDocument,
    options
  );
}
export function useReviewByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_ReviewByIdQuery,
    GQL_ReviewByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_ReviewByIdQuery,
    GQL_ReviewByIdQueryVariables
  >(ReviewByIdDocument, options);
}
export type ReviewByIdQueryHookResult = ReturnType<typeof useReviewByIdQuery>;
export type ReviewByIdLazyQueryHookResult = ReturnType<
  typeof useReviewByIdLazyQuery
>;
export type ReviewByIdSuspenseQueryHookResult = ReturnType<
  typeof useReviewByIdSuspenseQuery
>;
export type ReviewByIdQueryResult = Apollo.QueryResult<
  GQL_ReviewByIdQuery,
  GQL_ReviewByIdQueryVariables
>;
export const AllReviewsDocument = gql`
  query allReviews {
    review {
      allReviews {
        ...review
      }
    }
  }
  ${ReviewFragmentDoc}
`;

/**
 * __useAllReviewsQuery__
 *
 * To run a query within a React component, call `useAllReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReviewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllReviewsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllReviewsQuery,
    GQL_AllReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllReviewsQuery, GQL_AllReviewsQueryVariables>(
    AllReviewsDocument,
    options
  );
}
export function useAllReviewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllReviewsQuery,
    GQL_AllReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_AllReviewsQuery, GQL_AllReviewsQueryVariables>(
    AllReviewsDocument,
    options
  );
}
export function useAllReviewsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllReviewsQuery,
    GQL_AllReviewsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllReviewsQuery,
    GQL_AllReviewsQueryVariables
  >(AllReviewsDocument, options);
}
export type AllReviewsQueryHookResult = ReturnType<typeof useAllReviewsQuery>;
export type AllReviewsLazyQueryHookResult = ReturnType<
  typeof useAllReviewsLazyQuery
>;
export type AllReviewsSuspenseQueryHookResult = ReturnType<
  typeof useAllReviewsSuspenseQuery
>;
export type AllReviewsQueryResult = Apollo.QueryResult<
  GQL_AllReviewsQuery,
  GQL_AllReviewsQueryVariables
>;
export const AllRolesDocument = gql`
  query allRoles {
    role {
      allRoles {
        ...role
      }
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useAllRolesQuery__
 *
 * To run a query within a React component, call `useAllRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllRolesQuery,
    GQL_AllRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllRolesQuery, GQL_AllRolesQueryVariables>(
    AllRolesDocument,
    options
  );
}
export function useAllRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllRolesQuery,
    GQL_AllRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_AllRolesQuery, GQL_AllRolesQueryVariables>(
    AllRolesDocument,
    options
  );
}
export function useAllRolesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllRolesQuery,
    GQL_AllRolesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GQL_AllRolesQuery, GQL_AllRolesQueryVariables>(
    AllRolesDocument,
    options
  );
}
export type AllRolesQueryHookResult = ReturnType<typeof useAllRolesQuery>;
export type AllRolesLazyQueryHookResult = ReturnType<
  typeof useAllRolesLazyQuery
>;
export type AllRolesSuspenseQueryHookResult = ReturnType<
  typeof useAllRolesSuspenseQuery
>;
export type AllRolesQueryResult = Apollo.QueryResult<
  GQL_AllRolesQuery,
  GQL_AllRolesQueryVariables
>;
export const RoleByIdDocument = gql`
  query roleById($id: ID!) {
    role {
      roleById(roleId: $id) {
        ...role
      }
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useRoleByIdQuery__
 *
 * To run a query within a React component, call `useRoleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_RoleByIdQuery,
    GQL_RoleByIdQueryVariables
  > &
    (
      | { variables: GQL_RoleByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_RoleByIdQuery, GQL_RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export function useRoleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_RoleByIdQuery,
    GQL_RoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_RoleByIdQuery, GQL_RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export function useRoleByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_RoleByIdQuery,
    GQL_RoleByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GQL_RoleByIdQuery, GQL_RoleByIdQueryVariables>(
    RoleByIdDocument,
    options
  );
}
export type RoleByIdQueryHookResult = ReturnType<typeof useRoleByIdQuery>;
export type RoleByIdLazyQueryHookResult = ReturnType<
  typeof useRoleByIdLazyQuery
>;
export type RoleByIdSuspenseQueryHookResult = ReturnType<
  typeof useRoleByIdSuspenseQuery
>;
export type RoleByIdQueryResult = Apollo.QueryResult<
  GQL_RoleByIdQuery,
  GQL_RoleByIdQueryVariables
>;
export const AddRoleDocument = gql`
  mutation addRole($addRole: AddRoleInput!) {
    addRole(role: $addRole) {
      role {
        ...role
      }
    }
  }
  ${RoleFragmentDoc}
`;
export type GQL_AddRoleMutationFn = Apollo.MutationFunction<
  GQL_AddRoleMutation,
  GQL_AddRoleMutationVariables
>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      addRole: // value for 'addRole'
 *   },
 * });
 */
export function useAddRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddRoleMutation,
    GQL_AddRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GQL_AddRoleMutation, GQL_AddRoleMutationVariables>(
    AddRoleDocument,
    options
  );
}
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<GQL_AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddRoleMutation,
  GQL_AddRoleMutationVariables
>;
export const EditRoleDocument = gql`
  mutation editRole($roleId: ID!, $editRole: EditRoleInput!) {
    editRole(roleId: $roleId, role: $editRole) {
      role {
        ...role
      }
    }
  }
  ${RoleFragmentDoc}
`;
export type GQL_EditRoleMutationFn = Apollo.MutationFunction<
  GQL_EditRoleMutation,
  GQL_EditRoleMutationVariables
>;

/**
 * __useEditRoleMutation__
 *
 * To run a mutation, you first call `useEditRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRoleMutation, { data, loading, error }] = useEditRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      editRole: // value for 'editRole'
 *   },
 * });
 */
export function useEditRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditRoleMutation,
    GQL_EditRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditRoleMutation,
    GQL_EditRoleMutationVariables
  >(EditRoleDocument, options);
}
export type EditRoleMutationHookResult = ReturnType<typeof useEditRoleMutation>;
export type EditRoleMutationResult =
  Apollo.MutationResult<GQL_EditRoleMutation>;
export type EditRoleMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditRoleMutation,
  GQL_EditRoleMutationVariables
>;
export const AllSponsorsDocument = gql`
  query allSponsors {
    sponsor {
      allSponsors {
        ...sponsor
      }
    }
  }
  ${SponsorFragmentDoc}
`;

/**
 * __useAllSponsorsQuery__
 *
 * To run a query within a React component, call `useAllSponsorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSponsorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSponsorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSponsorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllSponsorsQuery,
    GQL_AllSponsorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllSponsorsQuery, GQL_AllSponsorsQueryVariables>(
    AllSponsorsDocument,
    options
  );
}
export function useAllSponsorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllSponsorsQuery,
    GQL_AllSponsorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllSponsorsQuery,
    GQL_AllSponsorsQueryVariables
  >(AllSponsorsDocument, options);
}
export function useAllSponsorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllSponsorsQuery,
    GQL_AllSponsorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllSponsorsQuery,
    GQL_AllSponsorsQueryVariables
  >(AllSponsorsDocument, options);
}
export type AllSponsorsQueryHookResult = ReturnType<typeof useAllSponsorsQuery>;
export type AllSponsorsLazyQueryHookResult = ReturnType<
  typeof useAllSponsorsLazyQuery
>;
export type AllSponsorsSuspenseQueryHookResult = ReturnType<
  typeof useAllSponsorsSuspenseQuery
>;
export type AllSponsorsQueryResult = Apollo.QueryResult<
  GQL_AllSponsorsQuery,
  GQL_AllSponsorsQueryVariables
>;
export const SponsorByIdDocument = gql`
  query sponsorById($id: ID!) {
    sponsor {
      sponsorById(id: $id) {
        ...sponsor
      }
    }
  }
  ${SponsorFragmentDoc}
`;

/**
 * __useSponsorByIdQuery__
 *
 * To run a query within a React component, call `useSponsorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSponsorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSponsorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSponsorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_SponsorByIdQuery,
    GQL_SponsorByIdQueryVariables
  > &
    (
      | { variables: GQL_SponsorByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_SponsorByIdQuery, GQL_SponsorByIdQueryVariables>(
    SponsorByIdDocument,
    options
  );
}
export function useSponsorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_SponsorByIdQuery,
    GQL_SponsorByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_SponsorByIdQuery,
    GQL_SponsorByIdQueryVariables
  >(SponsorByIdDocument, options);
}
export function useSponsorByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_SponsorByIdQuery,
    GQL_SponsorByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_SponsorByIdQuery,
    GQL_SponsorByIdQueryVariables
  >(SponsorByIdDocument, options);
}
export type SponsorByIdQueryHookResult = ReturnType<typeof useSponsorByIdQuery>;
export type SponsorByIdLazyQueryHookResult = ReturnType<
  typeof useSponsorByIdLazyQuery
>;
export type SponsorByIdSuspenseQueryHookResult = ReturnType<
  typeof useSponsorByIdSuspenseQuery
>;
export type SponsorByIdQueryResult = Apollo.QueryResult<
  GQL_SponsorByIdQuery,
  GQL_SponsorByIdQueryVariables
>;
export const AddSponsorDocument = gql`
  mutation addSponsor($addSponsor: AddSponsorInput!) {
    addSponsor(sponsor: $addSponsor) {
      sponsor {
        ...sponsor
      }
    }
  }
  ${SponsorFragmentDoc}
`;
export type GQL_AddSponsorMutationFn = Apollo.MutationFunction<
  GQL_AddSponsorMutation,
  GQL_AddSponsorMutationVariables
>;

/**
 * __useAddSponsorMutation__
 *
 * To run a mutation, you first call `useAddSponsorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSponsorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSponsorMutation, { data, loading, error }] = useAddSponsorMutation({
 *   variables: {
 *      addSponsor: // value for 'addSponsor'
 *   },
 * });
 */
export function useAddSponsorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_AddSponsorMutation,
    GQL_AddSponsorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_AddSponsorMutation,
    GQL_AddSponsorMutationVariables
  >(AddSponsorDocument, options);
}
export type AddSponsorMutationHookResult = ReturnType<
  typeof useAddSponsorMutation
>;
export type AddSponsorMutationResult =
  Apollo.MutationResult<GQL_AddSponsorMutation>;
export type AddSponsorMutationOptions = Apollo.BaseMutationOptions<
  GQL_AddSponsorMutation,
  GQL_AddSponsorMutationVariables
>;
export const EditSponsorDocument = gql`
  mutation editSponsor($sponsorId: ID!, $editSponsor: EditSponsorInput!) {
    editSponsor(sponsorId: $sponsorId, sponsor: $editSponsor) {
      sponsor {
        ...sponsor
      }
    }
  }
  ${SponsorFragmentDoc}
`;
export type GQL_EditSponsorMutationFn = Apollo.MutationFunction<
  GQL_EditSponsorMutation,
  GQL_EditSponsorMutationVariables
>;

/**
 * __useEditSponsorMutation__
 *
 * To run a mutation, you first call `useEditSponsorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSponsorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSponsorMutation, { data, loading, error }] = useEditSponsorMutation({
 *   variables: {
 *      sponsorId: // value for 'sponsorId'
 *      editSponsor: // value for 'editSponsor'
 *   },
 * });
 */
export function useEditSponsorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditSponsorMutation,
    GQL_EditSponsorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditSponsorMutation,
    GQL_EditSponsorMutationVariables
  >(EditSponsorDocument, options);
}
export type EditSponsorMutationHookResult = ReturnType<
  typeof useEditSponsorMutation
>;
export type EditSponsorMutationResult =
  Apollo.MutationResult<GQL_EditSponsorMutation>;
export type EditSponsorMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditSponsorMutation,
  GQL_EditSponsorMutationVariables
>;
export const AllUsersDocument = gql`
  query allUsers(
    $filter: UsersFilterInput
    $pagination: Pagination
    $search: UserSearchInput
  ) {
    user {
      allUsers(filter: $filter, pagination: $pagination, search: $search) {
        nodes {
          ...userLite
        }
        totalCount
      }
    }
  }
  ${UserLiteFragmentDoc}
`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllUsersQuery,
    GQL_AllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_AllUsersQuery, GQL_AllUsersQueryVariables>(
    AllUsersDocument,
    options
  );
}
export function useAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllUsersQuery,
    GQL_AllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_AllUsersQuery, GQL_AllUsersQueryVariables>(
    AllUsersDocument,
    options
  );
}
export function useAllUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllUsersQuery,
    GQL_AllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GQL_AllUsersQuery, GQL_AllUsersQueryVariables>(
    AllUsersDocument,
    options
  );
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<
  typeof useAllUsersLazyQuery
>;
export type AllUsersSuspenseQueryHookResult = ReturnType<
  typeof useAllUsersSuspenseQuery
>;
export type AllUsersQueryResult = Apollo.QueryResult<
  GQL_AllUsersQuery,
  GQL_AllUsersQueryVariables
>;
export const AllUsersForSelectDocument = gql`
  query allUsersForSelect {
    user {
      allUsers {
        nodes {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useAllUsersForSelectQuery__
 *
 * To run a query within a React component, call `useAllUsersForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersForSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersForSelectQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >(AllUsersForSelectDocument, options);
}
export function useAllUsersForSelectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >(AllUsersForSelectDocument, options);
}
export function useAllUsersForSelectSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >(AllUsersForSelectDocument, options);
}
export type AllUsersForSelectQueryHookResult = ReturnType<
  typeof useAllUsersForSelectQuery
>;
export type AllUsersForSelectLazyQueryHookResult = ReturnType<
  typeof useAllUsersForSelectLazyQuery
>;
export type AllUsersForSelectSuspenseQueryHookResult = ReturnType<
  typeof useAllUsersForSelectSuspenseQuery
>;
export type AllUsersForSelectQueryResult = Apollo.QueryResult<
  GQL_AllUsersForSelectQuery,
  GQL_AllUsersForSelectQueryVariables
>;
export const UserByIdDocument = gql`
  query userById($id: ID!) {
    user {
      userById(id: $id) {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GQL_UserByIdQuery,
    GQL_UserByIdQueryVariables
  > &
    (
      | { variables: GQL_UserByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GQL_UserByIdQuery, GQL_UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export function useUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GQL_UserByIdQuery,
    GQL_UserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GQL_UserByIdQuery, GQL_UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export function useUserByIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GQL_UserByIdQuery,
    GQL_UserByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GQL_UserByIdQuery, GQL_UserByIdQueryVariables>(
    UserByIdDocument,
    options
  );
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<
  typeof useUserByIdLazyQuery
>;
export type UserByIdSuspenseQueryHookResult = ReturnType<
  typeof useUserByIdSuspenseQuery
>;
export type UserByIdQueryResult = Apollo.QueryResult<
  GQL_UserByIdQuery,
  GQL_UserByIdQueryVariables
>;
export const EditUserDocument = gql`
  mutation editUser($userId: ID!, $editUser: EditUserInput!) {
    editUser(userId: $userId, user: $editUser) {
      user {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`;
export type GQL_EditUserMutationFn = Apollo.MutationFunction<
  GQL_EditUserMutation,
  GQL_EditUserMutationVariables
>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      editUser: // value for 'editUser'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GQL_EditUserMutation,
    GQL_EditUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GQL_EditUserMutation,
    GQL_EditUserMutationVariables
  >(EditUserDocument, options);
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult =
  Apollo.MutationResult<GQL_EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  GQL_EditUserMutation,
  GQL_EditUserMutationVariables
>;
