import DateFNSAddDays from 'date-fns/addDays';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import DateFNSDifferenceInDays from 'date-fns/differenceInDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import DateFNSFormat from 'date-fns/format';
import DateFNSFormatISO from 'date-fns/formatISO';
import DateFNSIsAfter from 'date-fns/isAfter';
import DateFNSIsBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import DateFNSParseISO from 'date-fns/parseISO';
import DateFNSStartOfYear from 'date-fns/startOfYear';
import DateFNSSubDays from 'date-fns/subDays';
import DateFNSSubYears from 'date-fns/subYears';

export const toDate = (date: string | undefined | null): string | undefined => {
  if (date) {
    const parsedDate = parseISO(date);
    if (parsedDate) {
      try {
        return format(parsedDate, 'yyyy-MM-dd');
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }
};

export const isAfter = DateFNSIsAfter;
export const isBefore = DateFNSIsBefore;
export const addDays = DateFNSAddDays;

export const differenceInDays = DateFNSDifferenceInDays;

export const format = DateFNSFormat;
export const formatDate = (date: Date) => DateFNSFormat(date, 'yyyy-MM-dd');
export const formatTime = (date: Date) => DateFNSFormat(date, 'HH:mm');
export const formatDateAndTime = (date: Date) =>
  DateFNSFormat(date, 'yyyy-MM-dd, HH:mm');

export const subDays = DateFNSSubDays;
export const subYears = DateFNSSubYears;
export const startOfYear = DateFNSStartOfYear;

export const isValidDate = (date: string | null | undefined): boolean => {
  if (date) {
    return isValid(parseISO(date));
  }
  return false;
};

export const compareDate = (
  dateA: string | undefined,
  dateB: string | undefined,
  order: 'asc' | 'desc'
) => {
  if (dateA === undefined && dateB === undefined) {
    return 0;
  }
  if (dateA === undefined) {
    return 1;
  }
  if (dateB === undefined) {
    return -1;
  }
  if (order === 'asc') {
    return compareAsc(new Date(dateA), new Date(dateB));
  } else {
    return compareDesc(new Date(dateA), new Date(dateB));
  }
};

export const getTimeDifferenceInMinutes = (
  start: string | undefined | null,
  end: string | undefined | null
): number | undefined => {
  if (start && end) {
    const diff = differenceInMinutes(parseISO(end), parseISO(start));
    if (!isNaN(diff)) {
      return diff;
    }
  }
  return undefined;
};

export const toDisplayDateTime = (
  date: string | undefined | null
): string | undefined => {
  if (date) {
    const parsedDate = parseISO(date);
    if (parsedDate) {
      try {
        return formatDateAndTime(parsedDate);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }
};

export const toDisplayTime = (
  date: string | undefined | null
): string | undefined => {
  if (date) {
    const parsedDate = parseISO(date);
    if (parsedDate) {
      try {
        return formatTime(parsedDate);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }
};

export const toDisplayDate = (
  date: string | undefined | null
): string | undefined => {
  if (date) {
    const parsedDate = parseISO(date);
    if (parsedDate) {
      try {
        return formatDate(parsedDate);
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }
};

export const getHoursFromString = (time: string): number => {
  return parseInt(time.slice(0, 2), 10);
};

export const getMinutesFromString = (time: string): number => {
  return parseInt(time.slice(3, 5), 10);
};

export const transformTime = (time: string) => {
  return formatISO(
    new Date(2000, 1, 1, getHoursFromString(time), getMinutesFromString(time))
  );
};

export const formatISO = DateFNSFormatISO;
export const parseISO = DateFNSParseISO;

export const generateDateArray = (
  fromDate: string,
  endDate: string,
  format: string = 'yyyy-MM-dd'
): string[] => {
  let currentDate = new Date(fromDate);
  let endDateDate = new Date(endDate);
  let dates = [];
  while (!DateFNSIsAfter(currentDate, endDateDate)) {
    dates.push(DateFNSFormat(currentDate, format));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
};
