import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { DataList } from '../../webui/DataList';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Rating } from '../../webui/Rating';
import { useReviewById } from './gql/useReviewById';

interface Props {}

interface DataListItem {
  value?: React.ReactNode;
  label: string;
}

export const ReviewPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: review, loading, error } = useReviewById({
    variables: { id },
  });

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!review) {
    return <div>Could not find review information.</div>;
  }

  const items: DataListItem[] = [
    {
      label: 'Id',
      value: review.id,
    },
    {
      label: 'Rating',
      value: <Rating readOnly={true} value={review.rating} />,
    },
    {
      label: 'Free text',
      value: review.freeText,
    },
  ];

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <DataList
              header={'Review information'}
              headerVariant={'h3'}
              items={items}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};
