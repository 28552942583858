import { Rental } from '../../../../common/types/rental';

interface NumberOfRentalsPerProductPerBoxMap {
  [name: string]: {
    [box: string]: number;
  };
}

export const generateNumberOfRentalsPerProductPerBoxMap = (
  rentals: Rental[]
): NumberOfRentalsPerProductPerBoxMap =>
  rentals.reduce<NumberOfRentalsPerProductPerBoxMap>((acc, rental) => {
    const product = rental.product.name;
    if (acc[product]) {
      if (acc[product][rental.box.name]) {
        acc[product][rental.box.name] = acc[product][rental.box.name] + 1;
      } else {
        acc[product][rental.box.name] = 1;
      }
    } else {
      acc[product] = { [rental.box.name]: 1 };
    }
    return acc;
  }, {});
