import { Rental } from '../../../../common/types/rental';
import { onlyUnique } from '../../../../common/utils/arrayUtils/onlyUnique';
import { formatDate, parseISO } from '../../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../../common/utils/resolveBoxDisplayName';

export interface RentalsPerBoxPerDayData {
  [name: string]: number | string;
  date: string;
}

export const generateDatesWithBoxes = (
  dates: string[],
  uniqueBoxes: string[]
) => {
  return dates.reduce((previousValue, currentValue) => {
    return {
      [currentValue]: uniqueBoxes.reduce((previousValue1, currentValue1) => {
        return {
          [currentValue1]: 0,
          ...previousValue1,
        };
      }, {}),
      ...previousValue,
    };
  }, {});
};

export const getRentalsPerBoxPerDay = (
  rentals: Rental[],
  dates: string[],
  uniqueBoxes: string[]
): { [date: string]: { [name: string]: number } } => {
  const datesWithBoxNames = dates.reduce<{
    [date: string]: { [name: string]: number };
  }>((acc, date) => {
    return {
      [date]: {},
      ...acc,
    };
  }, generateDatesWithBoxes(dates, uniqueBoxes));

  rentals.forEach((rental) => {
    const datesWithBoxName =
      datesWithBoxNames[formatDate(parseISO(rental.rentStart))];

    if (datesWithBoxName) {
      const boxDisplayName = resolveBoxDisplayName(rental.box);
      if (!datesWithBoxName[boxDisplayName]) {
        datesWithBoxName[boxDisplayName] = 1;
      } else {
        datesWithBoxName[boxDisplayName] = datesWithBoxName[boxDisplayName] + 1;
      }
    }
  });
  return datesWithBoxNames;
};

export const getUniqueBoxNames = (rentals: Rental[]) =>
  rentals.map((rental) => resolveBoxDisplayName(rental.box)).filter(onlyUnique);

export const generateRentalsPerBoxPerDayData = (
  rentals: Rental[],
  dates: string[],
  uniqueBoxes: string[]
): RentalsPerBoxPerDayData[] => {
  const rentalsPerBoxPerDay = getRentalsPerBoxPerDay(
    rentals,
    dates,
    uniqueBoxes
  );
  return dates.map((date) => {
    return {
      date,
      ...rentalsPerBoxPerDay[date],
    };
  });
};
