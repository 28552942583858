import { Rental } from '../../../../common/types/rental';
import { generateNumberOfRentalsPerProductPerBoxMap } from './generateNumberOfRentalsPerProductPerBoxMap';

type NumberOfRentalsPerProduct = {
  numberOfBoxes: number;
  perBox: number;
  total: number;
};

interface NumberOfRentalsPerProductMap {
  [name: string]: NumberOfRentalsPerProduct;
}

export const generateNumberOfRentalsPerProductMap = (
  rentals: Rental[]
): NumberOfRentalsPerProductMap =>
  Object.entries(generateNumberOfRentalsPerProductPerBoxMap(rentals))
    .map(([product, numberOfRentalsPerProductPerBox]) => {
      const total = Object.values(numberOfRentalsPerProductPerBox).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );
      const numberOfBoxes = Object.keys(numberOfRentalsPerProductPerBox).length;
      return {
        numberOfBoxes: numberOfBoxes,
        perBox: total / numberOfBoxes,
        product,
        total,
      };
    })
    .reduce<NumberOfRentalsPerProductMap>(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.product]: {
          numberOfBoxes: currentValue.numberOfBoxes,
          perBox: currentValue.perBox,
          total: currentValue.total,
        },
      }),
      {}
    );
