import React, { useState } from 'react';
import { useForm } from '../../../common/hooks/useForm';
import { useNotification } from '../../../common/notifications/useNotification';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Organisation } from '../../../common/types/organisation';
import { Product } from '../../../common/types/product';
import {
  formatDate,
  startOfYear,
  subDays,
  subYears,
} from '../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { Box } from '../../../webui/Box';
import { Button } from '../../../webui/Button';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../../webui/inputs/CheckboxWithLabel';
import { DateInput, DateInputProps } from '../../../webui/inputs/DateInput';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  SearchSelect,
  SearchSelectProps,
} from '../../../webui/inputs/SearchSelect';
import { Paper } from '../../../webui/Paper';
import { sortBoxes } from '../../box/utils/sortBoxes';
import { Filter } from './types';
import { genderOptions } from './utils/genderFilter';
import { getFilteredBoxesWithOrganisationBoxes } from './utils/getFilteredBoxesWithOrganisationBoxes';

interface Props {
  allBoxes: BoxWithoutRentals[];
  allOrganisations: Organisation[];
  allProducts: Product[];
  initialFilter: Filter;
  onFilterChange: (filter: Filter) => void;
}

export const StatisticsRentalFilter: React.FC<Props> = ({
  allBoxes,
  allOrganisations,
  allProducts,
  initialFilter,
  onFilterChange,
}) => {
  const { showNotification } = useNotification();
  const [filterHasChanged, setFilterHasChanged] = useState<boolean>(false);

  const { form, updateField } = useForm<Filter>(initialFilter);

  const onApplyFilter = () => {
    onFilterChange(form);
    setFilterHasChanged(false);
    showNotification({
      message: 'Filter applied',
      severity: 'success',
    });
  };

  const onBoxesChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('selectedBoxes', value.slice());
    setFilterHasChanged(true);
  };
  const onOnlyActiveBoxesChange: CheckboxWithLabelProps['onChange'] = (
    value
  ) => {
    updateField('onlyActiveBoxes', value);
    setFilterHasChanged(true);
  };
  const onProductsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('selectedProducts', value.slice());
    setFilterHasChanged(true);
  };
  const onFromDateChange: DateInputProps['onChange'] = (value) => {
    updateField('fromDate', value);
    setFilterHasChanged(true);
  };
  const onEndDateChange: DateInputProps['onChange'] = (value) => {
    updateField('endDate', value);
    setFilterHasChanged(true);
  };
  const onGenderChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('selectedGenders', value.slice());
    setFilterHasChanged(true);
  };
  const onOrganisationChange: SearchSelectProps['onChange'] = (value) => {
    if (value) {
      const organisation = allOrganisations.find(
        (org) => org.id === value.value
      );
      if (organisation) {
        updateField(
          'selectedBoxes',
          getFilteredBoxesWithOrganisationBoxes(
            form.selectedBoxes,
            organisation
          )
        );
      }
    }
    setFilterHasChanged(true);
  };

  const onOneMonthBack = () => {
    updateField('endDate', formatDate(new Date()));
    updateField('fromDate', formatDate(subDays(new Date(), 30)));
    setFilterHasChanged(true);
  };

  const onThisYear = () => {
    updateField('endDate', formatDate(new Date()));
    updateField('fromDate', formatDate(startOfYear(new Date())));
    setFilterHasChanged(true);
  };

  const onOneYearBack = () => {
    updateField('endDate', formatDate(new Date()));
    updateField('fromDate', formatDate(subYears(new Date(), 1)));
    setFilterHasChanged(true);
  };

  return (
    <Paper>
      <Box p={2} display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
        <MultiSearchSelect
          label={'Boxar'}
          onChange={onBoxesChange}
          options={sortBoxes(allBoxes).map((box) => ({
            label: resolveBoxDisplayName(box),
            value: box.id,
          }))}
          value={form.selectedBoxes}
        />
        <Box ml={2} />
        <SearchSelect
          label={'Organisationer'}
          onChange={onOrganisationChange}
          options={allOrganisations.map((organisation) => ({
            label: organisation.name,
            value: organisation.id,
          }))}
          value={null}
        />
        <Box ml={2} />
        <Box>
          <CheckboxWithLabel
            label={'Visa bara aktiva boxar'}
            labelPlacement={'start'}
            onChange={onOnlyActiveBoxesChange}
            value={form.onlyActiveBoxes}
          />
        </Box>
      </Box>
      <Box p={2} display={'flex'} flexDirection={'row'} alignItems={'end'}>
        <DateInput
          label={'Från datum'}
          onChange={onFromDateChange}
          value={form.fromDate}
        />
        <Box ml={2} />
        <DateInput
          label={'Till datum'}
          onChange={onEndDateChange}
          value={form.endDate}
        />
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box ml={2} />
          <Button onClick={onOneMonthBack}>En månad tillbaka</Button>
          <Box ml={2} />
          <Button onClick={onThisYear}>Detta året</Button>
          <Box ml={2} />
          <Button onClick={onOneYearBack}>Ett år tillbaka</Button>
        </Box>
      </Box>
      <Box p={2} display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <MultiSearchSelect
          label={'Kön'}
          onChange={onGenderChange}
          options={genderOptions}
          value={form.selectedGenders}
        />
      </Box>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <MultiSearchSelect
          label={'Produkter'}
          onChange={onProductsChange}
          options={allProducts.map((product) => ({
            label: product.name,
            value: product.id,
          }))}
          value={form.selectedProducts}
        />
        <Button disabled={!filterHasChanged} onClick={onApplyFilter}>
          Använd filter
        </Button>
      </Box>
    </Paper>
  );
};
