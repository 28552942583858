import React from 'react';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { AllReviewsTable } from './AllReviewsTable';

interface Props {}

const paginationLimit = 1000;

export const AllReviews: React.FC<Props> = () => {
  const {
    data: { rentals, totalCount },
    loading,
    error,
    fetchMore,
  } = useAllRentals({
    variables: {
      pagination: {
        limit: paginationLimit,
        offset: 0,
      },
      filter: {
        hasReview: true,
      },
    },
  });

  const onFetchMoreClicked = async () => {
    await fetchMore({
      variables: {
        pagination: {
          offset: rentals.length,
          limit: paginationLimit,
        },
      },
    });
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const hasReachedPenultimatePage = async () => {
    await onFetchMoreClicked();
  };

  return (
    <Paper>
      <AllReviewsTable
        hasReachedPenultimatePage={hasReachedPenultimatePage}
        rentals={rentals}
      />
      <Box
        alignItems={'flex-end'}
        display={'flex'}
        justifyContent={'flex-end'}
        pb={1}
        pr={1}
      >
        <Box ml={2} />
        <Typography variant={'h6'}>Total: {totalCount}</Typography>
      </Box>
    </Paper>
  );
};
